import React, { useMemo } from "react";
import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Box, Grid, Typography, Link } from "@material-ui/core";

import { FormContext } from "../../contexts/FormContext";
import styleVars from "../../_export.module.scss";
import Icon from "react-icons-kit";
import { ic_forum as icForum } from "react-icons-kit/md";
import { ic_print as icPrint } from "react-icons-kit/md";
import { CondensedReview } from "./CondensedReview";
import moment from "moment";
import classes from "./CompletedForm.module.scss";
import common from "../../Common.module.scss";
import {
  FormType,
  ReauthFormSchema,
  ReferralFormSchema,
  ReviewFile,
} from "../../types/FormSchema";

const CompletedForm = () => {
  const navigate = useNavigate();
  const {
    metadata,
    formStep,
    formData,
    userData,
    files,
    setFormStep,
    setFormData,
    setMetadata,
    setFiles,
  } = useContext(FormContext);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (formStep < 6) {
      setFormStep(6);
    }
  }, [formStep, setFormStep]);

  const reviewFiles = useMemo(() => {
    return files.map((file) => {
      return {
        name: file.name,
        category: file.category,
      } as ReviewFile;
    });
  }, [files]);

  const pdfUrl = useMemo(() => {
    if (metadata.formType === FormType.Referral) {
      const referralFormData = formData as ReferralFormSchema;
      const dateOfInjury = moment(referralFormData?.dateOfInjury).format(
        "YYYY-MM-DD"
      );

      return `${process.env.REACT_APP_REFERRAL_BACKEND}/public/referral/pdf?referral_id=${metadata?.referralId}&claim_number=${referralFormData?.claimNumber}&claim_date_of_injury=${dateOfInjury}`;
    } else if (metadata.formType === FormType.Reauth) {
      const reauthFormData = formData as ReauthFormSchema;
      const birthday = moment(reauthFormData?.patientBirthday).format(
        "YYYY-MM-DD"
      );

      return `${process.env.REACT_APP_REFERRAL_BACKEND}/public/reauth/pdf?reauth_id=${metadata?.reauthId}&claim_number=${reauthFormData?.claimNumber}&birthdate=${birthday}`;
    }
    return "";
  }, [formData, metadata.formType, metadata?.reauthId, metadata?.referralId]);

  return (
    <>
      <Grid item xs={12}>
        <Box style={{ width: "925px" }}></Box>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h4" style={{ color: styleVars.colorReferralBlue }}>
          {metadata.formType === FormType.Referral
            ? "We've received your referral"
            : "We've received your re-authorization!"}
        </Typography>
      </Grid>
      <Grid container spacing={2}>
        <Box height="28px"></Box>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body1" className={common.fontLargeBold}>
          Thanks for choosing Bardavon
        </Typography>
      </Grid>
      <Grid container spacing={2}>
        <Box height="28px"></Box>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body1" className={common.fontLargeNormal}>
          Here's what to expect next
        </Typography>
      </Grid>
      <Grid container spacing={2}>
        <Box height="30px"></Box>
      </Grid>
      <Grid item xs={12} style={{ marginTop: "0px" }}>
        <ul
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            margin: 0,
          }}
        >
          {metadata.formType === FormType.Referral ? (
            <>
              <Typography variant="body2" component="li">
                A confirmation message will be sent to you
              </Typography>

              <Typography variant="body2" component="li">
                If we need any further information, we'll contact you shortly.
              </Typography>

              <Typography variant="body2" component="li">
                You'll get an email confirming when we've placed this patient
                for his/her first visit
              </Typography>

              <Typography variant="body2" component="li">
                If you have any problems or questions, call us at (913) 236-3551
              </Typography>
            </>
          ) : (
            <>
              <Typography variant="body2" component="li">
                We'll copy you when we send this authorization to the clinic.
              </Typography>

              <Typography variant="body2" component="li">
                If we need any further information, we'll contact you shortly.
              </Typography>

              <Typography variant="body2" component="li">
                If you have any problems or questions, call us at (913)
                236-1020, Option 2
              </Typography>
            </>
          )}
        </ul>
      </Grid>
      <Grid container spacing={2}>
        <Grid container spacing={2}>
          <Box height="60px"></Box>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                size="medium"
                onClick={() => {
                  setFiles([]);
                  setFormData({});
                  setMetadata({
                    formStep: 0,
                    formType: metadata.formType,
                    referralId: undefined,
                    reauthId: undefined,
                  });
                  navigate("/");
                }}
              >
                <Typography variant="button">Done</Typography>
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="outlined"
                color="primary"
                size="medium"
                onClick={() => {
                  setFiles([]);
                  setFormData({});
                  setMetadata({
                    formStep: 1,
                    formType: metadata.formType,
                    referralId: undefined,
                    reauthId: undefined,
                  });
                  navigate(
                    metadata.formType === FormType.Referral
                      ? "/referral/requiredInfo"
                      : "/reauth/authorization"
                  );
                }}
              >
                <Typography variant="button">Add Another</Typography>
              </Button>
            </Grid>
          </Grid>
        </Grid>

        <Grid container>
          <Grid item xs={12}>
            <div className={classes.printLinkContainer}>
              <Link
                href={pdfUrl}
                target="_blank"
                component="a"
                className={classes.link}
              >
                <Icon icon={icPrint} size={22} style={{ marginRight: "8px" }} />
                Print a copy for your records
              </Link>

              <Link
                href={
                  "https://forms.office.com/pages/responsepage.aspx?id=3fh1FNGx7U6LtFvU1kutXx8CFGX-oFlGsu4lVVxF-uJUOFRYUDVWWTlGRkpMOFlBVkxKV0hNQ09KNi4u"
                }
                target="_blank"
                className={classes.link}
              >
                <Icon icon={icForum} size={22} style={{ marginRight: "8px" }} />
                Give us feedback on this form
              </Link>
            </div>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Box height="20px"></Box>
          <CondensedReview
            allFormData={{ ...formData, ...userData, fileUploads: reviewFiles }}
            completedPage
            formType={metadata.formType}
          />
          <Grid item xs={12}>
            <Button
              variant="contained"
              color="primary"
              size="medium"
              href={pdfUrl}
              target="_blank"
              style={{ marginTop: "20px" }}
            >
              <Typography variant="button">Print</Typography>
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default CompletedForm;
