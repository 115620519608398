import { useMemo, useRef } from "react";
import { useContext, useEffect, useState } from "react";
import { FormContext } from "../../contexts/FormContext";
import {
  Button,
  Box,
  Grid,
  FormControlLabel,
  Typography,
  Card,
  CardContent,
} from "@material-ui/core";
import { Field, Form, ErrorMessage, useFormik, FormikProvider } from "formik";
import { MagicTextField } from "../inputs/generic/MagicTextField";
import { MagicDateField } from "../inputs/generic/MagicDateField";
import { MagicPhoneField } from "../inputs/generic/MagicPhoneField";
import { MagicSelect } from "../inputs/generic/MagicSelect";
import { CheckboxLabel } from "../inputs/generic/Checkbox";
import RadioGroupField from "../inputs/generic/RadioGroupField";
import MagicCheckbox from "../inputs/generic/MagicCheckbox";
import languageChoices from "../../constants/languageChoices";
import states from "../../constants/states";
import { ERROR_NO_FUTURE_DATES } from "../../constants/textConstants";
import DialogContainer from "../dialogs/DialogContainer";
import UploadDialog from "../dialogs/UploadDialog";
import requiredInfoFormValidation from "../../yup/RequiredInfoFormValidation";
import styleVars from "../../_export.module.scss";
import { ReferringPhysicianSelect } from "../inputs/autocomplete/PhysicianSelect";
import { EmployerSelect } from "../inputs/autocomplete/EmployerSelect";
import { BodyPartSelect } from "../inputs/autocomplete/BodyPartSelect";
import { ICDSelect } from "../inputs/autocomplete/ICDSelect";
import common from "../../Common.module.scss";

import Icon from "react-icons-kit";
import { ic_file_upload as icFileUpload } from "react-icons-kit/md";
import { ic_file_copy as icFileCopy } from "react-icons-kit/md";

import {
  initializedReqInfoForm,
  ReferralFormSchema,
  RequiredInfoFormSchema,
  UserType,
} from "../../types/FormSchema";

interface Props {
  fileOpenOnMount?: boolean;
}

type Program = {
  Id: string;
  Name: string;
  ClientName: string;
  States: string;
};

const RequiredInfoForm = ({ fileOpenOnMount }: Props) => {
  const {
    formValid,
    userData,
    formData,
    formStep,
    files,
    clearFormData,
    setFormData,
    setFormStep,
    setFormValid,
    setClearFormData,
    setFiles,
  } = useContext(FormContext);

  const referralFormData = useMemo(
    () => formData as ReferralFormSchema,
    [formData]
  );
  const formik = useFormik({
    initialValues: clearFormData
      ? initializedReqInfoForm
      : ({
          ...initializedReqInfoForm,
          ...referralFormData,
          checkBoxForPhysicianInfo:
            userData?.userNewOrExisting === "existing"
              ? !!referralFormData?.checkBoxForPhysicianInfo
              : true,
          checkBoxPhysicianEmailPreferred:
            !!referralFormData?.checkBoxPhysicianEmailPreferred,
          checkBoxPhysicianPhonePreferred:
            !!referralFormData?.checkBoxPhysicianPhonePreferred,
          checkBoxForEmployer: !!referralFormData?.checkBoxForEmployer,
          checkBoxEmployerEmailPreferred:
            !!referralFormData?.checkBoxEmployerEmailPreferred,
          checkBoxEmployerPhonePreferred:
            !!referralFormData?.checkBoxEmployerPhonePreferred,
        } as RequiredInfoFormSchema),

    validationSchema: requiredInfoFormValidation,
    onSubmit: () => {},
    validateOnChange: true,
    validateOnBlur: true,
    //Prevents timing issues with form context being updated and the new component initializing state from saved formData.  Not ideal but will work for now.
    enableReinitialize: true,
  });
  const { values, validateForm, setFieldValue, setTouched, isValid } = formik;
  const refValues = useRef(values);

  const [programs, setPrograms] = useState<any[]>([]);
  const [programStates, setProgramStates] = useState<string[]>([]);

  const filteredStates =
    programStates.length > 0
      ? states.filter((s) => programStates.includes(s["value"]))
      : states;

  function getProgramClientName(): string | null {
    if (userData?.userEmail) {
      if (userData.userEmail.toLowerCase().includes("paradigm")) {
        return "Paradigm";
      }
    }
    return null;
  }

  const [programClientName] = useState(getProgramClientName());

  useEffect(() => {
    if(clearFormData){
      setFormData({})
      setFiles([])
      setClearFormData(false)
    }
  }, [clearFormData, setFormData, setClearFormData, setFiles]);

  useEffect(() => {
    setFieldValue("hasProgram", !!programClientName);

    if (programClientName && programClientName?.length >= 3) {
      fetch(
        `${process.env.REACT_APP_REFERRAL_BACKEND}/public/referral/programs/${programClientName}`,
        {
          headers: { accept: "application/json" },
        }
      )
        .then((response) => {
          if (!response.ok) {
            throw new Error(JSON.stringify(response.status));
          }
          return response.json();
        })
        .then((data) => {
          const clientPrograms = data.map((p: Program) => {
            return {
              key: p.Id,
              value: p.Id,
              name: p.Name,
            };
          });
          setPrograms(clientPrograms);

          // extract the set of states to filter the state drop-down
          const allStates: Set<string> = new Set<string>();
          data.forEach((p: Program) => {
            p.States.split(",").forEach((state) => allStates.add(state));
          });
          setProgramStates(Array.from(allStates));
        })
        .catch((error) => console.log("Error:", error));
    }
  }, [programClientName, formData, setFieldValue]);

  useEffect(() => {
    refValues.current = values;
  }, [values]);

  useEffect(() => {
    window.scrollTo(0, 0);
    validateForm();
  }, [validateForm]);

  useEffect(() => {
    //Local state is the source of truth, context just exposes current form validation state for use in navigation.
    if (isValid !== formValid) {
      setFormValid(isValid);
    }
  }, [isValid, formValid, setFormValid]);

  useEffect(() => {
    if (formStep < 1 || (!isValid && formStep !== 1)) {
      setFormStep(1);
    }
  }, [formStep, isValid, setFormStep]);

  useEffect(() => {
    // When the component unmounts, update the context values with the current form values
    return () => {
      setFormData((prevValues) => {
        return {
          ...prevValues,
          ...refValues.current,
        };
      });
    };
  }, [setFormData, clearFormData, setClearFormData]);

  return (
    <FormikProvider value={formik}>
      <Form noValidate>
        <Grid item xs={12}>
          <Typography
            className="fix-autocomplete-align"
            variant="h5"
            style={{ color: styleVars.colorReferralBlue }}
          >
            Who are you referring today?
          </Typography>
        </Grid>
        <Grid container spacing={2}>
          <Box height="28px"></Box>
        </Grid>
        <Grid item xs={12}>
          <Typography
            variant="body2"
            style={{
              fontSize: styleVars.fontSizeXL,
              fontWeight: 400,
              color: styleVars.colorSecondaryText,
            }}
          >
            The info below is all we need to get your patient into a clinic
            ASAP.
          </Typography>
        </Grid>
        <Grid container spacing={2}>
          <Box height="45px"></Box>
        </Grid>
        <Grid item xs={12}>
          <Typography
            variant="body1"
            style={{
              fontSize: styleVars.fontSizeLarge,
              color: styleVars.colorSecondaryText,
            }}
          >
            Have a prescription, FROI or other docs? Upload them here:
            <DialogContainer
              hideCloseButton={true}
              initialState={fileOpenOnMount}
              dialogTrigger={({ toggleDialog }) => (
                <>
                  <Button
                    variant="contained"
                    color="secondary"
                    size="medium"
                    onClick={toggleDialog}
                    style={{
                      marginLeft: "15px",
                      backgroundColor: styleVars.colorBlue,
                      width: "110px",
                    }}
                  >
                    <Icon icon={icFileUpload} size={24} />
                    <Typography
                      variant="button"
                      style={{
                        fontSize: styleVars.fontSizeNormal,
                        marginLeft: "6px",
                        minHeight: "25px",
                        fontWeight: 700,
                      }}
                    >
                      UPLOAD
                    </Typography>
                  </Button>
                  {files.length ? (
                    <Typography
                      component="span"
                      style={{
                        color: styleVars.colorBlue,
                        marginLeft: "10px",
                      }}
                    >
                      <Icon icon={icFileCopy} size={24} /> {files.length}{" "}
                      documents uploaded
                    </Typography>
                  ) : null}
                </>
              )}
              dialogContent={({ toggleDialog }) => (
                <UploadDialog toggleDialog={toggleDialog} />
              )}
            />
          </Typography>
        </Grid>
        <Grid container spacing={2}>
          <Box height="40px"></Box>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Field
              required
              component={MagicTextField}
              name="patientFirstName"
              type="text"
              label="Patient first name"
              helperText={<ErrorMessage name="patientFirstName" />}
            />
          </Grid>
          <Grid item xs={6}>
            <Field
              required
              component={MagicTextField}
              name="patientLastName"
              type="text"
              label="Patient last name"
              helperText={<ErrorMessage name="patientLastName" />}
            />
          </Grid>
          <Grid item xs={6}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Field
                  component={MagicDateField}
                  id="patientBirthDate"
                  disableFuture
                  label="Birthday"
                  maxDateMessage={ERROR_NO_FUTURE_DATES}
                  name="patientBirthday"
                  required
                  InputLabelProps={{
                    shrink: !!values.patientBirthday,
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <Field
                  component={MagicPhoneField}
                  label="Phone number"
                  name="patientPhoneNumber"
                  value={values.patientPhoneNumber}
                  type="tel"
                  required
                  InputProps={{
                    onChange: (e: { target: HTMLInputElement }) =>
                      setFieldValue(
                        "patientPhoneNumber",
                        e.target.value,
                        false
                      ),
                  }}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={5}>
            <Grid
              container
              spacing={2}
              alignItems="center"
              style={{
                color: styleVars.colorSecondaryText,
              }}
            >
              <Grid item xs={1}>
                <Typography variant="body1" className={common.fontLargeNormal}>
                  Sex
                </Typography>
              </Grid>
              <Grid item xs={11}>
                <RadioGroupField
                  name="patientSex"
                  row
                  options={[
                    { value: "M", label: "Male" },
                    { value: "F", label: "Female" },
                  ]}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <Field
              name="patientPrimaryLanguage"
              label="Primary language"
              component={MagicSelect}
              items={languageChoices}
              onChange={(e: { target: HTMLInputElement }) => {
                setFieldValue("patientPrimaryLanguage", e.target.value);
              }}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={6}>
            {userData?.userType &&
              [UserType.Adjuster, UserType.CaseManager].includes(
                userData.userType
              ) && (
                <Grid container spacing={0}>
                  <Grid item xs={4} lg={6}>
                    <FormControlLabel
                      control={
                        <MagicCheckbox
                          name="interpreterAuthorized"
                          setFieldValue={setFieldValue}
                        />
                      }
                      label={
                        <Typography
                          variant="body1"
                          component="span"
                          style={{
                            fontSize: styleVars.fontSizeLarge,
                            color: styleVars.colorSecondaryText,
                            whiteSpace: "nowrap",
                          }}
                        >
                          Interpreter authorized
                        </Typography>
                      }
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <FormControlLabel
                      style={{ whiteSpace: "nowrap", paddingLeft: "7px" }}
                      control={
                        <MagicCheckbox
                          name="transportationAuthorized"
                          setFieldValue={setFieldValue}
                        />
                      }
                      label={
                        <Typography
                          variant="body1"
                          style={{
                            fontSize: styleVars.fontSizeLarge,
                            color: styleVars.colorSecondaryText,
                          }}
                        >
                          Transportation authorized
                        </Typography>
                      }
                    />
                  </Grid>
                </Grid>
              )}
          </Grid>
          <Grid item xs={6}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Field
                  required
                  component={MagicTextField}
                  name="claimNumber"
                  type="text"
                  label="Claim number"
                  helperText={<ErrorMessage name="claimNumber" />}
                />
              </Grid>
              <Grid item xs={6}>
                <Field
                  required
                  name="stateOfJury"
                  label="State of jur."
                  component={MagicSelect}
                  items={filteredStates}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={6}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Field
                  name="caseType"
                  label="Case type"
                  component={MagicSelect}
                  items={[
                    {
                      name: "\0",
                      value: "",
                    },
                    { value: "PT", id: "PT", name: "Physical Therapy" },
                    {
                      value: "OT",
                      id: "OT",
                      name: "Occupational Therapy",
                    },
                    {
                      value: "WC",
                      id: "WC",
                      name: "Work Conditioning",
                    },
                  ]}
                  onChange={(e: { target: HTMLInputElement }) => {
                    setFieldValue("caseType", e.target.value);
                    if (e.target.value !== "PT" && e.target.value !== "OT") {
                      setFieldValue("specialty", "");
                    }
                  }}
                />
              </Grid>
              {!!(values?.caseType === "PT" || values?.caseType === "OT") &&
                (values?.stateOfJury !== "CA" ? (
                  <Grid item xs={6}>
                    <Field
                      name="specialty"
                      label="Specialty"
                      component={MagicSelect}
                      items={[
                        {
                          value: "",
                          id: "None",
                          name: "None",
                        },
                        {
                          value: "Telerehab",
                          id: "Telerehab",
                          name: "Telerehab",
                        },
                        {
                          value: "Home Health",
                          id: "Home Health",
                          name: "Home Health",
                        },
                        {
                          value: "Hybrid",
                          id: "Hybrid",
                          name: "Hybrid",
                        },
                      ]}
                    />
                  </Grid>
                ) : (
                  <Grid item xs={6}>
                    <Field
                      name="specialty"
                      label="Specialty"
                      component={MagicSelect}
                      items={[
                        {
                          value: "",
                          id: "None",
                          name: "None",
                        },
                        {
                          value: "Telerehab",
                          id: "Telerehab",
                          name: "Telerehab",
                        },
                        {
                          value: "Home Health",
                          id: "Home Health",
                          name: "Home Health",
                        },
                        {
                          value: "Hybrid",
                          id: "Hybrid",
                          name: "Hybrid",
                        },
                        {
                          value: "Chiropractic",
                          id: "Chiropractic",
                          name: "Chiropractic",
                        },
                        {
                          value: "Acupuncture",
                          id: "Acupuncture",
                          name: "Acupuncture",
                        },
                      ]}
                    />
                  </Grid>
                ))}
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              {userData?.userNewOrExisting === "existing" && (
                <Grid item xs={6}>
                  {!!values?.checkBoxForPhysicianInfo ? (
                    <ReferringPhysicianSelect
                      name="treatingPhysicianPlaceholder"
                      label="Treating physician"
                      required
                      disabled
                      {...formik}
                    />
                  ) : (
                    <ReferringPhysicianSelect
                      name="treatingPhysician"
                      label="Treating physician"
                      required
                      disabled={!!values?.checkBoxForPhysicianInfo}
                      {...formik}
                    />
                  )}
                </Grid>
              )}

              <Grid item xs={6}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Field
                      component={MagicDateField}
                      id="dateOfInjury"
                      disableFuture
                      label="Date of injury"
                      maxDateMessage={ERROR_NO_FUTURE_DATES}
                      name="dateOfInjury"
                      InputLabelProps={{
                        shrink: !!values.dateOfInjury,
                      }}
                      required
                    />
                  </Grid>
                  <Grid item xs={6}></Grid>
                </Grid>
              </Grid>
              {userData?.userNewOrExisting === "existing" &&
                !values?.checkBoxForPhysicianInfo && (
                  <Grid item xs={12}>
                    <CheckboxLabel
                      labelText={
                        <Typography
                          component="span"
                          variant="body1"
                          style={{
                            fontSize: styleVars.fontSizeLarge,
                            color: styleVars.colorSecondaryText,
                            fontWeight: 600,
                          }}
                        >
                          Treating physician not in list - I'll enter the
                          information
                        </Typography>
                      }
                      name="checkBoxForPhysicianInfo"
                      checked={values.checkBoxForPhysicianInfo}
                      disabled={false}
                      onChange={(event: any) => {
                        setFieldValue(
                          "checkBoxForPhysicianInfo",
                          event.target.checked
                        );
                      }}
                    />
                  </Grid>
                )}
            </Grid>
          </Grid>

          {!!values?.checkBoxForPhysicianInfo && (
            <Grid item xs={12} style={{ marginBottom: "8px" }}>
              <Card
                style={{
                  borderRadius: "0",
                  background: styleVars.colorCardBlue,
                }}
              >
                <CardContent>
                  <Grid container spacing={2}>
                    {userData?.userNewOrExisting === "existing" &&
                      !!values?.checkBoxForPhysicianInfo && (
                        <Grid item xs={12} style={{ padding: "0 8px" }}>
                          <CheckboxLabel
                            labelText={
                              <Typography
                                component="span"
                                variant="body1"
                                style={{
                                  fontSize: styleVars.fontSizeLarge,
                                  color: styleVars.colorSecondaryText,
                                  fontWeight: 600,
                                }}
                              >
                                Treating physician not in list - I'll enter the
                                information
                              </Typography>
                            }
                            name="checkBoxForPhysicianInfo"
                            checked={values.checkBoxForPhysicianInfo}
                            disabled={false}
                            onChange={(event: any) => {
                              setFieldValue(
                                "checkBoxForPhysicianInfo",
                                event.target.checked
                              );
                              if (event.target.checked) {
                                setTouched({});
                                setFieldValue("treatingPhysician", "");
                              }
                            }}
                          />
                        </Grid>
                      )}
                    <Grid item xs={12}>
                      <Typography
                        style={{
                          fontWeight: 600,
                          fontSize: "15px",
                          paddingLeft: "3px",
                        }}
                      >
                        Treating physician{" "}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Field
                        required
                        component={MagicTextField}
                        name="physicianFirstName"
                        disabled={false}
                        type="text"
                        label="First name"
                        helperText={<ErrorMessage name="physicianFirstName" />}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Field
                        required
                        component={MagicTextField}
                        name="physicianLastName"
                        disabled={false}
                        type="text"
                        label="Last name"
                        helperText={<ErrorMessage name="physicianLastName" />}
                      />
                    </Grid>
                    <Grid container style={{ paddingLeft: "9px" }}>
                      <Typography
                        variant="body2"
                        style={{ color: styleVars.colorSecondaryText }}
                      >
                        Contact method (choose one*)
                      </Typography>
                    </Grid>
                    <Grid item xs={8}>
                      <Field
                        required={!!values?.checkBoxPhysicianEmailPreferred}
                        component={MagicTextField}
                        name="physicianEmail"
                        disabled={false}
                        type="text"
                        label="Email"
                        helperText={<ErrorMessage name="physicianEmail" />}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={4}
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <CheckboxLabel
                        labelText={
                          <Typography
                            component="span"
                            variant="body1"
                            style={{
                              fontSize: styleVars.fontSizeLarge,
                              color: styleVars.colorSecondaryText,
                            }}
                          >
                            Preferred method of contact
                          </Typography>
                        }
                        name="checkBoxPhysicianEmailPreferred"
                        checked={values.checkBoxPhysicianEmailPreferred}
                        disabled={false}
                        onChange={(e: { target: HTMLInputElement }) => {
                          const { checked } = e.target;
                          setFieldValue(
                            "checkBoxPhysicianEmailPreferred",
                            e.target.checked
                          );
                          if (
                            checked &&
                            values.checkBoxPhysicianPhonePreferred
                          ) {
                            setFieldValue(
                              "checkBoxPhysicianPhonePreferred",
                              false
                            );
                          }
                        }}
                      />
                    </Grid>
                    {/* <Grid item xs={2}></Grid> */}
                    <Grid item xs={3}>
                      <Field
                        required={!!values?.checkBoxPhysicianPhonePreferred}
                        component={MagicPhoneField}
                        label="Phone number"
                        name="physicianPhoneNumber"
                        value={values.physicianPhoneNumber}
                        type="tel"
                        disabled={false}
                        InputProps={{
                          onChange: (e: { target: HTMLInputElement }) =>
                            setFieldValue(
                              "physicianPhoneNumber",
                              e.target.value,
                              false
                            ),
                        }}
                      />
                    </Grid>

                    <Grid item xs={3}>
                      <Field
                        name="physicianPhoneType"
                        label="Type"
                        id="physician-phone-type-select"
                        component={MagicSelect}
                        disabled={false}
                        items={[
                          {
                            name: "\0",
                            value: "",
                          },
                          { name: "Mobile", value: "M" },
                          { name: "Work", value: "W" },
                        ]}
                        onChange={(e: { target: HTMLInputElement }) => {
                          setFieldValue("physicianPhoneType", e.target.value);
                        }}
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <Field
                        component={MagicTextField}
                        name="physicianPhoneExtension"
                        type="text"
                        disabled={false}
                        label="Extension"
                        helperText={
                          <ErrorMessage name="physicianPhoneExtension" />
                        }
                      />
                    </Grid>
                    <Grid
                      item
                      xs={4}
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <CheckboxLabel
                        labelText={
                          <Typography
                            component="span"
                            variant="body1"
                            style={{
                              fontSize: styleVars.fontSizeLarge,
                              color: styleVars.colorSecondaryText,
                            }}
                          >
                            Preferred method of contact
                          </Typography>
                        }
                        name="checkBoxPhysicianPhonePreferred"
                        checked={values.checkBoxPhysicianPhonePreferred}
                        disabled={false}
                        onChange={(e: { target: HTMLInputElement }) => {
                          const { checked } = e.target;
                          setFieldValue(
                            "checkBoxPhysicianPhonePreferred",
                            e.target.checked
                          );
                          if (
                            checked &&
                            values.checkBoxPhysicianEmailPreferred
                          ) {
                            setFieldValue(
                              "checkBoxPhysicianEmailPreferred",
                              false
                            );
                          }
                        }}
                      />
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          )}

          <Grid item xs={6}>
            <ICDSelect name="medicalDiagnosis" {...formik} />
          </Grid>
          <Grid item xs={6}></Grid>
          <Grid item xs={true}>
            <BodyPartSelect name="bodyPart" {...formik} />
          </Grid>
          <Grid item xs="auto" style={{ width: "130px" }}>
            <Field
              name="surgical"
              label="Surgical"
              component={MagicSelect}
              items={[
                {
                  name: "\0",
                  value: "",
                },
                { name: "Yes", value: "surgical" },
                { name: "No", value: "nonsurgical" },
                { name: "Unknown", value: "unknown" },
              ]}
              onChange={(e: { target: HTMLInputElement }) => {
                setFieldValue("surgical", e.target.value);
              }}
            />
          </Grid>
          <Grid item xs={3}>
            {values?.surgical === "surgical" && (
              <Field
                component={MagicDateField}
                id="dateOfSurgery"
                disableFuture
                label="Surgery date"
                maxDateMessage={ERROR_NO_FUTURE_DATES}
                name="dateOfSurgery"
                InputLabelProps={{
                  shrink: !!values.dateOfSurgery,
                }}
              />
            )}
          </Grid>
          <Grid item xs={3}></Grid>
          {programClientName && (
            <>
              <Grid item xs={6}>
                <Field
                  name="programId"
                  label="Program"
                  component={MagicSelect}
                  items={programs}
                  onChange={(e: { target: HTMLInputElement }) => {
                    setFieldValue("programId", e.target.value);
                  }}
                />
              </Grid>
              <Grid item xs={2}>
                <Field
                  name="programRisk"
                  label="Program Risk"
                  component={MagicSelect}
                  items={[
                    {
                      name: "\0",
                      value: "",
                    },
                    { name: "High", value: "High" },
                    { name: "Moderate", value: "Moderate" },
                    { name: "Low", value: "Low" },
                  ]}
                  onChange={(e: { target: HTMLInputElement }) => {
                    setFieldValue("programRisk", e.target.value);
                  }}
                />
              </Grid>
            </>
          )}

          {userData?.userNewOrExisting === "existing" && (
            <>
              <Grid item xs={6} style={{ paddingBottom: "0" }}>
                {!!values?.checkBoxForEmployer ? (
                  <EmployerSelect
                    className="fix-autocomplete-align"
                    name="employerSelectedPlaceholder"
                    disabled={!!values?.checkBoxForEmployer}
                    {...formik}
                  />
                ) : (
                  <EmployerSelect
                    className="fix-autocomplete-align"
                    name="employerSelected"
                    disabled={!!values?.checkBoxForEmployer}
                    {...formik}
                  />
                )}
              </Grid>
              <Grid item xs={6}></Grid>
            </>
          )}
          {userData?.userNewOrExisting === "existing" &&
            !values?.checkBoxForEmployer && (
              <Grid item xs={12} style={{ paddingTop: "0" }}>
                <CheckboxLabel
                  labelText={
                    <Typography
                      component="span"
                      variant="body1"
                      style={{
                        fontSize: styleVars.fontSizeLarge,
                        color: styleVars.colorSecondaryText,
                        fontWeight: 600,
                      }}
                    >
                      Employer not in list - I'll enter the information
                    </Typography>
                  }
                  name="checkBoxForEmployer"
                  checked={values.checkBoxForEmployer}
                  disabled={false}
                  onChange={(e: { target: HTMLInputElement }) => {
                    setFieldValue("checkBoxForEmployer", e.target.checked);
                    if (e.target.checked) {
                      setFieldValue("employerSelected", "");
                    }
                  }}
                />
              </Grid>
            )}

          {(!!values?.checkBoxForEmployer ||
            userData?.userNewOrExisting !== "existing") && (
            <Grid item xs={12}>
              <Card
                style={{
                  borderRadius: "0px",
                  background: styleVars.colorCardBlue,
                }}
              >
                <CardContent>
                  <Grid container spacing={2}>
                    {userData?.userNewOrExisting === "existing" && (
                      <Grid item xs={12} style={{ padding: "0 8px" }}>
                        <CheckboxLabel
                          labelText={
                            <Typography
                              component="span"
                              variant="body1"
                              style={{
                                fontSize: styleVars.fontSizeLarge,
                                color: styleVars.colorSecondaryText,
                                fontWeight: 600,
                              }}
                            >
                              Employer not in list - I'll enter the information
                            </Typography>
                          }
                          name="checkBoxForEmployer"
                          checked={values.checkBoxForEmployer}
                          disabled={false}
                          onChange={(e: { target: HTMLInputElement }) => {
                            setFieldValue(
                              "checkBoxForEmployer",
                              e.target.checked
                            );
                            if (e.target.checked) {
                              setFieldValue("employerSelected", "");
                            }
                          }}
                        />
                      </Grid>
                    )}
                    <Grid item xs={12}>
                      <Typography
                        style={{
                          fontWeight: 600,
                          fontSize: "15px",
                          paddingLeft: "3px",
                        }}
                      >
                        <span style={{ marginRight: "10px" }}>Employer </span>
                        {userData?.userNewOrExisting !== "existing" && (
                          <CheckboxLabel
                            labelText={
                              <Typography
                                component="span"
                                variant="body1"
                                style={{
                                  fontSize: styleVars.fontSizeLarge,
                                  color: styleVars.colorSecondaryText,
                                  fontWeight: 600,
                                  verticalAlign: "middle",
                                }}
                              >
                                I have this information
                              </Typography>
                            }
                            name="checkBoxForEmployer"
                            checked={values.checkBoxForEmployer}
                            disabled={false}
                            onChange={(e: { target: HTMLInputElement }) => {
                              setFieldValue(
                                "checkBoxForEmployer",
                                e.target.checked
                              );
                              if (e.target.checked) {
                                setFieldValue("employerSelected", "");
                              }
                            }}
                          />
                        )}
                      </Typography>
                    </Grid>
                    {!!values?.checkBoxForEmployer && (
                      <>
                        <Grid item xs={6}>
                          <Field
                            required
                            component={MagicTextField}
                            name="employerCompany"
                            type="text"
                            label="Company"
                            helperText={<ErrorMessage name="employerCompany" />}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <Field
                            component={MagicTextField}
                            name="employerContactName"
                            type="text"
                            label="Contact name"
                            helperText={
                              <ErrorMessage name="employerContactName" />
                            }
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <Field
                            component={MagicTextField}
                            name="employerAddress"
                            type="text"
                            label="Address"
                            helperText={<ErrorMessage name="employerAddress" />}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <Grid container spacing={2}>
                            <Grid item xs={5}>
                              <Field
                                component={MagicTextField}
                                name="employerCity"
                                type="text"
                                label="City"
                                helperText={
                                  <ErrorMessage name="employerCity" />
                                }
                              />
                            </Grid>
                            <Grid item xs={4}>
                              <Field
                                name="employerState"
                                label="State"
                                component={MagicSelect}
                                items={states}
                              />
                            </Grid>
                            <Grid item xs={3}>
                              <Field
                                component={MagicTextField}
                                name="employerZipCode"
                                type="text"
                                label="ZIP"
                                helperText={
                                  <ErrorMessage name="employerZipCode" />
                                }
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid container style={{ paddingLeft: "9px" }}>
                          <Typography
                            variant="body2"
                            style={{ color: styleVars.colorSecondaryText }}
                          >
                            Contact method (choose one*)
                          </Typography>
                        </Grid>
                        <Grid item xs={8}>
                          <Field
                            required={!!values?.checkBoxEmployerEmailPreferred}
                            component={MagicTextField}
                            name="employerEmail"
                            type="text"
                            label="Email"
                            helperText={<ErrorMessage name="employerEmail" />}
                          />
                        </Grid>
                        <Grid
                          item
                          xs={4}
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          <CheckboxLabel
                            labelText={
                              <Typography
                                component="span"
                                variant="body1"
                                style={{
                                  fontSize: styleVars.fontSizeLarge,
                                  color: styleVars.colorSecondaryText,
                                }}
                              >
                                Preferred method of contact
                              </Typography>
                            }
                            name="checkBoxEmployerEmailPreferred"
                            checked={values.checkBoxEmployerEmailPreferred}
                            disabled={false}
                            onChange={(e: { target: HTMLInputElement }) => {
                              const { checked } = e.target;
                              setFieldValue(
                                "checkBoxEmployerEmailPreferred",
                                e.target.checked
                              );
                              if (
                                checked &&
                                values.checkBoxEmployerPhonePreferred
                              ) {
                                setFieldValue(
                                  "checkBoxEmployerPhonePreferred",
                                  false
                                );
                              }
                            }}
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <Field
                            required={!!values?.checkBoxEmployerPhonePreferred}
                            component={MagicPhoneField}
                            label="Phone number"
                            name="employerPhoneNumber"
                            value={values.employerPhoneNumber}
                            type="tel"
                            InputProps={{
                              onChange: (e: { target: HTMLInputElement }) =>
                                setFieldValue(
                                  "employerPhoneNumber",
                                  e.target.value,
                                  false
                                ),
                            }}
                          />
                        </Grid>

                        <Grid item xs={3}>
                          <Field
                            name="employerPhoneType"
                            label="Type"
                            id="employer-phone-type-select"
                            component={MagicSelect}
                            items={[
                              {
                                name: "\0",
                                value: "",
                              },
                              { name: "Home", value: "H" },
                              { name: "Mobile", value: "M" },
                              { name: "Work", value: "W" },
                            ]}
                            onChange={(e: { target: HTMLInputElement }) => {
                              setFieldValue(
                                "employerPhoneType",
                                e.target.value
                              );
                            }}
                          />
                        </Grid>
                        <Grid item xs={2}>
                          <Field
                            component={MagicTextField}
                            name="employerPhoneExtension"
                            type="text"
                            label="Extension"
                            helperText={
                              <ErrorMessage name="employerPhoneExtension" />
                            }
                          />
                        </Grid>
                        <Grid
                          item
                          xs={4}
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          <CheckboxLabel
                            labelText={
                              <Typography
                                component="span"
                                variant="body1"
                                style={{
                                  fontSize: styleVars.fontSizeLarge,
                                  color: styleVars.colorSecondaryText,
                                }}
                              >
                                Preferred method of contact
                              </Typography>
                            }
                            name="checkBoxEmployerPhonePreferred"
                            checked={values.checkBoxEmployerPhonePreferred}
                            disabled={false}
                            onChange={(e: { target: HTMLInputElement }) => {
                              const { checked } = e.target;
                              setFieldValue(
                                "checkBoxEmployerPhonePreferred",
                                e.target.checked
                              );
                              if (
                                checked &&
                                values.checkBoxEmployerEmailPreferred
                              ) {
                                setFieldValue(
                                  "checkBoxEmployerEmailPreferred",
                                  false
                                );
                              }
                            }}
                          />
                        </Grid>
                      </>
                    )}
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          )}
          <Grid item xs={6}>
            <Field
              component={MagicTextField}
              name="jobTitle"
              type="text"
              label="Job title"
              helperText={<ErrorMessage name="jobTitle" />}
            />
          </Grid>
        </Grid>
      </Form>
    </FormikProvider>
  );
};

export default RequiredInfoForm;
