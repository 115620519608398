import { useContext, useMemo } from "react";
import { Navigate, useLocation } from "react-router-dom";
import RequiredInfoForm from "../forms/RequiredInfoForm";
import AdditionalInfoForm from "../forms/AdditionalInfoForm";
import AuthorizationForm from "../forms/AuthorizationForm";
import ReviewForm from "../forms/ReviewForm";
import CompletedForm from "../forms/CompletedForm";
import { FormContext } from "../../contexts/FormContext";

/*
This page orchestrates rendering referral and reauth form components.
*/
const FormRouting = () => {
  const { userData, metadata } = useContext(FormContext);
  const location = useLocation();

  const getFormStep = useMemo(() => {
    switch (location.pathname || "") {
      case "/referral/requiredInfo":
        return <RequiredInfoForm fileOpenOnMount={false} />;
      case "/referral/requiredInfo/file":
        return <RequiredInfoForm fileOpenOnMount={true} />;
      case `/${metadata.formType}/authorization`:
        return <AuthorizationForm fileOpenOnMount={false} />;
      case `/reauth/authorization/file`:
        return <RequiredInfoForm fileOpenOnMount={true} />;
      case `/referral/additionalInfo`:
        return <AdditionalInfoForm />;
      case `/${metadata.formType}/review`:
        return <ReviewForm />;
      case `/${metadata.formType}/complete`:
        return <CompletedForm />;
      default:
        return <Navigate to="/" replace />;
    }
  }, [location.pathname, metadata.formType]);

  // Extra guardrails for navigation, probably won't ever be used outside of direct navigation.
  if (
    !userData?.userType ||
    !metadata?.formType ||
    (["authorization", "review", "complete"].some((pathEnd) =>
      location.pathname.endsWith(pathEnd)
    ) &&
      !location.pathname.startsWith(`/${metadata.formType}`))
  )
    return <Navigate to="/" replace />;

  return getFormStep;
};

export default FormRouting;
