import { useContext, useEffect, useMemo, useRef } from "react";
import { Box, Button, Grid, Typography } from "@material-ui/core";

import { Field, Form, ErrorMessage, useFormik, FormikProvider } from "formik";
import { FormContext } from "../../contexts/FormContext";
import { MagicTextField } from "../inputs/generic/MagicTextField";
import { MagicDateField } from "../inputs/generic/MagicDateField";
import { MagicSelect } from "../inputs/generic/MagicSelect";

import { ERROR_NO_FUTURE_DATES } from "../../constants/textConstants";
import authorizationFormValidation from "../../yup/AuthorizationFormValidation";
import reauthorizationFormValidation from "../../yup/ReauthorizationFormValidation";
import styleVars from "../../_export.module.scss";
import {
  AuthorizationFormSchema,
  BodyPartsNewOrExisting,
  FormType,
  initializedAuthForm,
  initializedReauthForm,
  ReauthorizationFormSchema,
  UserType,
} from "../../types/FormSchema";
import common from "../../Common.module.scss";
import UploadDialog from "../dialogs/UploadDialog";
import DialogContainer from "../dialogs/DialogContainer";
import Icon from "react-icons-kit";
import { ic_file_upload as icFileUpload } from "react-icons-kit/md";
import { ic_file_copy as icFileCopy } from "react-icons-kit/md";
import RadioGroupField from "../inputs/generic/RadioGroupField";
import { BodyPartSelect } from "../inputs/autocomplete/BodyPartSelect";

interface AuthorizationFormProps {
  fileOpenOnMount?: boolean;
}

const AuthorizationForm = ({ fileOpenOnMount }: AuthorizationFormProps) => {
  const {
    formStep,
    formData,
    formValid,
    metadata,
    files,
    clearFormData,
    userData,
    setFormData,
    setFormStep,
    setFormValid,
    setFiles,
    setClearFormData,
  } = useContext(FormContext);

  const intializedFormValues = useMemo(
    () =>
      metadata.formType === FormType.Referral
        ? initializedAuthForm
        : initializedReauthForm,
    [metadata.formType]
  );

  const formik = useFormik({
    initialValues: clearFormData
      ? intializedFormValues
      : {
          ...intializedFormValues,
          ...formData,
          authAuthorizedBy:
            metadata.formType === FormType.Reauth && userData.userType === UserType.Adjuster
              ? "Adjuster"
              : metadata.formType === FormType.Reauth && userData.userType === UserType.CaseManager
              ? "Case Manager"
              : "",
        },

    validationSchema:
      metadata.formType === FormType.Referral
        ? authorizationFormValidation
        : reauthorizationFormValidation,
    onSubmit: () => {},
    validateOnChange: true,
    validateOnBlur: true,
    //Prevents timing issues with form context being updated and the new component initializing state from saved formData.  Not ideal but will work for now.
    enableReinitialize: true,
  });
  const { values, isValid, validateForm, setFieldValue } = formik;

  const refValues = useRef(values);

  const clearBodyParts = () => {
    setFieldValue("reauthBodyParts", []);
    validateForm();
  }

  useEffect(() => {
    if (clearFormData) {
      setFormData({});
      setFiles([]);
      setClearFormData(false);
    }
  }, [clearFormData, setFormData, setClearFormData, setFiles]);

  useEffect(() => {
    refValues.current = values;
  }, [values]);

  useEffect(() => {
    window.scrollTo(0, 0);
    validateForm();
  }, [validateForm]);

  useEffect(() => {
    const expandedValidation =
      metadata.formType === FormType.Referral
        ? isValid
        : isValid && !!files.length;
    //Local state is the source of truth, context just exposes current form validation state for use in navigation.
    if (expandedValidation !== formValid) {
      setFormValid(expandedValidation);
    }
  }, [files, formValid, isValid, metadata.formType, setFormValid]);

  useEffect(() => {
    const currentStep = metadata.formType === FormType.Referral ? 2 : 1;
    if (formStep < currentStep || (!isValid && formStep !== currentStep)) {
      setFormStep(currentStep);
    }
  }, [formStep, isValid, metadata.formType, setFormStep]);

  useEffect(() => {
    // When the component unmounts, update the context values with the current form values
    return () => {
      setFormData((prevValues) => {
        return {
          ...prevValues,
          ...refValues.current,
        };
      });
    };
  }, [setFormData]);

  return (
    <>
      <FormikProvider value={formik}>
        <Form noValidate>
          <Grid item xs={12}>
            <Box width="925px"></Box>
          </Grid>
          <Grid item xs={12}>
            <Typography
              variant="h5"
              style={{ color: styleVars.colorReferralBlue }}
            >
              {metadata.formType === FormType.Referral
                ? "Great. That's enough for us to get started."
                : "Tell us about your re-authorization"}
            </Typography>
          </Grid>
          <Grid container spacing={2}>
            <Box height="28px"></Box>
          </Grid>
          {metadata.formType === FormType.Referral ? (
            <>
              <Grid item xs={12}>
                <Typography
                  variant="body1"
                  className={common.fontLargeSecondary}
                >
                  We can schedule your patient more quickly if we have the info
                  below.
                </Typography>
              </Grid>
              <Grid container spacing={2}>
                <Box height="28px"></Box>
              </Grid>
            </>
          ) : null}

          {metadata.formType === FormType.Reauth ? (
            <>
              <Grid item xs={12}>
                <Typography
                  variant="body1"
                  style={{
                    fontSize: styleVars.fontSizeLarge,
                    color: styleVars.colorSecondaryText,
                  }}
                >
                  Please upload a prescription, FRA or certification letter.*
                  <DialogContainer
                    hideCloseButton={true}
                    initialState={fileOpenOnMount}
                    dialogTrigger={({ toggleDialog }) => (
                      <>
                        <Button
                          variant="contained"
                          color="secondary"
                          size="medium"
                          onClick={() => {
                            toggleDialog();
                          }}
                          style={{
                            marginLeft: "15px",
                            backgroundColor: styleVars.colorBlue,
                            width: "110px",
                          }}
                        >
                          <Icon icon={icFileUpload} size={24} />
                          <Typography
                            variant="button"
                            style={{
                              fontSize: styleVars.fontSizeNormal,
                              marginLeft: "6px",
                              minHeight: "25px",
                              fontWeight: 700,
                            }}
                          >
                            UPLOAD
                          </Typography>
                        </Button>
                        {files.length ? (
                          <Typography
                            component="span"
                            style={{
                              color: styleVars.colorBlue,
                              marginLeft: "10px",
                            }}
                          >
                            <Icon icon={icFileCopy} size={24} />
                            {files.length} documents uploaded
                          </Typography>
                        ) : null}
                      </>
                    )}
                    dialogContent={({ toggleDialog }) => (
                      <UploadDialog toggleDialog={toggleDialog} />
                    )}
                  />
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Box height="40px" />
              </Grid>
            </>
          ) : null}

          <Grid container spacing={2}>
            {metadata.formType === FormType.Referral ? (
              <>
                <Grid item xs={3}>
                  <Field
                    component={MagicDateField}
                    id="auth-script-date"
                    label="Script date"
                    name="authScriptDate"
                    InputLabelProps={{
                      shrink: !!values.authScriptDate,
                    }}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Field
                    component={MagicDateField}
                    id="auth-script-end-date"
                    label="Script expiration date"
                    name="authScriptEndDate"
                    InputLabelProps={{
                      shrink: !!(values as AuthorizationFormSchema)
                        .authScriptEndDate,
                    }}
                  />
                </Grid>
                <Grid item xs={6} />
              </>
            ) : (
              <>
                <Grid item xs={5}>
                  <Field
                    component={MagicTextField}
                    type="text"
                    label="Patient first name"
                    required
                    name="patientFirstName"
                    helperText={<ErrorMessage name="patientFirstName" />}
                  />
                </Grid>
                <Grid item xs={5}>
                  <Field
                    component={MagicTextField}
                    label="Patient last name"
                    required
                    name="patientLastName"
                    type="text"
                    helperText={<ErrorMessage name="patientLastName" />}
                  />
                </Grid>
              </>
            )}
            {metadata.formType === FormType.Reauth ? (
              <>
                <Grid item xs={3}>
                  <Field
                    component={MagicDateField}
                    id="patientBirthday"
                    label="Birthday"
                    name="patientBirthday"
                    required
                    InputLabelProps={{
                      shrink: !!(values as ReauthorizationFormSchema)
                        .patientBirthday,
                    }}
                    helperText={<ErrorMessage name="patientBirthday" />}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Field
                    component={MagicTextField}
                    name="claimNumber"
                    required
                    type="text"
                    label="Claim number"
                    helperText={<ErrorMessage name="claimNumber" />}
                  />
                </Grid>
                <Grid item xs={6}></Grid>
              </>
            ) : null}

            <Grid item xs={3}>
              <Field
                component={MagicTextField}
                required={metadata.formType === FormType.Reauth}
                name="authAuthorizedVisits"
                type="text"
                label="Authorized visits"
                helperText={<ErrorMessage name="authAuthorizedVisits" />}
              />
            </Grid>
            <Grid item xs="auto" style={{ width: "100px" }}>
              <Field
                component={MagicTextField}
                name="authVisitTimes"
                type="text"
                label="# times"
                helperText={<ErrorMessage name="authVisitTimes" />}
              />
            </Grid>
            <Grid
              item
              xs="auto"
              style={{ padding: "0", alignContent: "center" }}
            >
              <Typography
                style={{ whiteSpace: "nowrap", color: styleVars.colorGray }}
              >
                /week for
              </Typography>
            </Grid>
            <Grid item xs="auto" style={{ width: "72px" }}>
              <Field
                component={MagicTextField}
                name="authVisitWeeks"
                type="text"
                helperText={<ErrorMessage name="authVisitWeeks" />}
              />
            </Grid>
            <Grid
              item
              xs="auto"
              style={{ padding: "0", alignContent: "center" }}
            >
              <Typography
                style={{ whiteSpace: "nowrap", color: styleVars.colorGray }}
              >
                weeks
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Field
                required={metadata.formType === FormType.Reauth}
                name="authAuthorizedBy"
                label="Authorized by"
                id="authorized-by-select"
                component={MagicSelect}
                items={[
                  {
                    name: "\0",
                    value: "",
                  },
                  { name: "Adjuster", value: "Adjuster" },
                  { name: "Nurse/Case manager", value: "Case Manager" },
                  { name: "Other", value: "Other" },
                  { name: "Other network", value: "Other Network" },
                  { name: "Not authorized", value: "Not Authorized" },
                ]}
                onChange={(e: { target: HTMLInputElement }) => {
                  setFieldValue("authAuthorizedBy", e.target.value);
                }}
                helperText={<ErrorMessage name="authAuthorizedBy" />}
              />
            </Grid>

            {metadata.formType === FormType.Referral ? (
              <Grid item xs={4}>
                <Field
                  component={MagicTextField}
                  name="authAuthorizationNumber"
                  type="text"
                  label="Authorization number"
                  helperText={<ErrorMessage name="authAuthorizationNumber" />}
                />
              </Grid>
            ) : (
              <Grid item xs={6}></Grid>
            )}

            {metadata.formType === FormType.Reauth ? (
              <>
                <Grid item xs={3}>
                  <Field
                    component={MagicDateField}
                    id="auth-script-date"
                    label="Script date"
                    name="authScriptDate"
                    InputLabelProps={{
                      shrink: !!values.authScriptDate,
                    }}
                  />
                </Grid>
                <Grid item xs={9}></Grid>
              </>
            ) : null}

            {metadata.formType === FormType.Referral ? (
              <>
                <Grid item xs={3}>
                  <Field
                    component={MagicDateField}
                    disableFuture
                    id="auth-additional-injury-date"
                    label="Add'l injury date"
                    name="authAdditionalInjuryDate"
                    maxDateMessage={ERROR_NO_FUTURE_DATES}
                    InputLabelProps={{
                      shrink: !!(values as AuthorizationFormSchema)
                        .authAdditionalInjuryDate,
                    }}
                  />
                </Grid>
                <Grid item xs={9}></Grid>
              </>
            ) : null}

            {metadata.formType === FormType.Referral ? (
              <Grid item xs={3}>
                <Field
                  component={MagicDateField}
                  id="auth-return-to-doctor-date"
                  label="Return to Dr date"
                  name="authReturnToDoctorDate"
                  InputLabelProps={{
                    shrink: !!(values as AuthorizationFormSchema)
                      .authReturnToDoctorDate,
                  }}
                />
              </Grid>
            ) : null}

            {metadata.formType === FormType.Reauth ? (
              <>
                <Grid
                  container
                  direction="row"
                  alignItems="flex-start"
                  spacing={2}
                  style={{ padding: "8px" }}
                >
                  <Grid item>
                    <Typography
                      variant="body1"
                      className={common.fontLargeBold}
                      style={{ marginTop: "8px" }}
                    >
                      Choose one*:
                    </Typography>
                  </Grid>
                  <Grid item>
                    <RadioGroupField
                      name="useNewOrExistingBodyParts"
                      required
                      row
                      otherAction={clearBodyParts}
                      options={[
                        {
                          value: BodyPartsNewOrExisting.Existing,
                          label: "Continue with same body part(s)",
                        },
                        {
                          value: BodyPartsNewOrExisting.New,
                          label: "Add new body part(s)",
                        },
                      ]}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <BodyPartSelect
                      required={
                        (values as ReauthorizationFormSchema)
                          ?.useNewOrExistingBodyParts ===
                        BodyPartsNewOrExisting.New
                      }
                      disabled={
                        (values as ReauthorizationFormSchema)
                          ?.useNewOrExistingBodyParts !==
                        BodyPartsNewOrExisting.New
                      }
                      name="reauthBodyParts"
                      helperText={<ErrorMessage name="reauthBodyParts" />}
                      {...formik}
                    />
                  </Grid>
                </Grid>
              </>
            ) : null}
          </Grid>
        </Form>
      </FormikProvider>
    </>
  );
};

export default AuthorizationForm;
