import { UploadFileType } from "../types/FormSchema";
import { NewReauth, NewReferral } from "../types/SubmissionSchema";

export type GenericFormSubmitResponse = {
  id: string;
  //array of key/value pairs as strings
  errors: string[];
  status: string;
};

export type FetchReauthRequest = {
  reauthId: string;
  claimNumber: string;
  birthday: string;
};

export type FetchReferralRequest = {
  referralId: string;
  claimNumber: string;
  dateOfInjury: string;
};

export const postReferral = async (newReferral: NewReferral) => {
  const url = `${process.env.REACT_APP_REFERRAL_BACKEND}/public/referral/new`;
  return await genericSubmitForm(newReferral, url);
};

export const postReauth = async (newReauth: NewReauth) => {
  const url = `${process.env.REACT_APP_REFERRAL_BACKEND}/public/reauth/new`;
  return await genericSubmitForm(newReauth, url);
};

export const genericSubmitForm = async (
  data: NewReauth | NewReferral,
  url: string
): Promise<GenericFormSubmitResponse> => {
  const options = {
    method: "POST",
    body: JSON.stringify(data),
    headers: {
      "Content-Type": "application/json",
    },
  };
  return await fetch(url, options)
    .then((response) => {
      if (response.status === 409) {
        return;
      }
      if (!response.ok) {
        throw new Error(JSON.stringify(response.status));
      }
      return response.json();
    })
    .then((data) => {
      return {
        id: data?.referralId || data?.reauthId,
        errors: data.errors,
        status: data.status,
      };
    })
    .catch((error) => {
      console.error(`genericSubmitForm (${url}) error: error occured.`, error);
      throw new Error(error);
    });
};

export const uploadFile = (file: File & UploadFileType & Blob) => {
  const formData = new FormData();
  formData.append("file", file);

  return fetch(
    `${process.env.REACT_APP_REFERRAL_BACKEND}/public/referral/fileupload`,
    {
      method: "POST",
      body: formData,
    }
  )
    .then((response) => {
      if (!response.ok) {
        throw new Error(JSON.stringify(response.status));
      }
      return response.json();
    })
    .then((data) => data.uploadRequestId)
    .catch((error) => {
      console.error(error);
      return null;
    });
};
