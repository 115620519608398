import {
  Box,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from "@material-ui/core";
import styleVars from "../../_export.module.scss";
import common from "../../Common.module.scss";
import { FormType } from "../../types/FormSchema";
import { useContext, useEffect } from "react";
import { FormContext } from "../../contexts/FormContext";
import classes from "./FormSelection.module.scss";

const FormSelection = () => {
  const {
    formValid,
    metadata,
    setMetadata,
    setFormValid,
  } = useContext(FormContext);

  useEffect(() => {
    if (!metadata.formType && formValid) setFormValid(false);
    else if (metadata.formType && !formValid) setFormValid(true);
  }, [metadata.formType, setFormValid, formValid]);

  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography
          variant="h5"
          style={{ color: styleVars.colorReferralBlue }}
        >
          How can we help?
        </Typography>
      </Grid>
      <Grid container spacing={2}>
        <Box height="30px"></Box>
      </Grid>
      <Grid item xs={12}>
        <Typography
          variant="body1"
          style={{ fontSize: styleVars.fontSizeLarge }}
        >
          * = required information
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Box mb="20px" mt="40px" flex>
          <FormControl className={classes.formControl}>
            <FormLabel className={classes.formControlLabel}>
              <Typography variant="body1" className={common.fontLargeBold}>
                I am*:
              </Typography>
            </FormLabel>
            <RadioGroup
              name="formType"
              row
              value={metadata?.formType ?? ""}
              onChange={(event) => {
                const formType = event.target.value as FormType
                setMetadata({
                  ...metadata,
                  formType,
                });
              }}
            >
              <FormControlLabel
                value={FormType.Referral}
                control={<Radio />}
                label={
                  <Typography variant="body1">
                    Referring a <b>new patient</b>
                  </Typography>
                }
              />
              <FormControlLabel
                value={FormType.Reauth}
                control={<Radio />}
                label={
                  <Typography variant="body1">
                    Authorizing more visits for an <b>existing patient</b>
                  </Typography>
                }
              />
            </RadioGroup>
          </FormControl>
        </Box>
      </Grid>
    </Grid>
  );
};

export default FormSelection;
