import {
  ReferralFormSchema,
  BodyPart,
  UserFormSchema,
  UserType,
  UserNewOrExisting,
  FileUpload,
  Files,
} from "../../types/FormSchema";
import { NewReferral, SubmissionFile } from "../../types/SubmissionSchema";
import { MedicalDiagnosisType } from "../../types/RequiredInfoTypes";

import {
  momentStripTimestamp,
  getReferrerType,
  getReferrerId,
  getPhoneType,
  getRace,
  getMaritalStatus,
  getEthnicity,
  getSpecialty,
  getSurgicalStatus,
  getEmergencyContactRelationship,
} from "../formatters/submissionFormatters";
import {
  getCaseType,
  getFormattedPatientGender,
  getContactPreference,
} from "../formatters/dataFormatters";

export const serializeReferralData = ({
  userFirstName,
  userLastName,
  userEmail,
  userCompany,
  userPhonePreferred,
  userEmailPreferred,
  userPhoneNumber,
  userPhoneType,
  userPhoneExtension,
  userType,
  userAdjuster,
  userReferringPhysician,
  userCaseManager,
  patientFirstName,
  patientLastName,
  patientBirthday,
  patientPhoneNumber,
  patientSex,
  patientPrimaryLanguage,
  interpreterAuthorized,
  transportationAuthorized,
  claimNumber,
  stateOfJury,
  caseType,
  specialty,
  treatingPhysician,
  dateOfInjury,
  checkBoxForPhysicianInfo,
  physicianFirstName,
  physicianLastName,
  physicianEmail,
  physicianPhoneNumber,
  physicianPhoneType,
  physicianPhoneExtension,
  checkBoxPhysicianPhonePreferred,
  checkBoxPhysicianEmailPreferred,
  medicalDiagnosis,
  bodyPart,
  surgical,
  dateOfSurgery,
  programId,
  programRisk,
  employerSelected,
  jobTitle,
  checkBoxForEmployer,
  employerCompany,
  employerContactName,
  employerAddress,
  employerCity,
  employerState,
  employerZipCode,
  employerEmail,
  checkBoxEmployerEmailPreferred,
  employerPhoneNumber,
  employerPhoneType,
  employerPhoneExtension,
  checkBoxEmployerPhonePreferred,
  authScriptDate,
  authScriptEndDate,
  authAuthorizedVisits,
  authVisitTimes,
  authVisitWeeks,
  authAuthorizedBy,
  authAdditionalInjuryDate,
  authReturnToDoctorDate,
  authAuthorizationNumber,
  selectAdjuster,
  selectCaseManager,
  customCaseManagerInfo,
  customAdjusterInfo,
  patientPreferredName,
  patientMiddleName,
  patientSuffix,
  patientAddress,
  patientCity,
  patientState,
  patientZip,
  patientMaritalStatus,
  patientRace,
  patientEthnicity,
  patientEmergencyContactName,
  patientEmergencyContactRelationship,
  patientEmergencyContactPhoneNumber,
  patientPreferredClinic,
  patientPreferredClinician,
  patientOtherInfo,
  adjusterFirstName,
  adjusterLastName,
  adjusterEmail,
  adjusterCompany,
  adjusterPhonePreferred,
  adjusterEmailPreferred,
  adjusterPhoneNumber,
  adjusterPhoneType,
  adjusterPhoneExtension,
  caseManagerFirstName,
  caseManagerLastName,
  caseManagerEmail,
  caseManagerCompany,
  caseManagerPhonePreferred,
  caseManagerEmailPreferred,
  caseManagerPhoneNumber,
  caseManagerPhoneType,
  caseManagerPhoneExtension,
  userRoleInCase,
  userNewOrExisting,
  fileUploads,
}: ReferralFormSchema & UserFormSchema & Files) => {
  const serializedReferral: NewReferral = {
    addLInjuryDate: momentStripTimestamp(authAdditionalInjuryDate),
    adjuster: selectAdjuster?.id,
    adjusterCompany: customAdjusterInfo ? adjusterCompany : null,
    adjusterEmail: customAdjusterInfo ? adjusterEmail : null,
    adjusterFax: null, // Not captured
    adjusterFirstName: customAdjusterInfo ? adjusterFirstName : null,
    adjusterLastName: customAdjusterInfo ? adjusterLastName : null,
    adjusterPhone:
      customAdjusterInfo && adjusterPhoneNumber ? adjusterPhoneNumber : null,
    adjusterPreferredContact: customAdjusterInfo
      ? getContactPreference(adjusterPhonePreferred, adjusterEmailPreferred)
      : null,
    adjusterPhoneType:
      customAdjusterInfo && adjusterPhoneType
        ? getPhoneType(adjusterPhoneType)
        : null,
    adjusterPhoneExtension:
      customAdjusterInfo && adjusterPhoneExtension
        ? adjusterPhoneExtension
        : null,
    adjusterSameAsReferrer: userType === UserType.Adjuster,
    authorizedNoOfVisits: authAuthorizedVisits && authAuthorizedVisits.length ? Number(authAuthorizedVisits) : undefined,
    authorizationNumber: authAuthorizationNumber,
    bodyParts: bodyPart?.length
      ? bodyPart.reduce(
          (
            accumulator: string,
            currentBodyPart: BodyPart,
            index: number,
            arr: BodyPart[]
          ) => {
            const delimiter = index < arr.length - 1 ? ";" : "";
            return accumulator + currentBodyPart.BodyPartName + delimiter; // other property is bodyPartName
          },
          ""
        )
      : null,
    caseManager: selectCaseManager?.id,
    caseManagerPhoneType: customCaseManagerInfo
      ? getPhoneType(caseManagerPhoneType)
      : null,
    caseManagerPreferredContact: customCaseManagerInfo
      ? getContactPreference(
          caseManagerPhonePreferred,
          caseManagerEmailPreferred
        )
      : null,
    caseType: getCaseType(caseType),
    claimDateOfInjury: momentStripTimestamp(dateOfInjury),
    claimNumber: claimNumber,
    claimStateOfJurisdiction: stateOfJury || null,
    client: null, // Not captured
    dateOfReferral: null,
    duration: authVisitWeeks && authVisitWeeks.length ? Number(authVisitWeeks) : undefined,
    emergencyContactName: patientEmergencyContactName,
    emergencyContactPhoneNumber: patientEmergencyContactPhoneNumber,
    employeeWorkSchedule: null, // Not captured
    employeeWorkStatusTimeOfReferral: null, // Not captured,
    employerLocation: employerSelected?.id || null,
    employerLocationCity: checkBoxForEmployer ? employerCity : null,
    employerLocationCompany: checkBoxForEmployer ? employerCompany : null,
    employerLocationContactName: checkBoxForEmployer
      ? employerContactName
      : null,
    employerLocationEmail: checkBoxForEmployer ? employerEmail : null,
    employerLocationPhoneNumber:
      checkBoxForEmployer && employerPhoneNumber ? employerPhoneNumber : null,
    employerLocationPhoneType: checkBoxForEmployer
      ? getPhoneType(employerPhoneType)
      : null,
    employerLocationPreferredContact: checkBoxForEmployer
      ? getContactPreference(
          checkBoxEmployerPhonePreferred,
          checkBoxEmployerEmailPreferred
        )
      : null,
    employerLocationPhoneExtension: checkBoxForEmployer
      ? employerPhoneExtension
      : null,
    employerLocationState: checkBoxForEmployer ? employerState || null : null,
    employerLocationStreetAddress: checkBoxForEmployer
      ? employerAddress || null
      : null,
    employerLocationZipPostalCode: checkBoxForEmployer ? employerZipCode : null,
    ethnicity: getEthnicity(patientEthnicity),
    fileUploads: fileUploads.length
      ? (fileUploads.map((f: FileUpload) => ({
          upload_id: f.fileId,
          filename: f.name,
          document_category: f.category,
        })) as SubmissionFile[])
      : [],
    frequency: authVisitTimes && authVisitTimes.length ? Number(authVisitTimes) : undefined,
    healthcareDiagnosis: medicalDiagnosis?.length
      ? medicalDiagnosis?.reduce(
          (
            accumulator: string,
            currentDiagnosis: MedicalDiagnosisType,
            index: number,
            arr: MedicalDiagnosisType[]
          ) => {
            const delimiter = index < arr.length - 1 ? ";" : "";
            return accumulator + currentDiagnosis.diagnosisCode + delimiter;
          },
          ""
        )
      : null,
    maritalStatus: getMaritalStatus(patientMaritalStatus),
    medicalDiagnosis: medicalDiagnosis,
    nurseCaseManagerCompany: customCaseManagerInfo ? caseManagerCompany : null,
    nurseCaseManagerEmail: customCaseManagerInfo ? caseManagerEmail : null,
    nurseCaseManagerFax: null, // Not captured,
    nurseCaseManagerFirstName: customCaseManagerInfo
      ? caseManagerFirstName
      : null,
    nurseCaseManagerLastName: customCaseManagerInfo
      ? caseManagerLastName
      : null,
    nurseCaseManagerPhone:
      customCaseManagerInfo && caseManagerPhoneNumber
        ? caseManagerPhoneNumber
        : null,
    nurseCaseManagerPhoneExt:
      customCaseManagerInfo && caseManagerPhoneExtension
        ? caseManagerPhoneExtension
        : null,
    nurseCaseManagerSameAsReferrer: userType === UserType.CaseManager,
    otherSpecialInstructions: patientOtherInfo,
    patientAddressLine: patientAddress,
    patientAddressLine2: null, // Not captured,
    patientCity: patientCity,
    patientDob: momentStripTimestamp(patientBirthday),
    patientFirstName: patientFirstName,
    patientGender: getFormattedPatientGender(patientSex),
    patientJobTitle: jobTitle,
    patientLastName: patientLastName,
    patientMiddleName: patientMiddleName,
    patientPhone: patientPhoneNumber,
    // patientPreferredLanguage: patientPreferredLanguage, // NEED TO CLARIFY THIS
    patientPreferredLanguage: patientPrimaryLanguage
      ? patientPrimaryLanguage
      : null, // label and value were made the same for this property
    patientPreferredName: patientPreferredName,
    patientState: patientState ? patientState : null,
    patientSuffix: patientSuffix || null,
    patientZip: patientZip,
    physician: checkBoxForPhysicianInfo ? null : treatingPhysician?.id || null,
    program: programId || null,
    programRisk: programRisk || null,
    race: getRace(patientRace),
    referralAuthorizedBy: authAuthorizedBy || null,
    referralMethod: "Web Form",
    referrer: getReferrerId(
      userType,
      userAdjuster?.id,
      userCaseManager?.id,
      userReferringPhysician?.id
    ),
    referrerCompany:
      userNewOrExisting === UserNewOrExisting.New &&
      userType !== UserType.ReferringDoctor
        ? userCompany
        : null,
    referrerEmail:
      userNewOrExisting === UserNewOrExisting.New ? userEmail : null,
    referrerFax: null, // Not collected
    referrerFirstName:
      userNewOrExisting === UserNewOrExisting.New ? userFirstName : null,
    referrerJobTitle:
      userNewOrExisting === UserNewOrExisting.New && userType === UserType.Other
        ? userRoleInCase || null
        : null,
    referrerLastName:
      userNewOrExisting === UserNewOrExisting.New ? userLastName : null,
    referrerPhone:
      userNewOrExisting === UserNewOrExisting.New ? userPhoneNumber : null,
    referrerPhoneType:
      userNewOrExisting === UserNewOrExisting.New
        ? getPhoneType(userPhoneType)
        : null,
    referrerType: getReferrerType(userType) || null,
    referringPhoneExtension:
      userNewOrExisting === UserNewOrExisting.New ? userPhoneExtension : null,
    referringPhysicianFirstName: checkBoxForPhysicianInfo
      ? physicianFirstName
      : null,
    referringPhysicianLastName: checkBoxForPhysicianInfo
      ? physicianLastName
      : null,
    referringPhysicianPhone: checkBoxForPhysicianInfo
      ? physicianPhoneNumber || null
      : null,
    referringPhysicianPhoneExt: checkBoxForPhysicianInfo
      ? physicianPhoneExtension
      : null,
    referrerPreferredContact:
      userNewOrExisting === UserNewOrExisting.New
        ? getContactPreference(userPhonePreferred, userEmailPreferred)
        : null,
    referringPhysicianEmail: checkBoxForPhysicianInfo ? physicianEmail : null,
    referringPhysicianPhoneType: checkBoxForPhysicianInfo
      ? getPhoneType(physicianPhoneType)
      : null,
    referringPhysicianPreferredContact: checkBoxForPhysicianInfo
      ? getContactPreference(
          checkBoxPhysicianPhonePreferred,
          checkBoxPhysicianEmailPreferred
        )
      : null,
    relationshipToPatient: getEmergencyContactRelationship(
      patientEmergencyContactRelationship
    ),
    returnToDoctorDate: momentStripTimestamp(authReturnToDoctorDate),
    requestedTherapyProviderClinician: patientPreferredClinician,
    requestedTherapyProviderLocation: patientPreferredClinic,
    scriptDate: momentStripTimestamp(authScriptDate),
    authorizationEndDate: momentStripTimestamp(authScriptEndDate),
    specialty: getSpecialty(specialty),
    surgicalStatus: getSurgicalStatus(surgical),
    surgeryDate: momentStripTimestamp(dateOfSurgery),
    translationRequired: interpreterAuthorized,
    transportation: transportationAuthorized,
  };

  Object.keys(serializedReferral).forEach((key: string) => {
    const objKey = key as keyof NewReferral;
    let tempValue = serializedReferral[objKey];

    if (typeof tempValue === "string" || tempValue instanceof String) {
      tempValue = tempValue.trim();

      if (tempValue === "") {
        tempValue = null;
      }

      if (key.toLowerCase()) {
        assign(serializedReferral, objKey, tempValue);
      }
    }
  });
  return serializedReferral;
};

const assign = <T extends object, K extends keyof T>(
  obj: T,
  key: K,
  val: T[K]
) => {
  obj[key] = val;
};
