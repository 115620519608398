import { Checkbox, makeStyles } from "@material-ui/core/";
import { useField } from "formik";
import styleVars from "../../../_export.module.scss";

const useStyles = makeStyles(() => ({
  radioButton: {
    "& .MuiRadio-root": {
      padding: "3px",
    },
  },
  unselected: {
    color: "#9E9E9E",
    "& .MuiFormControlLabel-root": {
      color: styleVars.colorSecondaryText,
      fontSize: styleVars.fontSizeSmall,
    },
  },
  selected: {
    color: `${styleVars.colorGreen} !important`,
  },
}));

const MagicCheckbox = ({ otherAction, setFieldValue, ...props }: any) => {
  const classes = useStyles({ row: props.row });
  const [field] = useField(props.name);

  const handleChange = (e: { target: HTMLInputElement }) => {
    if (otherAction) {
      otherAction();
    }
    setFieldValue(field.name, e.target.checked);
  };

  return (
    <Checkbox
      {...field}
      checked={field.value}
      classes={{ checked: classes.selected }}
      className={classes.unselected}
      onChange={handleChange}
    />
  );
};

export default MagicCheckbox;
