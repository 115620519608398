import ReactDOM from "react-dom";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import FormRouting from "./components/pages/FormRouting";
import { CustomLayout } from "./components/CustomLayout";
import { createTheme, ThemeProvider } from "@material-ui/core";
import { StyledEngineProvider } from "@mui/material";
import { FormProvider } from "./contexts/FormContext";
import styleVars from "./_export.module.scss";
import FormSelection from "./components/forms/FormSelection";
import FormSelect from "./components/pages/QAFormSelect";
import UserForm from "./components/forms/UserForm";
import FormLayout from "./components/forms/layout/FormLayout";
const NotFound = () => <h1>Not Found</h1>;

const MOUNT_NODE = document.getElementById("root") as HTMLElement;

const theme = createTheme({
  palette: {
    primary: {
      main: "#0B78D0",
    },
    secondary: {
      main: "#6FA136",
    },
  },
  overrides: {
    MuiFormLabel: {
      root: {
        color: styleVars.colorPrimaryText,
        "&$focused": {
          color: styleVars.colorPrimaryText,
        },
      },
    },
    MuiFormControlLabel: {
      root: {
        '& [class*="MuiTypography-root"]': {
          fontSize: "14px",
        },
      },
    },
  },
});

const router = createBrowserRouter([
  {
    path: "/",
    element: <CustomLayout />,
    children: [
      {
        index: true,
        element: (
          <FormProvider>
            <FormLayout>
              <UserForm />
            </FormLayout>
          </FormProvider>
        ),
      },
      {
        index: true,
        path: "/form/select",
        element: (
          <FormProvider>
            <FormLayout>
              <FormSelection />
            </FormLayout>
          </FormProvider>
        ),
      },
      ...["/referral/*", "/reauth/*"].map((path) => {
        return {
          index: true,
          path,
          element: (
            <FormProvider>
              <FormLayout>
                <FormRouting />
              </FormLayout>
            </FormProvider>
          ),
        };
      }),
      {
        index: true,
        path: "/qa",
        element:
          process.env.REACT_APP_QA_ENABLED === "true" ? (
            <FormProvider>
              <FormSelect />
            </FormProvider>
          ) : (
            <NotFound />
          ),
      },
      { path: "*", element: <NotFound /> },
    ],
  },
]);

ReactDOM.render(
  <StyledEngineProvider injectFirst>
    <ThemeProvider theme={theme}>
      <RouterProvider router={router} />
    </ThemeProvider>
  </StyledEngineProvider>,
  MOUNT_NODE
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
