import { useField } from 'formik';
import {
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  makeStyles,
  Typography,
} from '@material-ui/core';

import styleVars from '../../../_export.module.scss';

const useStyles = makeStyles(() => ({
  radioButton: {
    '& .MuiRadio-root': {
      padding: '3px',
    },
    '& .MuiFormControlLabel-label': {
      fontSize: ({ row }: { row: string }) =>
        row ? styleVars.fontSizeXsmall : 'inherit',
    },
  },
  unselected: {
    color: '#959595',
  },
  selected: {
    color: '#71A036 !important',
  },
}));

const RadioGroupField = ({
  label,
  name,
  options,
  otherAction,
  ...props
}: any) => {
  const classes = useStyles({ row: props.row });
  const [field, meta] = useField(name);

  const handleChange = (event: any) => {
    field.onChange(event);
    if (otherAction) {
      otherAction();
    }
  };

  return (
    <FormControl component="fieldset" error={meta.touched && !!meta.error}>
      <FormLabel component="legend">{label}</FormLabel>
      <RadioGroup {...field} {...props} onChange={handleChange}>
        {options.map((option: any, index: any) => (
          <FormControlLabel
            key={index}
            value={option.value}
            control={
              <Radio
                classes={{ checked: classes.selected }}
                className={classes.unselected}
              />
            }
            label={
              <Typography
                variant="body1"
                style={{ fontSize: '14px' }}
                component="span"
              >
                {option.label}
              </Typography>
            }
          />
        ))}
      </RadioGroup>
      {meta.touched && meta.error ? (
        <div style={{ color: 'red', marginTop: '.5rem' }}>{meta.error}</div>
      ) : null}
    </FormControl>
  );
};

export default RadioGroupField;
