import moment from 'moment';
import * as Yup from 'yup';
import { ERROR_REQUIRED_TXT } from '../constants/textConstants';

const validationSchema = Yup.object().shape({
  authScriptDate: Yup.string().nullable(),
  authScriptEndDate: Yup.string().nullable()
    .test(
      'is-valid-auth-script-end-date',
      'Must be after the script date',
      function(value) {
        const { authScriptDate } = this.parent;
        if (!authScriptDate || !value) return true; // Skip validation if either date is missing
        const startDate = moment(authScriptDate);
        const endDate = moment(value);
        return endDate.isAfter(startDate);
      }
    ),
  authAuthorizedVisits: Yup.number()
    .transform((curr, orig) => (!!orig ? curr : null))
    .nullable()
    .typeError('You must enter a numeric value')
    .min(0, 'The value must be non-negative')
    .max(99, 'The value must be 99 or less'),
  authVisitTimes: Yup.number()
    .transform((curr, orig) => (!!orig ? curr : null))
    .nullable()
    .test(
      'authVisit',
      ERROR_REQUIRED_TXT,
      function (value: number | null | undefined) {
        const { authVisitWeeks } = this.parent;
        return (
          (value === undefined && authVisitWeeks === undefined) ||
          (value !== undefined && authVisitWeeks !== undefined)
        );
      }
    )
    .typeError('You must enter a numeric value')
    .min(0, 'The value must be non-negative')
    .max(7, 'The value must be 7 or less'),

  authVisitWeeks: Yup.number()
    .transform((curr, orig) => (!!orig ? curr : null))
    .nullable()
    .test(
      'authVisit',
      ERROR_REQUIRED_TXT,
      function (value) {
        const { authVisitTimes } = this.parent;
        return (
          (value === undefined && authVisitTimes === undefined) ||
          (value !== undefined && authVisitTimes !== undefined)
        );
      }
    )
    .typeError('You must enter a numeric value')
    .min(0, 'The value must be non-negative')
    .max(99, 'The value must be 99 or less'),

  authAuthorizedBy: Yup.string().nullable(),
  authAuthorizationNumber: Yup.string().nullable(),
  authAdditionalInjuryDate: Yup.string().nullable(),
  authReturnToDoctorDate: Yup.string().nullable(),
});

export default validationSchema;
