import React, { useState, useEffect, useCallback } from "react";
import MagicSearchInput from "../generic/MagicSearchInput";
import { Autocomplete } from "formik-material-ui-lab";
import { Field, FormikTouched, FormikErrors } from "formik";
import { ReferralFormSchema, SelectPhysician } from "../../../types/FormSchema";
import { ic_search as icSearch } from "react-icons-kit/md";
import Icon from "react-icons-kit";
import {
  toSearchString,
  filterOptions,
} from "../../../helpers/formatters/autocomplete";
import {
  fetchPhysicianMatches,
  PhysicianDTO,
} from "../../../api/autocomplete";

const getStatusLabel = (status: string) => {
  if (status === "noResults") {
    return "No results found";
  } else if (status === "searching") {
    return "Searching...";
  } else {
    return "Search";
  }
};

export const ReferringPhysicianSelect = (props: {
  name: string;
  label: string;
  required: boolean;
  disabled: boolean;
  touched: FormikTouched<{ [field: string]: any }>;
  errors: { [field: string]: any };
  setTouched: (
    fields: { [field: string]: any },
    shouldValidate?: boolean
  ) => Promise<void | FormikErrors<ReferralFormSchema>>;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean
  ) => Promise<void | FormikErrors<ReferralFormSchema>>;
}) => {
  const [physicians, setPhysicians] = useState<SelectPhysician[]>([]);
  const [filteredPhysicians, setFilteredPhysicians] = useState<SelectPhysician[]>([]);
  const [searchValue, setSearchValue] = useState("");
  const [inputValue, setInputValue] = useState("");
  const [status, setStatus] = useState("idle");

  const fetchPhysicians = useCallback(
    (signal: AbortSignal) => {
      if (searchValue?.length === 3) {
        setStatus("searching");
        fetchPhysicianMatches(searchValue, signal).then((response) => {
          setStatus(response.data.length > 0 ? "idle" : "noResults");
          if (response.data.length > 0) {
            const physicianData = response.data as PhysicianDTO[];
            setStatus("idle");
            setPhysicians(
              physicianData.map((d: PhysicianDTO) => ({
                label: `${d.firstName || ""} ${d.lastName || ""} ${
                  d.company ? "-" : ""
                } ${d.company || ""}`,
                ...d,
              }))
            );
          }
        }).catch((error) => console.log('Error:', error));;
      }
    },
    [searchValue]
  );

  useEffect(() => {
    const abortController = new AbortController();
    if (searchValue.length < 3) setFilteredPhysicians([]);
    else fetchPhysicians(abortController.signal);
    return () => {
      abortController.abort();
    };
  }, [fetchPhysicians, searchValue.length]);

  // filter the physicians options based on the input
  useEffect(() => {
    const clean = toSearchString(inputValue);
    if (clean.length >= 3) {
      setStatus("searching");
      const filtered = filterOptions<SelectPhysician>(physicians, clean);
      if (filtered.length === 0) {
        setStatus("noResults");
      } else {
        setStatus("idle");
        setFilteredPhysicians(filtered);
      }
    }
  }, [physicians, inputValue]);

  return (
    <Field
      name={props.name}
      component={Autocomplete}
      id="referring-doctor-search"
      disabled={props.disabled}
      options={filteredPhysicians}
      filterOptions={(options: [], state: any) => options}
      getOptionLabel={(option: { label: string }) => option.label}
      onChange={(e: MouseEvent, value: SelectPhysician[]) => {
        props.setFieldValue(props.name, value);
      }}
      onBlur={() => {
        const touchedFields = { ...props.touched };
        touchedFields[props.name] = true;
        props.setTouched(touchedFields);
      }}
      noOptionsText={getStatusLabel(status)}
      renderOption={(option: { label: string }) => (
        <div
          style={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {option.label}
        </div>
      )}
      renderInput={(params: any) => {
        const errorProps =
          props.errors?.[props.name] && props.touched?.[props.name]
            ? {
                error: true,
                helperText: "Required Field",
              }
            : {};
        return (
          <MagicSearchInput
            {...params}
            label={props.label}
            defaultValue=""
            required={props.required}
            variant="outlined"
            helperText="Tip: Start typing first or last name to search"
            onChange={(e: { target: HTMLInputElement }) => {
              const newInputValue = e.target.value ?? "";
              const newSearchValue = toSearchString(newInputValue).substring(
                0,
                3
              );
              if (searchValue !== newSearchValue) {
                setSearchValue(newSearchValue);
              }
              setInputValue(newInputValue);
            }}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <Icon
                  icon={icSearch}
                  size={24}
                  style={{
                    color: "gray",
                    position: "relative",
                    left: !!props.disabled ? "26px" : "52px",
                  }}
                />
              ),
            }}
            {...errorProps}
          />
        );
      }}
    />
  );
};
