import { useContext, useEffect, useRef, useState } from "react";
import ethnicityChoices from "../../constants/ethnicityChoices";
import patientNameSuffixChoices from "../../constants/suffixChoices";
import raceChoices from "../../constants/raceChoices";
import maritalStatusChoices from "../../constants/maritalChoices";
import relationshipChoices from "../../constants/relationshipChoices";
import states from "../../constants/states";
import {
  Box,
  Grid,
  FormControlLabel,
  Typography,
  Card,
  CardContent,
} from "@material-ui/core";
import Icon from "react-icons-kit";
import { ic_offline_bolt as icOfflineBolt } from "react-icons-kit/md";

import { AdjusterSelect } from "../inputs/autocomplete/AdjusterSelect";
import { CaseManagerSelect } from "../inputs/autocomplete/CaseManager";
import { Field, Form, ErrorMessage, useFormik, FormikProvider } from "formik";
import { FormContext } from "../../contexts/FormContext";
import { MagicTextField } from "../inputs/generic/MagicTextField";
import { MagicPhoneField } from "../inputs/generic/MagicPhoneField";
import { MagicSelect } from "../inputs/generic/MagicSelect";
import MagicCheckbox from "../inputs/generic/MagicCheckbox";
import additionalInfoFormValidation from "../../yup/AdditionalInfoFormValidation";
import styleVars from "../../_export.module.scss";
import {
  AdditionalInfoSchema,
  FormType,
  initializedAdditionalInfoForm,
  ReferralFormSchema,
} from "../../types/FormSchema";
import { checkFastTrack } from "../../helpers/validators/checkFastTrack";

import common from "../../Common.module.scss";

const AdditionalInfoForm = () => {
  const {
    formStep,
    formValid,
    formData,
    metadata,
    userData,
    clearFormData,
    setFormData,
    setFormStep,
    setFiles,
    setFormValid,
    setClearFormData,
  } = useContext(FormContext);

  const [isFastTrackActive, setIsFastTrackActive] = useState(false);
  const formik = useFormik({
    initialValues: clearFormData ? initializedAdditionalInfoForm : {
      ...initializedAdditionalInfoForm,
      ...formData,
      customAdjusterInfo: !!(formData as ReferralFormSchema)?.customAdjusterInfo,
      customCaseManagerInfo: !!(formData as ReferralFormSchema)?.customCaseManagerInfo,
    } as AdditionalInfoSchema,

    validationSchema: additionalInfoFormValidation,
    onSubmit: (values) => {},
    validateOnChange: true,
    validateOnBlur: true,
    //Prevents timing issues with form context being updated and the new component initializing state from saved formData.  Not ideal but will work for now.
    enableReinitialize: true,
  });
  const { values, isValid, setFieldValue, setTouched, validateForm } = formik;

  const refValues = useRef(values);

  useEffect(() => {
    if(clearFormData){
      setFormData({})
      setFiles([])
      setClearFormData(false)
    }
  }, [clearFormData, setFormData, setClearFormData, setFiles])

  useEffect(() => {
    refValues.current = values;
  }, [values]);

  useEffect(() => {
    window.scrollTo(0, 0);
    validateForm();
    const valid =
      metadata.formType === FormType.Referral
        ? checkFastTrack(formData as ReferralFormSchema)
        : true;
    setIsFastTrackActive(!!valid);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    //Local state is the source of truth, context just exposes current form validation state for use in navigation.
    if (isValid !== formValid) {
      setFormValid(isValid);
    }
  }, [isValid, formValid, setFormValid]);

  useEffect(() => {
    if (formStep < 3 || (!isValid && formStep !== 3)) {
      setFormStep(3);
    }
  }, [formStep, isValid, metadata.formType, setFormStep]);

  useEffect(() => {
    // When the component unmounts, update the context values with the current form values
    return () => {
      setFormData((prevValues) => {
        return {
          ...prevValues,
          ...refValues.current,
        };
      });
    };
  }, [setFormData]);

  return (
    <>
      <FormikProvider value={formik}>
        <Form noValidate>
          <Grid item xs={12}>
            <Box style={{ width: "925px" }}></Box>
          </Grid>
          {isFastTrackActive && (
            <>
              <Grid item xs={12}>
                <Typography
                  variant="body1"
                  style={{
                    color: styleVars.colorGreen,
                    fontSize: styleVars.fontSize3XL,
                    fontWeight: 500,
                  }}
                >
                  <Icon size={28} icon={icOfflineBolt} /> You're on the fast
                  track!
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography
                  variant="body1"
                  style={{
                    color: styleVars.colorGreen,
                    fontSize: styleVars.fontSizeXL,
                    fontWeight: 500,
                  }}
                >
                  You've given us all we need to schedule this patient at a
                  clinic
                </Typography>
              </Grid>
              <Grid container spacing={2}>
                <Box height="60px"></Box>
              </Grid>
            </>
          )}

          <Grid item xs={12}>
            <Card
              style={{
                borderRadius: "0px",
                background: styleVars.colorCardBlue,
              }}
            >
              <CardContent>
                <Grid container>
                  <Grid item xs={12} style={{ marginBottom: "8px" }}>
                    <Typography className={common.fontLargeNormal}>
                      ADJUSTER
                    </Typography>
                  </Grid>
                  {userData?.userNewOrExisting === "existing" && (
                    <>
                      <Grid item xs={6}>
                        {!!values.customAdjusterInfo ? (
                          <AdjusterSelect
                            name="selectAdjusterPlaceholder"
                            disabled
                            {...formik}
                          />
                        ) : (
                          <AdjusterSelect
                            name="selectAdjuster"
                            disabled={!!values.customAdjusterInfo}
                            {...formik}
                          />
                        )}
                      </Grid>
                    </>
                  )}

                  <Grid item xs={7}>
                    <FormControlLabel
                      control={
                        <MagicCheckbox
                          setFieldValue={setFieldValue}
                          otherAction={() => {
                            setTouched({});
                            setFieldValue("selectAdjuster", undefined);
                          }}
                          name="customAdjusterInfo"
                        />
                      }
                      labelPlacement="end"
                      label={
                        <Typography
                          variant="body1"
                          style={{
                            fontSize: styleVars.fontSizeNormal,
                            color: styleVars.colorSecondaryText,
                            fontWeight: 600,
                          }}
                        >
                          {userData?.userNewOrExisting === "existing"
                            ? `Not in list - I'll enter the information`
                            : "I want to enter this information"}
                        </Typography>
                      }
                    />
                  </Grid>

                  {!!values?.customAdjusterInfo && (
                    <>
                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <Field
                            required
                            component={MagicTextField}
                            name="adjusterFirstName"
                            type="text"
                            label="First name"
                            helperText={
                              <ErrorMessage name="adjusterFirstName" />
                            }
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <Field
                            required
                            component={MagicTextField}
                            name="adjusterLastName"
                            type="text"
                            label="Last name"
                            helperText={
                              <ErrorMessage name="adjusterLastName" />
                            }
                          />
                        </Grid>

                        <Grid item xs={6}>
                          <Field
                            required
                            component={MagicTextField}
                            name="adjusterCompany"
                            type="text"
                            label="Company"
                            helperText={<ErrorMessage name="adjusterCompany" />}
                          />
                        </Grid>
                        <Grid item xs={6}></Grid>
                        <Grid container style={{ paddingLeft: "9px" }}>
                          <Typography variant="body2" style={{ color: "gray" }}>
                            Contact method (choose one*)
                          </Typography>
                        </Grid>
                        <Grid item xs={8}>
                          <Field
                            component={MagicTextField}
                            name="adjusterEmail"
                            type="text"
                            label="Email"
                            required={!!values?.adjusterEmailPreferred}
                            helperText={<ErrorMessage name="userEmail" />}
                          />
                        </Grid>
                        <Grid item xs={4}>
                          <FormControlLabel
                            control={
                              <MagicCheckbox
                                name="adjusterEmailPreferred"
                                setFieldValue={setFieldValue}
                                otherAction={() =>
                                  values?.adjusterPhonePreferred &&
                                  setFieldValue("adjusterPhonePreferred", false)
                                }
                              />
                            }
                            label={
                              <Typography
                                variant="body1"
                                style={{
                                  fontSize: styleVars.fontSizeLarge,
                                  color: styleVars.colorSecondaryText,
                                }}
                              >
                                Preferred method of contact
                              </Typography>
                            }
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <Field
                            component={MagicPhoneField}
                            label="Phone number"
                            name="adjusterPhoneNumber"
                            value={values.adjusterPhoneNumber}
                            type="tel"
                            required={!!values?.adjusterPhonePreferred}
                            InputProps={{
                              onChange: (e: { target: HTMLInputElement }) =>
                                setFieldValue(
                                  "adjusterPhoneNumber",
                                  e.target.value,
                                  false
                                ),
                            }}
                          />
                        </Grid>

                        <Grid item xs={3}>
                          <Field
                            name="adjusterPhoneType"
                            label="Type"
                            id="adjuster-phone-type-select"
                            component={MagicSelect}
                            items={[
                              {
                                name: "\0",
                                value: "",
                              },
                              { name: "Mobile", value: "M" },
                              { name: "Work", value: "W" },
                            ]}
                            onChange={(e: { target: HTMLInputElement }) => {
                              setFieldValue(
                                "adjusterPhoneType",
                                e.target.value
                              );
                            }}
                          />
                        </Grid>
                        <Grid item xs={2}>
                          <Field
                            component={MagicTextField}
                            name="adjusterPhoneExtension"
                            type="text"
                            label="Extension"
                            helperText={
                              <ErrorMessage name="adjusterPhoneExtension" />
                            }
                          />
                        </Grid>
                        <Grid item xs={4}>
                          <FormControlLabel
                            control={
                              <MagicCheckbox
                                name="adjusterPhonePreferred"
                                setFieldValue={setFieldValue}
                                otherAction={() =>
                                  values?.adjusterEmailPreferred &&
                                  setFieldValue("adjusterEmailPreferred", false)
                                }
                              />
                            }
                            label={
                              <Typography
                                variant="body1"
                                style={{
                                  fontSize: styleVars.fontSizeLarge,
                                  color: styleVars.colorSecondaryText,
                                }}
                              >
                                Preferred method of contact
                              </Typography>
                            }
                          />
                        </Grid>
                      </Grid>
                    </>
                  )}
                </Grid>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12}>
            <Box height="15px"></Box>
          </Grid>
          <Grid item xs={12}>
            <Card
              style={{
                borderRadius: "0px",
                background: styleVars.colorCardBlue,
              }}
            >
              <CardContent>
                <Grid container>
                  <Grid item xs={12} style={{ marginBottom: "8px" }}>
                    <Typography className={common.fontLargeNormal}>
                      NURSE/CASE MANAGER
                    </Typography>
                  </Grid>
                  {userData?.userNewOrExisting === "existing" && (
                    <>
                      <Grid item xs={6}>
                        {!!values.customCaseManagerInfo ? (
                          <CaseManagerSelect
                            name="selectCaseManagerPlaceholder"
                            disabled
                            {...formik}
                          />
                        ) : (
                          <CaseManagerSelect
                            name="selectCaseManager"
                            disabled={!!values.customCaseManagerInfo}
                            {...formik}
                          />
                        )}
                      </Grid>
                    </>
                  )}
                  <Grid item xs={7}>
                    <FormControlLabel
                      control={
                        <MagicCheckbox
                          setFieldValue={setFieldValue}
                          otherAction={() => {
                            setTouched({});
                            setFieldValue("selectCaseManager", undefined);
                          }}
                          name="customCaseManagerInfo"
                        />
                      }
                      labelPlacement="end"
                      label={
                        <Typography
                          variant="body1"
                          style={{
                            fontSize: styleVars.fontSizeNormal,
                            color: styleVars.colorSecondaryText,
                            fontWeight: 600,
                          }}
                        >
                          {userData?.userNewOrExisting === "existing"
                            ? `Not in list - I'll enter the information`
                            : "I want to enter this information"}
                        </Typography>
                      }
                    />
                  </Grid>
                  {!!values?.customCaseManagerInfo && (
                    <>
                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <Field
                            required
                            component={MagicTextField}
                            name="caseManagerFirstName"
                            type="text"
                            label="First name"
                            helperText={
                              <ErrorMessage name="caseManagerFirstName" />
                            }
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <Field
                            required
                            component={MagicTextField}
                            name="caseManagerLastName"
                            type="text"
                            label="Last name"
                            helperText={
                              <ErrorMessage name="caseManagerLastName" />
                            }
                          />
                        </Grid>

                        <Grid item xs={6}>
                          <Field
                            required
                            component={MagicTextField}
                            name="caseManagerCompany"
                            type="text"
                            label="Company"
                            helperText={
                              <ErrorMessage name="caseManagerCompany" />
                            }
                          />
                        </Grid>
                        <Grid item xs={6}></Grid>
                        <Grid container style={{ paddingLeft: "9px" }}>
                          <Typography variant="body2" style={{ color: "gray" }}>
                            Contact method (choose one*)
                          </Typography>
                        </Grid>
                        <Grid item xs={8}>
                          <Field
                            component={MagicTextField}
                            name="caseManagerEmail"
                            type="text"
                            label="Email"
                            required={!!values?.caseManagerEmailPreferred}
                            helperText={
                              <ErrorMessage name="caseManagerEmail" />
                            }
                          />
                        </Grid>
                        <Grid item xs={4}>
                          <FormControlLabel
                            control={
                              <MagicCheckbox
                                name="caseManagerEmailPreferred"
                                setFieldValue={setFieldValue}
                                otherAction={() =>
                                  values?.caseManagerPhonePreferred &&
                                  setFieldValue(
                                    "caseManagerPhonePreferred",
                                    false
                                  )
                                }
                              />
                            }
                            label={
                              <Typography
                                variant="body1"
                                style={{
                                  fontSize: styleVars.fontSizeLarge,
                                  color: styleVars.colorSecondaryText,
                                }}
                              >
                                Preferred method of contact
                              </Typography>
                            }
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <Field
                            component={MagicPhoneField}
                            label="Phone number"
                            name="caseManagerPhoneNumber"
                            value={values.caseManagerPhoneNumber}
                            type="tel"
                            required={!!values?.caseManagerPhonePreferred}
                            InputProps={{
                              onChange: (e: { target: HTMLInputElement }) =>
                                setFieldValue(
                                  "caseManagerPhoneNumber",
                                  e.target.value,
                                  false
                                ),
                            }}
                          />
                        </Grid>

                        <Grid item xs={3}>
                          <Field
                            name="caseManagerPhoneType"
                            label="Type"
                            id="case-manager-phone-type-select"
                            component={MagicSelect}
                            items={[
                              {
                                name: "\0",
                                value: "",
                              },
                              { name: "Mobile", value: "M" },
                              { name: "Work", value: "W" },
                            ]}
                            onChange={(e: { target: HTMLInputElement }) => {
                              setFieldValue(
                                "caseManagerPhoneType",
                                e.target.value
                              );
                            }}
                          />
                        </Grid>
                        <Grid item xs={2}>
                          <Field
                            component={MagicTextField}
                            name="caseManagerPhoneExtension"
                            type="text"
                            label="Extension"
                            helperText={
                              <ErrorMessage name="caseManagerPhoneExtension" />
                            }
                          />
                        </Grid>
                        <Grid item xs={4}>
                          <FormControlLabel
                            control={
                              <MagicCheckbox
                                name="caseManagerPhonePreferred"
                                setFieldValue={setFieldValue}
                                otherAction={() =>
                                  values?.caseManagerEmailPreferred &&
                                  setFieldValue(
                                    "caseManagerEmailPreferred",
                                    false
                                  )
                                }
                              />
                            }
                            label={
                              <Typography
                                variant="body1"
                                style={{
                                  fontSize: styleVars.fontSizeLarge,
                                  color: styleVars.colorSecondaryText,
                                }}
                              >
                                Preferred method of contact
                              </Typography>
                            }
                          />
                        </Grid>
                      </Grid>
                    </>
                  )}
                </Grid>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12}>
            <Box height="15px"></Box>
          </Grid>
          <Grid item xs={12}>
            <Card
              style={{
                borderRadius: "0px",
                background: styleVars.colorCardBlue,
              }}
            >
              <CardContent>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography className={common.fontLargeNormal}>
                      PATIENT{" "}
                      <Typography
                        component="span"
                        style={{
                          fontWeight: 400,
                          color: styleVars.colorSecondaryText,
                        }}
                      >
                        (Additional contact & personal info)
                      </Typography>
                    </Typography>
                  </Grid>
                  <Grid item xs={5}>
                    <Field
                      component={MagicTextField}
                      name="patientPreferredName"
                      type="text"
                      label="Preferred name"
                      helperText={<ErrorMessage name="patientPreferredName" />}
                    />
                  </Grid>
                  <Grid item xs={5}>
                    <Field
                      component={MagicTextField}
                      name="patientMiddleName"
                      type="text"
                      label="Patient middle name"
                      helperText={<ErrorMessage name="patientMiddleName" />}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <Field
                      name="patientSuffix"
                      label="Suffix"
                      id="patient-suffix"
                      component={MagicSelect}
                      items={patientNameSuffixChoices}
                      onChange={(e: { target: HTMLInputElement }) => {
                        setFieldValue("patientSuffix", e.target.value);
                      }}
                    />
                  </Grid>
                  <Grid item xs={5}>
                    <Field
                      component={MagicTextField}
                      name="patientAddress"
                      type="text"
                      label="Patient address"
                      helperText={<ErrorMessage name="patientAddress" />}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <Field
                      component={MagicTextField}
                      name="patientCity"
                      type="text"
                      label="City"
                      helperText={<ErrorMessage name="patientCity" />}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <Field
                      name="patientState"
                      label="State"
                      id="patient-state"
                      component={MagicSelect}
                      // Need to grab the proper values from bnotes
                      items={states}
                      onChange={(e: { target: HTMLInputElement }) => {
                        setFieldValue("patientState", e.target.value);
                      }}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <Field
                      component={MagicTextField}
                      name="patientZip"
                      type="text"
                      label="Zip"
                      helperText={<ErrorMessage name="patientZip" />}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Field
                      name="patientMaritalStatus"
                      label="Marital status"
                      id="patient-marital-status"
                      component={MagicSelect}
                      items={maritalStatusChoices}
                      onChange={(e: { target: HTMLInputElement }) => {
                        setFieldValue("patientMaritalStatus", e.target.value);
                      }}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Field
                      name="patientRace"
                      label="Race"
                      id="patient-race"
                      component={MagicSelect}
                      items={raceChoices}
                      onChange={(e: { target: HTMLInputElement }) => {
                        setFieldValue("patientRace", e.target.value);
                      }}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Field
                      name="patientEthnicity"
                      label="Ethnicity"
                      id="patient-ethnicity"
                      component={MagicSelect}
                      items={ethnicityChoices}
                      onChange={(e: { target: HTMLInputElement }) => {
                        setFieldValue("patientEthnicity", e.target.value);
                      }}
                    />
                  </Grid>
                  <Grid item xs={5}>
                    <Field
                      component={MagicTextField}
                      name="patientEmergencyContactName"
                      type="text"
                      label="Emergency contact name"
                      helperText={
                        <ErrorMessage name="patientEmergencyContactName" />
                      }
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Field
                      name="patientEmergencyContactRelationship"
                      label="Relationship to patient"
                      id="patient-emergency-contact-relationship"
                      component={MagicSelect}
                      items={relationshipChoices}
                      onChange={(e: { target: HTMLInputElement }) => {
                        setFieldValue(
                          "patientEmergencyContactRelationship",
                          e.target.value
                        );
                      }}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <Field
                      component={MagicPhoneField}
                      label="Phone number"
                      name="patientEmergencyContactPhoneNumber"
                      value={values.patientEmergencyContactPhoneNumber}
                      type="tel"
                      InputProps={{
                        onChange: (e: { target: HTMLInputElement }) =>
                          setFieldValue(
                            "patientEmergencyContactPhoneNumber",
                            e.target?.value,
                            false
                          ),
                      }}
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12}>
            <Box height="15px"></Box>
          </Grid>
          <Grid item xs={12}>
            <Card
              style={{
                borderRadius: "0px",
                background: styleVars.colorCardBlue,
              }}
            >
              <CardContent>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography className={common.fontLargeNormal}>
                      OTHER
                    </Typography>
                  </Grid>

                  <Grid item xs={6}>
                    <Field
                      component={MagicTextField}
                      name="patientPreferredClinic"
                      type="text"
                      label="Preferred clinic"
                      helperText={
                        <ErrorMessage name="patientPreferredClinic" />
                      }
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Field
                      component={MagicTextField}
                      name="patientPreferredClinician"
                      type="text"
                      label="Preferred clinician"
                      helperText={
                        <ErrorMessage name="patientPreferredClinician" />
                      }
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      component={MagicTextField}
                      name="patientOtherInfo"
                      type="text"
                      label="Other info/notes"
                      showMaxCharacters
                      showCharacterCount
                      maxCharacters={1000}
                      multiline
                      helperText={<ErrorMessage name="patientOtherInfo" />}
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
          <Grid container spacing={2}>
            <Box height="24px"></Box>
          </Grid>
        </Form>
      </FormikProvider>
    </>
  );
};

export default AdditionalInfoForm;
