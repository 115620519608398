/* This is used to prefill Adjuster/NCM/Physician fields based on the user's info */

import {
  UserFormSchema,
  UserNewOrExisting,
  UserType,
} from "../types/FormSchema";

export function getPrefillFieldsFromUser(
  values: UserFormSchema,
  formStep: number
) {
  let prefillFields = {};

  //Fill adjuster additional info form
  if (values.userType === UserType.Adjuster && Number(formStep) < 4) {
    if (values.userNewOrExisting === UserNewOrExisting.New) {
      prefillFields = {
        customAdjusterInfo: true,
        adjusterFirstName: values?.userFirstName,
        adjusterLastName: values?.userLastName,
        adjusterEmail: values?.userEmail,
        adjusterCompany: values?.userCompany,
        adjusterPhonePreferred: !!values?.userPhonePreferred,
        adjusterEmailPreferred: !!values?.userEmailPreferred,
        adjusterPhoneNumber: values?.userPhoneNumber,
        adjusterPhoneType: values?.userPhoneType,
        adjusterPhoneExtension: values?.userPhoneExtension,
      };
    } else {
      values.userEmail = values?.userAdjuster?.email;
      prefillFields = {
        selectAdjuster: {
          ...values?.userAdjuster,
          label: `${values?.userAdjuster?.firstName || ""} ${
            values?.userAdjuster?.lastName || ""
          } ${values?.userAdjuster?.company ? "-" : ""} ${
            values?.userAdjuster?.company || ""
          }`,
          id: values?.userAdjuster?.id,
        },
      };
    }
  }
  //Fill case manager additional info form
  else if (values.userType === UserType.CaseManager && Number(formStep) < 4) {
    if (values.userNewOrExisting === UserNewOrExisting.New) {
      prefillFields = {
        customCaseManagerInfo: true,
        caseManagerFirstName: values?.userFirstName,
        caseManagerLastName: values?.userLastName,
        caseManagerEmail: values?.userEmail,
        caseManagerCompany: values?.userCompany,
        caseManagerPhonePreferred: !!values?.userPhonePreferred,
        caseManagerEmailPreferred: !!values?.userEmailPreferred,
        caseManagerPhoneNumber: values?.userPhoneNumber,
        caseMangerPhoneType: values?.userPhoneType,
        caseManagerPhoneExtension: values?.userPhoneExtension,
      };
    } else {
      values.userEmail = values?.userCaseManager?.email;
      prefillFields = {
        selectCaseManager: {
          ...values?.userCaseManager,
          id: values?.userCaseManager?.id,
          label: `${values?.userCaseManager?.firstName || ""} ${
            values?.userCaseManager?.lastName || ""
          } ${values?.userCaseManager?.company ? "-" : ""} ${
            values?.userCaseManager?.company || ""
          }`,
        },
      };
    }
  }
  //Fill referring doctor required info form
  else if (
    values.userType === UserType.ReferringDoctor &&
    Number(formStep) < 2
  ) {
    if (values.userNewOrExisting === UserNewOrExisting.New) {
      prefillFields = {
        checkBoxForPhysicianInfo: true,
        physicianFirstName: values?.userFirstName,
        physicianLastName: values?.userLastName,
        physicianEmail: values?.userEmail,
        checkBoxPhysicianPhonePreferred: !!values?.userPhonePreferred,
        checkBoxPhysicianEmailPreferred: !!values?.userEmailPreferred,
        physicianPhoneNumber: values?.userPhoneNumber,
        physicianPhoneType: values?.userPhoneType,
        physicianPhoneExtension: values?.userPhoneExtension,
      };
    } else {
      let contactText = "";
      if (
        values?.userReferringPhysician?.phone ||
        values?.userReferringPhysician?.fax
      ) {
        if (values?.userReferringPhysician?.phone) {
          contactText = "ph";
        } else if (values?.userReferringPhysician?.fax) contactText = "fax";
      } else {
        contactText = "";
      }

      prefillFields = {
        treatingPhysician: {
          ...values?.userReferringPhysician,
          label: `${values?.userReferringPhysician?.firstName || ""} ${
            values?.userReferringPhysician?.lastName || ""
          } ${
            values?.userReferringPhysician?.phone ? "-" : ""
          } ${contactText} ${
            values?.userReferringPhysician?.phone
              ? values?.userReferringPhysician?.phone || ""
              : values?.userReferringPhysician?.fax || ""
          }`,
          id: values?.userReferringPhysician?.id,
        },
      };
    }
  }
  //Fill "other" required info form
  else if (values.userType === UserType.Other && Number(formStep) < 4) {
    prefillFields = {
      // customAdjusterInfo: true,
      // customCaseManagerInfo: true,
    };
  }

  return prefillFields;
}
