const patientNameSuffixChoices = [
    {
      name: '\0',
      value: '',
    },
    {
      name: 'N/A',
      value: '',
    },
    {
      name: 'Sr.',
      value: 'Sr',
    },
    {
      name: 'Jr.',
      value: 'Jr',
    },
    {
      name: 'II',
      value: 'II',
    },
    {
      name: 'III',
      value: 'III',
    },
    {
      name: 'IV',
      value: 'IV',
    },
  ];

export default patientNameSuffixChoices