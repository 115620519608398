import React from 'react';
import classnames from 'classnames';
import {
  Checkbox,
  CheckboxProps,
  FormControlLabel,
  makeStyles,
  Typography,
} from '@material-ui/core';
import styleVars from '../../../_export.module.scss';

const useStyles = makeStyles((theme) => ({
  unchecked: {
    color: theme.palette.grey[500], //#9E9E9E
    '& svg': {
      height: '24px',
      width: '24px',
    },
  },
  checked: { color: styleVars.colorGreen },
  label: {
    color: styleVars.colorPrimaryText,
    fontSize: styleVars.fontSizeNormal,
  },
}));

/* ---------------------------------------------------------------------------------------------- */
/*  Standard styled checkbox (no label)                                                           */
/* ---------------------------------------------------------------------------------------------- */

export const StandardCheckbox = (props: CheckboxProps) => {
  const classes = useStyles();
  return (
    <Checkbox
      classes={{ root: classes.unchecked, checked: classes.checked }}
      color="default"
      {...props}
    />
  );
};

/* ---------------------------------------------------------------------------------------------- */
/*  CheckboxLabel (StandardCheckbox with a label on the right)                                    */
/* ---------------------------------------------------------------------------------------------- */

export type CheckboxLabelProps = {
  checked?: boolean;
  id?: string; // TODO should be required
  name?: string; // TODO can probably be removed
  onChange?: CheckboxProps['onChange'];
  labelText?: string | React.ReactElement; // TODO should be required
  extraRenderContent?: React.ReactNode;
  value?: string;
  labelTextClass?: string;
  controlLabelClassName?: string;
  disabled?: boolean;
};

export const CheckboxLabel = ({
  labelText,
  checked,
  onChange,
  name,
  labelTextClass,
  controlLabelClassName,
  ...restOfProps
}: CheckboxLabelProps) => {
  const classes = useStyles();
  return (
    <FormControlLabel
      label={
        <Typography className={classnames(classes.label, labelTextClass)}>
          {labelText}
        </Typography>
      }
      control={
        <StandardCheckbox
          checked={checked}
          onChange={onChange}
          name={name}
          {...restOfProps}
        />
      }
      className={controlLabelClassName}
    />
  );
};