import { Typography } from "@material-ui/core";

import { NOVALUE } from "../../constants/textConstants";
import {
  BodyPartsNewOrExisting,
  FormType,
  ReauthFormSchema,
  ReferralFormSchema,
  UserFormSchema,
  BodyPart,
  ReviewFiles,
  ReviewFile,
} from "../../types/FormSchema";
import {
  formatDate,
  getSurgicalFormattedNames,
  getSelectNameProperty,
  getUserName,
  getUserPhone,
  getUserEmail,
  getCaseType,
  getFormattedPatientGender,
  getTreatingPhysician,
  formatVisitText,
  formatAddress,
  getUserTypeLabel,
  getContactPreference,
} from "./dataFormatters";

import languageChoices from "../../constants/languageChoices";
import states from "../../constants/states";

import ethnicityChoices from "../../constants/ethnicityChoices";
import patientNameSuffixChoices from "../../constants/suffixChoices";
import raceChoices from "../../constants/raceChoices";
import { MedicalDiagnosisType } from "../../types/RequiredInfoTypes";

export type FormValue = {
  name: string;
  value: any;
};
export type FormattedValuesGrouping = {
  yourInfo: FormValue[];
  referralInfo: FormValue[];
  authorizations: FormValue[];
  reauthorization: FormValue[];
  stakeholders: FormValue[];
  patient: FormValue[];
  other: FormValue[];
};

export const parseAndGroupFormData = (
  formValues: (ReferralFormSchema | ReauthFormSchema) &
    UserFormSchema &
    ReviewFiles,
  formType?: FormType
) => {
  const modifiedData: FormattedValuesGrouping = {
    yourInfo: [],
    referralInfo: [],
    authorizations: [],
    reauthorization: [],
    stakeholders: [],
    patient: [],
    other: [],
  };

  modifiedData.yourInfo = buildUserInfoSection(formValues);

  if (formType === FormType.Reauth) {
    modifiedData.reauthorization = buildReauthorizationSection(
      formValues as ReauthFormSchema
    );
  } else {
    modifiedData.referralInfo = buildReferralInfoSection(
      formValues as ReferralFormSchema
    );
    modifiedData.authorizations = buildAuthorizationsSection(
      formValues as ReferralFormSchema
    );
    modifiedData.stakeholders = buildStakeholdersSection(
      formValues as ReferralFormSchema
    );
    modifiedData.patient = buildPatientSection(
      formValues as ReferralFormSchema
    );
    modifiedData.other = buildOtherSection(formValues as ReferralFormSchema);
  }
  return modifiedData;
};

const buildUserInfoSection = (
  formValues: (ReferralFormSchema | ReauthFormSchema) &
    UserFormSchema &
    ReviewFiles
) => {
  return [
    {
      name: "Role",
      value: getUserTypeLabel(formValues?.userType),
    },
    {
      name: "Name",
      value: getUserName(formValues),
    },
    {
      name: "Email",
      value: getUserEmail(formValues),
    },
    {
      name: "Preferred contact",
      value: getContactPreference(
        formValues?.userPhonePreferred,
        formValues?.userEmailPreferred
      ),
    },
    {
      name: "Phone",
      value: getUserPhone(formValues),
    },
    {
      name: "Files",
      value: formValues?.fileUploads.length
        ? formValues?.fileUploads.map((f: ReviewFile, i: number) => {
            // Changing this breaks compatibility with the current review page
            return (
              <Typography
                component="div"
                variant="body1"
                style={{ fontSize: "14px" }}
                key={`${f?.name}${i}`}
              >
                {f?.name}({f?.category}){" "}
                {Number(formValues?.fileUploads.length) > 0 &&
                  i !== Number(formValues?.fileUploads.length) - 1 &&
                  "|"}{" "}
              </Typography>
            );
          })
        : null,
    },
  ];
};

const buildReauthorizationSection = (formValues: ReauthFormSchema) => {
  return [
    {
      name: "Patient name",
      value:
        formValues?.patientFirstName && formValues?.patientLastName
          ? `${formValues?.patientFirstName} ${formValues?.patientLastName}`
          : NOVALUE,
    },
    {
      name: "Birthday",
      value: formatDate(formValues?.patientBirthday),
    },
    {
      name: "Claim number",
      value: formValues?.claimNumber,
    },
    {
      name: "Authorized visits",
      value: formatVisitText(
        formValues?.authAuthorizedVisits,
        formValues?.authVisitTimes,
        formValues?.authVisitWeeks
      ),
    },
    {
      name: "Authorized by",
      value: formValues?.authAuthorizedBy,
    },
    {
      name: "Script date",
      value: formatDate(formValues?.authScriptDate),
    },
    {
      name: "New body part(s)",
      value:
        formValues?.useNewOrExistingBodyParts === BodyPartsNewOrExisting.New
          ? "Yes"
          : "No",
    },
    {
      name: "Adding body parts",
      value: formValues?.reauthBodyParts?.length
        ? formValues?.reauthBodyParts
            ?.map((b: BodyPart) => b?.BodyPartName)
            .join(",")
        : null,
    },
  ];
};

const buildReferralInfoSection = (formValues: ReferralFormSchema) => {
  return [
    {
      name: "Patient name",
      value:
        formValues?.patientFirstName && formValues?.patientLastName
          ? `${formValues?.patientFirstName} ${formValues?.patientLastName}`
          : NOVALUE,
    },
    {
      name: "Birthday",
      value: formatDate(formValues?.patientBirthday),
    },
    {
      name: "Gender",
      value: getFormattedPatientGender(formValues?.patientSex),
    },
    {
      name: "Phone number",
      value: formValues?.patientPhoneNumber,
    },
    {
      name: "Primary language",
      value: formValues?.patientPrimaryLanguage ? getSelectNameProperty(
        formValues.patientPrimaryLanguage,
        languageChoices
      ) : "",
    },
    // Forcing this to be null if undefined
    {
      name: "Interpeter authorized",
      value: formValues?.interpreterAuthorized
        ? "Yes"
        : formValues?.interpreterAuthorized === undefined
        ? null
        : "No",
    },
    // Forcing this to be null if undefined
    {
      name: "Transportation authorized",
      value: formValues?.transportationAuthorized
        ? "Yes"
        : formValues?.transportationAuthorized === undefined
        ? null
        : "No",
    },
    {
      name: "Claim number",
      value: formValues?.claimNumber,
    },
    {
      name: "State of Jurisdiction",
      value: getSelectNameProperty(formValues?.stateOfJury, states),
    },
    {
      name: "Injury date",
      value: formatDate(formValues?.dateOfInjury),
    },
    {
      name: "Treating physician",
      value: getTreatingPhysician(formValues),
    },
    {
      name: "Case type",
      value: getCaseType(formValues?.caseType),
    },
    {
      name: "Specialty",
      value: formValues?.specialty,
    },
    {
      name: "Diagnosis/ICD-10",
      value: formValues?.medicalDiagnosis?.length
        ? formValues?.medicalDiagnosis.map(
            (m: MedicalDiagnosisType, i: number) => {
              return <div key={m.combined}>{m?.combined}</div>;
            }
          )
        : null,
    },
    {
      name: "Body part",
      value: formValues?.bodyPart?.length
        ? formValues?.bodyPart?.map((b: BodyPart, i: number) => {
            return <div key={b.BodyPartName}>{b?.BodyPartName}</div>;
          })
        : null,
    },
    {
      name: "Surgical",
      value: getSurgicalFormattedNames(formValues?.surgical),
    },
    {
      name: "Surgery Date",
      value: formatDate(formValues?.dateOfSurgery),
    },
    {
      name: "Employer",
      value: formValues?.employerSelected?.label || formValues?.employerCompany,
    },
    {
      name: "Job title",
      value: formValues?.jobTitle,
    },
  ];
};

const buildAuthorizationsSection = (formValues: ReferralFormSchema) => {
  return [
    {
      name: "Script date",
      value: formatDate(formValues?.authScriptDate),
    },
    {
      name: "Script expiration date",
      value: formatDate(formValues?.authScriptEndDate),
    },
    {
      name: "Authorized visits",
      value: formatVisitText(
        formValues?.authAuthorizedVisits,
        formValues?.authVisitTimes,
        formValues?.authVisitWeeks
      ),
    },
    {
      name: "Authorized by",
      value: formValues?.authAuthorizedBy,
    },
    {
      name: "Authorization number",
      value: formValues?.authAuthorizationNumber,
    },
    {
      name: "Add\u0027l injury date",
      value: formatDate(formValues?.authAdditionalInjuryDate),
    },
    {
      name: "Return to Dr. date",
      value: formatDate(formValues?.authReturnToDoctorDate),
    },
  ];
};

const buildStakeholdersSection = (formValues: ReferralFormSchema) => {
  return [
    {
      name: "Adjuster",
      value: !!formValues?.selectAdjuster
        ? formValues?.selectAdjuster?.label || NOVALUE
        : formValues?.adjusterFirstName &&
          formValues?.adjusterLastName &&
          formValues.adjusterCompany
        ? `${formValues?.adjusterFirstName || ""} ${
            formValues?.adjusterLastName || ""
          } - ${formValues?.adjusterCompany || ""}`
        : NOVALUE,
    },
    {
      name: "Nurse/Case manager",
      value: !!formValues?.selectCaseManager
        ? formValues?.selectCaseManager?.label || NOVALUE
        : formValues?.caseManagerFirstName &&
          formValues?.caseManagerLastName &&
          formValues.caseManagerCompany
        ? `${formValues?.caseManagerFirstName || ""} ${
            formValues?.caseManagerLastName || ""
          } - ${formValues?.caseManagerCompany || ""}`
        : NOVALUE,
    },
  ];
};

const buildPatientSection = (formValues: ReferralFormSchema) => {
  return [
    {
      name: "Preferred name",
      value: formValues?.patientPreferredName,
    },
    {
      name: "Middle name",
      value: formValues?.patientMiddleName,
    },
    {
      name: "Suffix",
      value: formValues?.patientSuffix
        ? getSelectNameProperty(
            formValues.patientSuffix,
            patientNameSuffixChoices
          )
        : "",
    },
    {
      name: "Address",
      value: formatAddress(
        formValues?.patientAddress,
        formValues?.patientCity,
        formValues?.patientState,
        formValues?.patientZip
      ),
    },
    {
      name: "Race",
      value: formValues?.patientRace
        ? getSelectNameProperty(formValues.patientRace ?? "", raceChoices)
        : "",
    },
    {
      name: "Ethnicity",
      value: formValues?.patientEthnicity
        ? getSelectNameProperty(formValues.patientEthnicity, ethnicityChoices)
        : "",
    },
    {
      name: "Emergency contact",
      value: formValues?.patientEmergencyContactName,
    },
    {
      name: "Relationship",
      value: formValues?.patientEmergencyContactRelationship,
    },
    {
      name: "Phone",
      // Does this need to be formatted?
      value: formValues?.patientEmergencyContactPhoneNumber,
    },
  ];
};

const buildOtherSection = (formValues: ReferralFormSchema) => {
  return [
    {
      name: "Preferred clinic",
      value: formValues?.patientPreferredClinic,
    },
    {
      name: "Preferred therapist",
      value: formValues?.patientPreferredClinician,
    },
    {
      name: "Other info/notes",
      // Does this need to be formatted?
      value: formValues?.patientOtherInfo,
    },
  ];
};
