import { useContext } from "react";
import {
  Box,
  Grid,
  Typography,
  Card,
  CardContent,
  Link,
} from "@material-ui/core";

import { useNavigate } from "react-router-dom";
import styleVars from "../../../_export.module.scss";
import { FormContext } from "../../../contexts/FormContext";
import common from "../../../Common.module.scss";
import FormFooter from "./FormFooter";
import { FormType } from "../../../types/FormSchema";

const FormLayout = (props: {
  children: JSX.Element;
  helpCardBorderColor?: string;
}) => {
  const navigate = useNavigate();
  const { formStep, metadata } = useContext(FormContext);
  return (
    <Grid container>
      <Grid item md={8} sm={12} style={{ marginRight: "30px" }}>
        {props.children}
        {formStep <= 4 ? (
          <>
            <Grid container spacing={2}>
              <Box height="30px"></Box>
            </Grid>
            <FormFooter />
          </>
        ) : null}
        <Box height="45px"></Box>
      </Grid>
      <Grid item sm={8} md={3}>
        <Card
          style={{
            borderTop: `5px solid ${
              props.helpCardBorderColor || styleVars.colorWarning
            }`,
            borderRadius: "0px",
            backgroundColor: styleVars.colorCardBackground,
          }}
        >
          <CardContent>
            <Grid container>
              <Grid container>
                <Box height="30px"></Box>
              </Grid>
              <Grid item xs={11}>
                <Typography
                  style={{
                    color: styleVars.colorThemeBlue,
                    fontSize: styleVars.fontSize4XL,
                    fontWeight: 400,
                  }}
                >
                  Need help?
                </Typography>
              </Grid>
              <Grid container spacing={2}>
                <Box height="45px"></Box>
              </Grid>

              {!metadata?.formType ||
              metadata.formType === FormType.Referral ? (
                <>
                  <Grid item xs={11}>
                    <Typography
                      variant="body1"
                      style={{
                        fontSize: styleVars.fontSizeNormal,
                        color: styleVars.bardGreen,
                      }}
                    >
                      New patient referrals
                    </Typography>
                  </Grid>
                  <Grid item xs={11}>
                    <Typography
                      variant="body1"
                      className={common.fontLargeSecondary}
                    >
                      Phone: (913) 236-3551
                    </Typography>
                  </Grid>
                  <Grid item xs={1}></Grid>
                  <Grid item xs={11}>
                    <Typography
                      variant="body1"
                      className={common.fontLargeSecondary}
                    >
                      Fax: (913) 236-3559
                    </Typography>
                  </Grid>
                  <Grid item xs={1}></Grid>
                  <Grid item xs={11}>
                    <Typography
                      variant="body1"
                      className={common.fontLargeSecondary}
                    >
                      Email:{" "}
                      <Link
                        onClick={() => {
                          return process.env.REACT_APP_QA_ENABLED === "true"
                            ? navigate("/qa")
                            : (window.location.href =
                                "mailto:example@example.com");
                        }}
                        style={{
                          cursor: "pointer",
                          color: styleVars.colorThemeBlue,
                        }}
                      >
                        referrals@bardavon.com
                      </Link>
                    </Typography>
                  </Grid>
                  <Grid container>
                    <Box height="30px"></Box>
                  </Grid>
                </>
              ) : null}

              {!metadata?.formType || metadata.formType === FormType.Reauth ? (
                <>
                  <Grid item xs={11}>
                    <Typography
                      variant="body1"
                      style={{
                        fontSize: styleVars.fontSizeNormal,
                        color: styleVars.bardGreen,
                      }}
                    >
                      Existing patient authorizations
                    </Typography>
                  </Grid>
                  <Grid item xs={11}>
                    <Typography
                      variant="body1"
                      className={common.fontLargeSecondary}
                    >
                      Phone: (913) 236-1020, option 2
                    </Typography>
                  </Grid>
                  <Grid item xs={1}></Grid>
                  <Grid item xs={11}>
                    <Typography
                      variant="body1"
                      className={common.fontLargeSecondary}
                    >
                      Fax: (913) 826-6508
                    </Typography>
                  </Grid>
                  <Grid item xs={1}></Grid>
                  <Grid item xs={11}>
                    <Typography
                      variant="body1"
                      className={common.fontLargeSecondary}
                    >
                      Email:{" "}
                      <Link
                        onClick={() => {
                          return process.env.REACT_APP_QA_ENABLED === "true"
                            ? navigate("/qa")
                            : (window.location.href =
                                "mailto:example@example.com");
                        }}
                        style={{
                          cursor: "pointer",
                          color: styleVars.colorThemeBlue,
                          fontSize: "10pt",
                        }}
                      >
                        reauthorizationrequest@bardavon.com
                      </Link>
                    </Typography>
                  </Grid>
                  <Grid container>
                    <Box height="30px"></Box>
                  </Grid>
                </>
              ) : null}
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default FormLayout;
