// import { ReferralFormSchema } from "../../types/FormSchema";
import UserFormValidation from "../../yup/UserFormValidation";
import RequiredInfoFormValidation from "../../yup/RequiredInfoFormValidation";
import AuthorizationFormValidation from "../../yup/AuthorizationFormValidation";
import AdditionalInfoFormValidation, {
  optionalPatientSection,
  otherSection,
  stakeholderSection,
} from "../../yup/AdditionalInfoFormValidation";
import ReauthorizationFormValidation from "../../yup/ReauthorizationFormValidation";
import {
  Files,
  FormType,
  ReauthFormSchema,
  ReferralFormSchema,
  UserFormSchema,
} from "../../types/FormSchema";

export enum SectionType {
  User = "user",
  Required = "required",
  Authorization = "authorization",
  Reauthorization = "reauthorization",
  Additional = "additional",
  Stakeholder = "stakeholder",
  PatientOptional = "patientOptional",
  Other = "other",
  Files = "files",
}

export const validateEntireFormInSections = async (
  formValues: (ReferralFormSchema | ReauthFormSchema) & UserFormSchema & Files,
  formType: FormType
) => {
  let errors: { errors: Record<string, string>; sections: SectionType[] } = {
    errors: {},
    sections: [],
  };

  const validationSchemas =
    formType === FormType.Referral
      ? [
          { schema: UserFormValidation, section: SectionType.User },
          { schema: RequiredInfoFormValidation, section: SectionType.Required },
          {
            schema: AuthorizationFormValidation,
            section: SectionType.Authorization,
          },
          {
            schema: AdditionalInfoFormValidation,
            section: SectionType.Additional,
          },
          { schema: stakeholderSection, section: SectionType.Stakeholder },
          {
            schema: optionalPatientSection,
            section: SectionType.PatientOptional,
          },
          { schema: otherSection, section: SectionType.Other },
        ]
      : [
          { schema: UserFormValidation, section: SectionType.User },
          {
            schema: ReauthorizationFormValidation,
            section: SectionType.Reauthorization,
          },
        ];
  // Map over the validationSchemas and return an array of promises
  const validationPromises = validationSchemas.map(
    async (currentSchema: any) => {
      try {
        await currentSchema.schema.validate(formValues, { abortEarly: false });
      } catch (err: any) {
        const currentErrors = err.inner.reduce(
          (acc: any, currentError: { message: string; path: string }) => {
            acc[currentError.path] = currentError.message;
            return acc;
          },
          {}
        );

        errors.errors = { ...errors.errors, ...currentErrors };
        errors.sections = [currentSchema.section, ...errors.sections];
      }
    }
  );

  // Wait for all promises to resolve/reject
  await Promise.all(validationPromises);

  if (formType === FormType.Reauth) {
    if (!formValues?.fileUploads?.length) {
      errors.errors["fileUploads"] = "Required.";
      if (!errors.sections.some((section) => section === SectionType.User)) {
        errors.sections.push(SectionType.Files);
      }
    }
  }
  
  return errors;
};
