import {
  Box,
  Button,
  Checkbox,
  Dialog,
  FormControlLabel,
  Grid,
  LinearProgress,
  Typography,
} from "@material-ui/core";
import { useContext, useEffect, useMemo, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { FormContext } from "../../../contexts/FormContext";
import { CancelDialog } from "../../dialogs/CancelDialog";
import DialogContainer from "../../dialogs/DialogContainer";
import styleVars from "../../../_export.module.scss";
import { ROUTE_TO_STEP_MAPPINGS } from "../../../constants/routeMappings";
import common from "../../../Common.module.scss";
import { DialogOptions, FormType } from "../../../types/FormSchema";
import { serializeReferralData } from "../../../helpers/serializers/serializeReferralData";
import { ic_error as icError } from "react-icons-kit/md";
import Icon from "react-icons-kit";
import UploadDialog from "../../dialogs/UploadDialog";
import classes from "./FormFooter.module.scss";
import { postReauth, postReferral } from "../../../api/form";
import { serializeReauthData } from "../../../helpers/serializers/serializeReauthData";
import { getPrefillFieldsFromUser } from "../../../helpers/prefill";

const FormFooter = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const {
    formStep,
    metadata,
    formValid,
    persistState,
    formData,
    formNavigation,
    files,
    userData,
    setFormData,
    setPersistState,
    setMetadata,
    setFormNavigation,
    setClearFormData,
  } = useContext(FormContext);

  const [submitting, setSubmitting] = useState<boolean>(false);
  const [shownDialog, setShownDialog] = useState(DialogOptions.ServerError);
  const [submitOnDialogClose, setSubmitOnDialogClose] = useState(false);
  const [uploadWarningAcknowledged, setUploadWarningAcknowledged] =
    useState(false);

  const formType = useMemo(() => metadata?.formType, [metadata?.formType]);

  //#region route helpers
  const currentViewingStep = useMemo(() => {
    if (!formType) return 0;
    return ROUTE_TO_STEP_MAPPINGS[formType][location.pathname]
      ? ROUTE_TO_STEP_MAPPINGS[formType][location.pathname]
      : 0;
  }, [formType, location.pathname]);

  const stepsToRoutes = useMemo(() => {
    if (!formType) return {};
    const mappedRoutes = ROUTE_TO_STEP_MAPPINGS[formType];
    const flippedMappings: Record<number, string> = {};
    Object.keys(mappedRoutes).forEach(
      (key) => (flippedMappings[mappedRoutes[key]] = key)
    );
    return flippedMappings;
  }, [formType]);

  const lastStep = useMemo(
    () => (formType ? Object.keys(ROUTE_TO_STEP_MAPPINGS[formType]).length : 0),
    [formType]
  );
  //#endregion route helpers

  //#region display input helpers
  const showCancel = useMemo(
    () => currentViewingStep > 0,
    [currentViewingStep]
  );

  const showRememberMe = useMemo(
    () => location.pathname === "/",
    [location.pathname]
  );

  const showSubmit = useMemo(
    () => currentViewingStep > 0 && currentViewingStep === lastStep,
    [currentViewingStep, lastStep]
  );
  //#endregion

  const submitForm = async (toggleDialog: () => void) => {
    setSubmitting(true);

    try {
      if (metadata.formType === FormType.Referral) {
        const referralPayload = serializeReferralData({
          ...formData,
          ...userData,
          fileUploads: files,
        });
        const submissionResponse = await postReferral(referralPayload);
        setMetadata({
          ...metadata,
          referralId: submissionResponse?.id,
        });
      } else {
        const reauthPayload = serializeReauthData({
          ...formData,
          ...userData,
          fileUploads: files,
        });
        const submissionResponse = await postReauth(reauthPayload);
        setMetadata({
          ...metadata,
          reauthId: submissionResponse?.id,
        });
      }
      navigate(`/${metadata.formType}/complete`);
    } catch (error) {
      setShownDialog(DialogOptions.ServerError);
      toggleDialog();
      console.error(error);
    }
    setSubmitting(false);
  };

  const onSubmitClick = async (e: any, toggleDialog: () => void) => {
    e.preventDefault();
    if (!formValid) {
      setShownDialog(DialogOptions.ValidationError);
      toggleDialog();
      return;
    } else if (!files.length && !uploadWarningAcknowledged) {
      setShownDialog(DialogOptions.UploadWarning);
      setUploadWarningAcknowledged(true);
      toggleDialog();
      return;
    }
    submitForm(toggleDialog);
  };

  //Navigation rules
  useEffect(() => {
    if (location.pathname === "/" || !formType)
      setFormNavigation({
        next: "/form/select",
        previous: undefined,
        last: undefined,
      });
    else if (location.pathname === "/form/select" && !formType)
      setFormNavigation({
        next: undefined,
        previous: "/",
        last: undefined,
      });
    else {
      let previous = undefined;
      if (currentViewingStep === 1) previous = "/";
      else if (currentViewingStep > 1 && currentViewingStep < lastStep)
        previous = stepsToRoutes[currentViewingStep - 1];

      setFormNavigation({
        next:
          currentViewingStep < lastStep
            ? stepsToRoutes[currentViewingStep + 1]
            : undefined,
        previous,
        last:
          currentViewingStep < lastStep &&
          formType === FormType.Referral &&
          currentViewingStep > 0
            ? stepsToRoutes[lastStep]
            : undefined,
      });
    }
  }, [
    currentViewingStep,
    formType,
    lastStep,
    location.pathname,
    setFormNavigation,
    stepsToRoutes,
  ]);

  return (
    <Grid container spacing={2}>
      <Grid container spacing={2}>
        <Box height="30px"></Box>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          {formNavigation?.next ? (
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                size="medium"
                disabled={!formValid}
                onClick={() => {
                  if (
                    currentViewingStep === 0 &&
                    metadata.formType === FormType.Referral
                  ) {
                    setFormData((prevValues) => {
                      return {
                        ...prevValues,
                        ...getPrefillFieldsFromUser(userData, formStep),
                      };
                    });
                  }
                  if (formNavigation?.next) navigate(formNavigation.next);
                  else
                    console.error(
                      `Unexpected route, next route was not defined.  Check route mappings.  Current route: ${location.pathname}`
                    );
                }}
              >
                <Typography variant="button">Next</Typography>
              </Button>
            </Grid>
          ) : null}
          {showSubmit ? (
            <>
              <DialogContainer
                hideCloseButton={true}
                title={(() => {
                  if (shownDialog === DialogOptions.ServerError) {
                    return "Sorry... something went wrong";
                  } else if (shownDialog === DialogOptions.UploadWarning) {
                    return "Please be sure to upload your documents";
                  } else {
                    return undefined;
                  }
                })()}
                classes={(() => {
                  if (shownDialog === DialogOptions.ValidationError) {
                    return classes.submitValidationDialog;
                  } else if (shownDialog === DialogOptions.ServerError) {
                    return classes.submitErrorDialog;
                  } else if (shownDialog === DialogOptions.UploadWarning) {
                    return classes.submitUploadWarningDialog;
                  } else {
                    return classes.submitErrorDialog;
                  }
                })()}
                onDialogClose={(toggleDialog: () => void) => {
                  if (submitOnDialogClose) {
                    setSubmitOnDialogClose(false);
                    submitForm(toggleDialog);
                  }
                }}
                dialogContent={({
                  toggleDialog,
                }: {
                  toggleDialog: () => void;
                }) => {
                  if (shownDialog === DialogOptions.ValidationError) {
                    return (
                      <div className={classes.submitValidationDialog}>
                        <Icon
                          icon={icError}
                          size={64}
                          style={{ color: styleVars.colorError }}
                        />
                        <h2>Oops! Some info is missing</h2>
                        <Typography component="p" variant="body2">
                          Please click <strong>edit</strong> wherever you see
                          "Info missing" on this page
                        </Typography>
                        <Button
                          variant="outlined"
                          color="primary"
                          size="medium"
                          onClick={() => {
                            toggleDialog();
                          }}
                        >
                          <Typography variant="button">OK</Typography>
                        </Button>
                      </div>
                    );
                  } else if (shownDialog === DialogOptions.ServerError) {
                    return (
                      <div className={classes.submitErrorDialog}>
                        <p>
                          Please wait a few minutes and then click "Submit"
                          again.
                        </p>
                        <Button
                          variant="contained"
                          color="primary"
                          size="medium"
                          onClick={() => {
                            toggleDialog();
                          }}
                        >
                          <Typography variant="button">OK</Typography>
                        </Button>
                      </div>
                    );
                  } else if (shownDialog === DialogOptions.UploadWarning) {
                    return (
                      <>
                        <p>
                          Documentation such as prescriptions, FROIs, job
                          descriptions, and doctor's notes will help ensure
                          speedy placement for your patient.
                        </p>
                        <Button
                          variant="contained"
                          color="primary"
                          size="medium"
                          onClick={() => {
                            setShownDialog(DialogOptions.UploadManagement);
                          }}
                        >
                          <Typography variant="button">Upload</Typography>
                        </Button>
                        <Button
                          variant="outlined"
                          color="primary"
                          size="medium"
                          onClick={() => {
                            setSubmitOnDialogClose(true);
                            toggleDialog();
                          }}
                        >
                          <Typography variant="button">
                            Nothing to upload
                          </Typography>
                        </Button>
                      </>
                    );
                  } else if (shownDialog === DialogOptions.UploadManagement) {
                    return <UploadDialog toggleDialog={toggleDialog} />;
                  } else {
                    return <></>;
                  }
                }}
                dialogTrigger={({ toggleDialog }) => (
                  <Grid item>
                    <Button
                      variant="contained"
                      color="primary"
                      size="medium"
                      onClick={(e: any) => onSubmitClick(e, toggleDialog)}
                      disabled={submitting || !formValid}
                    >
                      <Typography variant="button">Submit</Typography>
                    </Button>
                  </Grid>
                )}
              />
              <Dialog id="submit-wait-modal" open={submitting}>
                <Box
                  id="submit-wait-modal-content"
                  textAlign="center"
                  width="600px"
                  padding="40px"
                  boxSizing="border-box"
                  style={{
                    backgroundColor: "white",
                  }}
                >
                  <LinearProgress style={{ height: 12 }}></LinearProgress>
                  <Typography
                    id="submit-wait-modal-title"
                    style={{
                      fontWeight: "medium",
                      fontSize: styleVars.fontSize3XL,
                      paddingTop: 40,
                    }}
                  >
                    {formType === FormType.Reauth ? (
                      <span>
                        Please wait while we process <br />
                        your re-authorization
                      </span>
                    ) : (
                      "Please wait while we process your referral"
                    )}
                  </Typography>
                </Box>
              </Dialog>
            </>
          ) : null}
          {showCancel ? (
            <DialogContainer
              hideCloseButton
              classes={"cancel-dialog"}
              dialogTrigger={({ toggleDialog }) => (
                <Grid item>
                  <Button
                    variant="outlined"
                    color="primary"
                    size="medium"
                    onClick={() => {
                      toggleDialog();
                    }}
                  >
                    <Typography variant="button">Cancel</Typography>
                  </Button>
                </Grid>
              )}
              dialogContent={({ toggleDialog }) => (
                <CancelDialog
                  toggleDialog={toggleDialog}
                  handleClearForm={() => {
                    setClearFormData(true);
                    navigate("/");
                  }}
                />
              )}
            />
          ) : null}
          <Grid item>
            {formNavigation?.previous ? (
              <Button
                color="primary"
                size="medium"
                onClick={() => {
                  if (formNavigation?.previous)
                    navigate(formNavigation.previous);
                }}
                style={{ textTransform: "none" }}
              >
                <Typography>Back</Typography>
              </Button>
            ) : null}
          </Grid>
          <Grid item>
            {formNavigation?.last ? (
              <Button
                color="primary"
                size="medium"
                disabled={!formValid}
                onClick={() => {
                  if (formNavigation?.last) navigate(formNavigation.last);
                }}
                style={{ textTransform: "none" }}
              >
                <Typography>Skip to last step</Typography>
              </Button>
            ) : null}
          </Grid>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        {showRememberMe ? (
          <>
            <Grid container spacing={2}>
              <Box height="20px"></Box>
            </Grid>
            <Grid
              item
              xs={12}
              style={{
                marginBottom: "30px",
              }}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    name="userRememberMe"
                    checked={persistState}
                    onChange={(event) => {
                      setPersistState(event.target.checked);
                    }}
                  />
                }
                label={
                  <Box display="flex" flexDirection="column">
                    <Typography
                      variant="body1"
                      className={common.fontLargeBold}
                      component="span"
                    >
                      Remember me
                    </Typography>
                    <Typography
                      variant="body1"
                      style={{ fontSize: styleVars.fontSizeSmall }}
                      component="span"
                    >
                      {" "}
                      (If checked, this information will be used any time you
                      use this form)
                    </Typography>
                  </Box>
                }
              />
            </Grid>
          </>
        ) : null}
      </Grid>

      {currentViewingStep > 0 && formType !== FormType.Reauth ? (
        <Grid item xs={3}>
          <Box
            display="flex"
            justifyContent="space-between"
            minWidth="250px"
            alignItems="center"
          >
            <LinearProgress
              variant="determinate"
              value={25 * formStep}
              color="secondary"
              style={{ width: "145px" }}
            />
            <Typography
              variant="body1"
              style={{ fontSize: styleVars.fontSizeLarge }}
            >
              (Step {formStep} of {Object.keys(stepsToRoutes).length})
            </Typography>
          </Box>
        </Grid>
      ) : null}
    </Grid>
  );
};

export default FormFooter;
