import { Moment } from "moment";
import {
  AdjusterDTO,
  CaseManagerDTO,
  EmployerDTO,
  PhysicianDTO,
} from "../api/autocomplete";
import { MedicalDiagnosisType } from "./RequiredInfoTypes";

export enum UserType {
  Adjuster = "adjuster",
  ReferringDoctor = "referringDoctor",
  CaseManager = "caseManager",
  Other = "other",
}

export enum FormType {
  Referral = "referral",
  Reauth = "reauth",
}

export enum UserNewOrExisting {
  New = "new",
  Existing = "existing",
}

export enum DialogOptions {
  ServerError = "serverError",
  ValidationError = "validationError",
  UploadWarning = "uploadWarning",
  UploadManagement = "uploadManagement",
}

export enum BodyPartsNewOrExisting {
  New = "new",
  Existing = "existing",
}

export type FormSchema = {
  metadata: MetaInfo;
  user: UserFormSchema;
  form: ReferralFormSchema | ReauthFormSchema;
  files: Files | ReviewFiles;
};

export type ReferralFormSchema = RequiredInfoFormSchema &
  AuthorizationFormSchema &
  AdditionalInfoSchema;

export type ReauthFormSchema = ReauthorizationFormSchema;

export type UploadFileType = {
  path: string;
  lastModified: number;
  lastModifiedDate: Date;
  name: string;
  size: number;
  type: string;
  webkitRelativePath: string;
};

export type SelectAdjuster = {
  label: string;
  id: string;
} & AdjusterDTO;

export type SelectCaseManager = {
  label: string;
  id: string;
} & CaseManagerDTO;

export type SelectPhysician = {
  label: string;
  id: string;
} & PhysicianDTO;

//Simple way to pass the file list around in a combination object without typescript complaining.
//{...props, files, ...otherProps}: ReferralFormSchema | ReauthFormSchema & Files
export type Files = {
  fileUploads: FileUpload[];
};

export type ReviewFiles = {
  fileUploads: ReviewFile[];
};

export type ReviewFile = {
  name?: string;
  category?: string;
};

export type FileUpload = {
  name: string;
  category: string | null;
  size: number;
  content: boolean;
  file: UploadFileType;
  fileId: string | null;
};

export type Adjuster = {
  adjusterCompany?: string;
  adjusterEmail?: string;
  adjusterFirstName?: string;
  adjusterLastName?: string;
  adjusterPhoneNumber?: string;
  adjusterPhonePreferred?: boolean;
  adjusterEmailPreferred?: boolean;
  adjusterPhoneType?: string;
  adjusterPhoneExtension?: string;
};

export type CaseManager = {
  caseManagerFirstName?: string;
  caseManagerLastName?: string;
  caseManagerEmail?: string;
  caseManagerCompany?: string;
  caseManagerPhonePreferred?: boolean;
  caseManagerEmailPreferred?: boolean;
  caseManagerPhoneNumber?: string;
  caseManagerPhoneType?: string;
  caseManagerPhoneExtension?: string;
};

export type Physician = {
  physicianFirstName?: string;
  physicianLastName?: string;
  physicianPhoneNumber?: string;
  physicianPhoneExtension?: string;
  physicianEmail?: string;
  physicianPhoneType?: string;
  checkBoxPhysicianPhonePreferred?: boolean;
  checkBoxPhysicianEmailPreferred?: boolean;
};

export type SelectEmployer = {
  label: string;
} & EmployerDTO;

export type BodyPart = {
  BodyPartId: string;
  BodyPartName: string;
  id?: string;
  label: string;
};

export type User = {
  userType?: UserType;
  userFirstName?: string;
  userLastName?: string;
  userEmail?: string;
  userCompany?: string;
  userPhonePreferred?: boolean;
  userEmailPreferred?: boolean;
  userPhoneNumber?: string;
  userPhoneType?: string;
  userPhoneExtension?: string;
  userRoleInCase?: string;
  userNewOrExisting?: UserNewOrExisting;
};

export type RequiredPatient = {
  patientFirstName?: string;
  patientLastName?: string;
  patientBirthday?: string | Date | Moment | null;
  patientPhoneNumber?: string;
  patientSex?: string;
  patientPrimaryLanguage?: string;
  medicalDiagnosis?: MedicalDiagnosisType[];
  jobTitle?: string;
};

export type OptionalPatient = {
  patientPreferredName?: string;
  patientMiddleName?: string;
  patientSuffix?: string;
  patientAddress?: string;
  patientCity?: string;
  patientState?: string;
  patientZip?: string;
  patientMaritalStatus?: string;
  patientRace?: string;
  patientEthnicity?: string;
  patientPreferredLanguage?: string;
  patientEmergencyContactName?: string;
  patientEmergencyContactRelationship?: string;
  patientEmergencyContactPhoneNumber?: string;
  patientPreferredClinic?: string;
  patientPreferredClinician?: string;
  patientOtherInfo?: string;
};

export type Employer = {
  employerCompany?: string;
  employerContactName?: string;
  employerAddress?: string;
  employerCity?: string;
  employerState?: string;
  employerZipCode?: string;
  employerEmail?: string;
  checkBoxEmployerEmailPreferred?: boolean;
  employerPhoneNumber?: string;
  employerPhoneType?: string;
  employerPhoneExtension?: string;
  checkBoxEmployerPhonePreferred?: boolean;
};

export type UserFormSchema = {
  customUserInfo?: boolean;
  userAdjuster?: SelectAdjuster;
  userReferringPhysician?: SelectPhysician;
  userCaseManager?: SelectCaseManager;
  adjusterEmailSearch?: string;
  caseManagerEmailSearch?: string;
  physicianPhoneSearch?: string;
  physicianFaxSearch?: string;
  physicianLastNameSearch?: string;
  emailSearch?: string;
} & User;

export const initializedUser: UserFormSchema = {
  userFirstName: "",
  userLastName: "",
  userEmail: "",
  userCompany: "",
  userPhonePreferred: false,
  userEmailPreferred: false,
  userPhoneNumber: "",
  userPhoneType: "",
  userPhoneExtension: "",
  userRoleInCase: "",
  adjusterEmailSearch: "",
  caseManagerEmailSearch: "",
  physicianPhoneSearch: "",
  physicianFaxSearch: "",
  physicianLastNameSearch: "",
  emailSearch: "",
};

export type RequiredInfoFormSchema = {
  interpreterAuthorized?: boolean;
  transportationAuthorized?: boolean;
  claimNumber?: string;
  stateOfJury?: string;
  caseType?: string;
  specialty?: string;
  treatingPhysician?: SelectPhysician;
  dateOfInjury?: string | Date | Moment | null;
  checkBoxForPhysicianInfo?: boolean;
  bodyPart?: BodyPart[];
  hasProgram?: boolean;
  programId?: string;
  programRisk?: string;
  surgical?: string;
  dateOfSurgery?: string | Date | Moment | null;
  employerSelected?: SelectEmployer;
  checkBoxForEmployer?: boolean;
} & Physician &
  RequiredPatient &
  Employer;

export const initializedReqInfoForm: RequiredInfoFormSchema = {
  patientFirstName: "",
  patientLastName: "",
  patientBirthday: null,
  patientPhoneNumber: "",
  patientSex: "",
  patientPrimaryLanguage: "",
  claimNumber: "",
  stateOfJury: "",
  caseType: "",
  specialty: "",
  dateOfInjury: null,
  physicianFirstName: "",
  physicianLastName: "",
  physicianEmail: "",
  physicianPhoneNumber: "",
  physicianPhoneType: "",
  physicianPhoneExtension: "",
  medicalDiagnosis: [],
  bodyPart: [],
  hasProgram: false,
  programId: "",
  programRisk: "",
  surgical: "",
  dateOfSurgery: null,
  jobTitle: "",
  employerCompany: "",
  employerContactName: "",
  employerAddress: "",
  employerCity: "",
  employerState: "",
  employerZipCode: "",
  employerEmail: "",
  employerPhoneNumber: "",
  employerPhoneType: "",
  employerPhoneExtension: "",
  checkBoxForPhysicianInfo: false,
  checkBoxPhysicianEmailPreferred: false,
  checkBoxPhysicianPhonePreferred: false,
  checkBoxForEmployer: false,
  checkBoxEmployerEmailPreferred: false,
  checkBoxEmployerPhonePreferred: false,
};

export interface AuthorizationFormSchema {
  authScriptDate?: string | Date | Moment | null;
  authScriptEndDate?: string | Date | Moment | null;
  authAuthorizedVisits?: string;
  authVisitTimes?: string;
  authVisitWeeks?: string;
  authAuthorizedBy?: string;
  authAdditionalInjuryDate?: string | Date | Moment | null;
  authReturnToDoctorDate?: string | Date | Moment | null;
  authAuthorizationNumber?: string;
}

export const initializedAuthForm: AuthorizationFormSchema = {
  authScriptDate: null,
  authScriptEndDate: null,
  authAuthorizedVisits: "",
  authVisitTimes: "",
  authVisitWeeks: "",
  authAuthorizedBy: "",
  authAdditionalInjuryDate: null,
  authReturnToDoctorDate: null,
  authAuthorizationNumber: "",
};

export interface ReauthorizationFormSchema {
  patientFirstName?: string;
  patientLastName?: string;
  patientBirthday?: string | Date | Moment | null;
  claimNumber?: string;
  authAuthorizedVisits?: string;
  authVisitTimes?: string;
  authVisitWeeks?: string;
  authAuthorizedBy?: string;
  authScriptDate?: string | Date | Moment | null;
  useNewOrExistingBodyParts?: BodyPartsNewOrExisting | null;
  reauthBodyParts?: BodyPart[];
}

export const initializedReauthForm: ReauthorizationFormSchema = {
  patientFirstName: "",
  patientLastName: "",
  patientBirthday: null,
  claimNumber: "",
  authAuthorizedVisits: "",
  authVisitTimes: "",
  authVisitWeeks: "",
  authAuthorizedBy: "",
  authScriptDate: null,
  useNewOrExistingBodyParts: null,
  reauthBodyParts: [],
};

export type AdditionalInfoSchema = {
  selectAdjuster?: SelectAdjuster;
  selectCaseManager?: SelectCaseManager;
  // Checkboxes for adding custom details
  customCaseManagerInfo?: boolean;
  customAdjusterInfo?: boolean;
  // Adjuster fold out form
  adjusterUserInfo?: boolean;
  // Case Manager fold out form
} & Adjuster &
  CaseManager &
  OptionalPatient;

export const initializedAdditionalInfoForm: AdditionalInfoSchema = {
  // Patient Form
  patientPreferredName: "",
  patientMiddleName: "",
  patientSuffix: "",
  patientAddress: "",
  patientCity: "",
  patientState: "",
  patientZip: "",
  patientMaritalStatus: "",
  patientRace: "",
  patientEthnicity: "",
  patientPreferredLanguage: "",
  patientEmergencyContactName: "",
  patientEmergencyContactRelationship: "",
  patientEmergencyContactPhoneNumber: "",
  patientPreferredClinic: "",
  patientPreferredClinician: "",
  patientOtherInfo: "",
  adjusterFirstName: "",
  adjusterLastName: "",
  adjusterEmail: "",
  adjusterCompany: "",
  adjusterPhoneNumber: "",
  adjusterPhoneType: "",
  adjusterPhoneExtension: "",
  caseManagerFirstName: "",
  caseManagerLastName: "",
  caseManagerEmail: "",
  caseManagerCompany: "",
  caseManagerPhoneNumber: "",
  caseManagerPhoneType: "",
  caseManagerPhoneExtension: "",
};

export interface MetaInfo {
  formStep?: number;
  formType?: FormType;
  referralId?: string;
  reauthId?: string;
}

export type ReviewSchema = {
  label: string;
  value: string;
  customFormatter: (inValue: any) => any;
};

export const getInitializedReferralForm = () => {};

export const getInitializedReauthForm = () => {};
