import * as Yup from "yup";
import { ERROR_REQUIRED_TXT } from "../constants/textConstants";
import {
  phoneExtensionValidation,
  phoneNumberValidationTest,
} from "../helpers/validators/validationHelpers";
import { UserNewOrExisting, UserType } from "../types/FormSchema";

const validationSchema = Yup.object().shape({
  customUserInfo: Yup.boolean(),

  //Required if the user is "New" vs. existing from search
  userFirstName: Yup.string().when("userType", {
    is: (val: string) => !!val && val !== UserType.Other,
    then: Yup.string().when("userNewOrExisting", {
      is: UserNewOrExisting.New,
      then: Yup.string().required(ERROR_REQUIRED_TXT),
    }),
    otherwise: Yup.string().required(ERROR_REQUIRED_TXT),
  }),

  //Required if the user is "New" vs. existing from search
  userLastName: Yup.string().when("userType", {
    is: (val: string) => val !== UserType.Other,
    then: Yup.string().when("userNewOrExisting", {
      is: UserNewOrExisting.New,
      then: Yup.string().required(ERROR_REQUIRED_TXT),
    }),
    otherwise: Yup.string().required(ERROR_REQUIRED_TXT),
  }),

  //Required if the user is "New" and not the referring doctor.
  userCompany: Yup.string().when("userType", {
    is: (val: string) =>
      val !== UserType.Other && val !== UserType.ReferringDoctor,
    then: Yup.string().when("userNewOrExisting", {
      is: UserNewOrExisting.New,
      then: Yup.string().required(ERROR_REQUIRED_TXT),
    }),
    otherwise: Yup.string(),
  }),

  userPhoneType: Yup.string(),

  userPhoneExtension: phoneExtensionValidation,

  //Required if the user is "Existing" and an adjuster
  userAdjuster: Yup.string().when("userType", {
    is: (val: string) => val === UserType.Adjuster,
    then: Yup.string().when("userNewOrExisting", {
      is: UserNewOrExisting.Existing,
      then: Yup.string().required(ERROR_REQUIRED_TXT),
    }),
  }),

  //Required if the user is "Existing" and the referring doctor
  userReferringPhysician: Yup.string().when("userType", {
    is: (val: string) => val === UserType.ReferringDoctor,
    then: Yup.string().when("userNewOrExisting", {
      is: UserNewOrExisting.Existing,
      then: Yup.string().required(ERROR_REQUIRED_TXT),
    }),
  }),

  //Required if the user is "Existing" and a referring doctor
  userCaseManager: Yup.string().when("userType", {
    is: (val: string) => val === UserType.CaseManager,
    then: Yup.string().when("userNewOrExisting", {
      is: UserNewOrExisting.Existing,
      then: Yup.string().required(ERROR_REQUIRED_TXT),
    }),
  }),

  //Required if the user is of type "Other", implied "New" status.
  userRoleInCase: Yup.string().when("userType", {
    is: (val: string) => val === UserType.Other,
    then: Yup.string().required(ERROR_REQUIRED_TXT),
    otherwise: Yup.string(),
  }),

  userNewOrExisting: Yup.string().required(""),

  userEmail: Yup.string().when("userNewOrExisting", {
    is: UserNewOrExisting.New,
    then: Yup.string().when("userEmailPreferred", {
      is: true,
      then: Yup.string()
        .email("Invalid email")
        .required("Required when preferred"),
      otherwise: Yup.string().when(
        ["userPhonePreferred", "userEmail", "userPhoneNumber"],
        {
          is: (userPhonePreferred, userEmail, userPhoneNumber) =>
            !userPhonePreferred && !userEmail && !userPhoneNumber,
          then: Yup.string().required("Phone or email required"),
          otherwise: Yup.string().email("Invalid email"),
        }
      ),
    }),
  }),
  userPhoneNumber: Yup.string().when("userNewOrExisting", {
    is: UserNewOrExisting.New,
    then: Yup.string().when("userPhonePreferred", {
      is: true,
      then: Yup.string()
        .test("len", "Invalid phone", phoneNumberValidationTest)
        .required("Required when preferred"),
      otherwise: Yup.string().when(
        ["userEmailPreferred", "userEmail", "userPhoneNumber"],
        {
          is: (userEmailPreferred, userEmail, userPhoneNumber) =>
            !userEmailPreferred && !userEmail && !userPhoneNumber,
          then: Yup.string().required("Phone or email required"),
          otherwise: Yup.string().when(["userPhoneNumber"], {
            is: (userPhoneNumber) => !!userPhoneNumber?.length,
            then: Yup.string().test(
              "len",
              "Invalid phone",
              phoneNumberValidationTest
            ),
          }),
        }
      ),
    }),
  }),
  userPhonePreferred: Yup.boolean().test(
    "userPhonePreferred",
    ERROR_REQUIRED_TXT,
    function (value: boolean | null | undefined) {
      const { userEmailPreferred, userPhoneNumber, userEmail } = this.parent;
      const bothContactFieldsFilled =
        !!userPhoneNumber?.length && !!userEmail?.length;
      const noPreferredContact = !value && !userEmailPreferred;

      return !bothContactFieldsFilled || !noPreferredContact;
    }
  ),
  userEmailPreferred: Yup.boolean().test(
    "userEmailPreferred",
    ERROR_REQUIRED_TXT,
    function (value: boolean | null | undefined) {
      const { userPhonePreferred, userPhoneNumber, userEmail } = this.parent;
      const bothContactFieldsFilled =
        !!userPhoneNumber?.length && !!userEmail?.length;
      const noPreferredContact = !value && !userPhonePreferred;

      return !bothContactFieldsFilled || !noPreferredContact;
    }
  ),
});

export default validationSchema;
