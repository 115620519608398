import { FormType } from "../types/FormSchema";

export const ROUTE_TO_STEP_MAPPINGS: Record<FormType, Record<string, number>> = {
  [FormType.Referral]: {
    "/referral/requiredInfo": 1,
    "/referral/authorization": 2,
    "/referral/additionalInfo": 3,
    "/referral/review": 4,
  },
  [FormType.Reauth]: {
    "/reauth/authorization": 1,
    "/reauth/review": 2,
  },
};