import { RequiredInfoFormSchema } from "../../types/FormSchema";
export const checkFastTrack = (formValues: RequiredInfoFormSchema) => {
  const physicianInfo = !!formValues?.checkBoxForPhysicianInfo
    ? formValues?.physicianFirstName &&
      formValues?.physicianLastName &&
      (formValues?.physicianEmail || formValues?.physicianPhoneNumber)
    : formValues?.treatingPhysician;

  const employerInfo = !!formValues?.checkBoxForEmployer
    ? formValues?.employerCompany &&
      formValues?.employerContactName &&
      formValues?.employerAddress &&
      formValues?.employerCity &&
      formValues?.employerState &&
      formValues?.employerZipCode &&
      (formValues?.employerEmail || formValues?.employerPhoneNumber)
    : formValues?.employerSelected;

  return (
    formValues?.patientFirstName &&
    formValues?.patientLastName &&
    formValues?.patientBirthday &&
    formValues?.patientPhoneNumber &&
    formValues?.patientSex &&
    formValues?.patientPrimaryLanguage &&
    formValues?.claimNumber &&
    formValues?.stateOfJury &&
    formValues?.caseType &&
    formValues?.specialty &&
    formValues?.dateOfInjury &&
    formValues?.medicalDiagnosis &&
    formValues?.bodyPart &&
    formValues.surgical &&
    formValues?.jobTitle &&
    physicianInfo &&
    employerInfo
  );
};
