import {
  Button,
  Typography,
} from "@material-ui/core";
import './CancelDialog.scss';
interface CancelDialogProps {
  isCancelDialogOpen?: boolean;
  setIsCancelDialogOpen?: (x: boolean) => void;
  toggleDialog: () => void;
  handleClearForm: () => void;
}

export const CancelDialog = ({
  toggleDialog,
  handleClearForm
}: CancelDialogProps) => {
  return (
    <>
      <h2>Do you really want to cancel?</h2>
      <Typography variant="body2">
        This will delete your request and all the information in the form.
      </Typography>
      <p></p>
      <Button
        variant="contained"
        color="primary"
        size="medium"
        onClick={() => {
          toggleDialog();
          handleClearForm();
        }}
      >
        <Typography variant="button">Yes</Typography>
      </Button>
      <Button
        variant="outlined"
        color="primary"
        size="medium"
        onClick={() => {
          toggleDialog();
        }}
      >
        <Typography variant="button">No</Typography>
      </Button>
    </>
  );
};
