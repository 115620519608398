import { useRef, useState } from "react";
import { useContext, useEffect } from "react";
import {
  Box,
  Grid,
  FormControlLabel,
  Typography,
  FormControl,
  FormHelperText,
} from "@material-ui/core";

import {
  Field,
  Form,
  ErrorMessage,
  useFormik,
  FormikProvider,
  FormikProps,
  FormikErrors,
} from "formik";
import { FormContext } from "../../contexts/FormContext";
import { MagicTextField } from "../inputs/generic/MagicTextField";
import { MagicPhoneField } from "../inputs/generic/MagicPhoneField";
import { MagicSelect } from "../inputs/generic/MagicSelect";
import RadioGroupField from "../inputs/generic/RadioGroupField";
import MagicCheckbox from "../inputs/generic/MagicCheckbox";
import userFormValidation from "../../yup/UserFormValidation";
import styleVars from "../../_export.module.scss";
import {
  UserType,
  UserFormSchema,
  initializedUser,
  UserNewOrExisting,
} from "../../types/FormSchema";
import { UserSelection } from "../inputs/UserSelection/UserSelection";
import { wakeupPdfLambda } from "../../api/pdfWakeup";
import {
  AdjusterDTO,
  CaseManagerDTO,
  PhysicianDTO,
} from "../../api/autocomplete";
import common from "../../Common.module.scss";

const UserForm = () => {
  const [fetchedMatches, setFetchedMatches] = useState<
    AdjusterDTO[] | CaseManagerDTO[] | PhysicianDTO[]
  >([]);

  const {
    userData,
    formValid,
    formStep,
    setUserData,
    setFormValid,
    setFormStep,
  } = useContext(FormContext);

  const formik: FormikProps<UserFormSchema> = useFormik({
    initialValues: {
      ...initializedUser,
      ...userData,
      userType: userData?.userType ?? null,
      customUserInfo: userData.userType === UserType.Other,
      userNewOrExisting:
        userData.userNewOrExisting ??
        (userData.userType === UserType.Other
          ? UserNewOrExisting.New
          : UserNewOrExisting.Existing),
    } as UserFormSchema,

    validationSchema: userFormValidation,
    //No submit method required, form validation occurrs automatically and submit his handled by the next button in the form footer.
    //Formik just handles local state + form validation.
    onSubmit: () => {},
    validateOnChange: true,
    validateOnBlur: true,
    //Prevents timing issues with form context being updated and the new component initializing state from saved formData.  Not ideal but will work for now.
    enableReinitialize: true,
  });
  const { values, setFieldValue, isValid, validateForm, errors } =
    formik;

  const refValues = useRef(values);

  const clearSelectFields = () => {
     //User form field reset.
    setFieldValue("userAdjuster", "");
    setFieldValue("userReferringPhysician", "");
    setFieldValue("userCaseManager", "");
    setFieldValue("emailSearch", "");
    setFieldValue("physicianPhoneSearch", "");
    setFieldValue("physicianFaxSearch", "");
    setFieldValue("physicianLastNameSearch", "");
    setFetchedMatches([]);
  };

  // call the API method once on mount to ensure the PDF lambda is awake
  useEffect(() => {
    wakeupPdfLambda();
  }, []);

  //Validate form on component mount
  useEffect(() => {
    validateForm();
  }, [validateForm]);

  useEffect(() => {
    //Local state is the source of truth, context just exposes current form validation state for use in navigation.
    if (isValid !== formValid) {
      setFormValid(isValid);
    }
  }, [isValid, formValid, setFormValid]);

  useEffect(() => {
    if (!isValid && formStep !== 0) {
      setFormStep(0);
    }
  }, [isValid, formStep, setFormStep]);

  useEffect(() => {
    refValues.current = values;
  }, [values]);

  useEffect(() => {
    // Clear required/additional form data parsed from the user selection (see prefill.ts).
    setFieldValue("selectAdjuster", "");
    setFieldValue("treatingPhysician", "");
    setFieldValue("selectCaseManager", "");
    setFieldValue("adjusterEmailSearch", "");
    setFieldValue("caseManagerEmailSearch","");
  }, [setFieldValue, values.userType]);

  // Exercise caution when modifying this as updating context in an unmount can cause infinite updates
  useEffect(() => {
    // When the component unmounts, update the context values with the current form values
    return () => {
      setUserData((prevValues: UserFormSchema) => {
        return {
          ...prevValues,
          ...refValues.current,
        };
      });
    };
  }, [setUserData]);

  return (
    <FormikProvider value={formik}>
      <Form noValidate>
        <Grid item xs={12}>
          <Typography
            variant="h5"
            style={{ color: styleVars.colorReferralBlue }}
          >
            Thanks for choosing Bardavon
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Box width="925px"></Box>
        </Grid>
        <Grid item xs={12} style={{ marginTop: "40px" }}>
          <Typography variant="body1" className={common.fontLargeBold}>
            I am the*:
          </Typography>
        </Grid>
        <Grid item xs={11}>
          <Box mb="20px">
            <RadioGroupField
              name="userType"
              row
              otherAction={clearSelectFields}
              options={[
                { value: UserType.Adjuster, label: "Adjuster" },
                {
                  value: UserType.ReferringDoctor,
                  label: "Referring doctor",
                },
                { value: UserType.CaseManager, label: "Nurse/Case manager" },
                { value: UserType.Other, label: "Other" },
              ]}
            />
          </Box>
        </Grid>
        <Grid item xs={10}>
          <Box className={common.divider} height="1px"></Box>
        </Grid>
        <Grid container spacing={2}>
          <Box height="30px"></Box>
        </Grid>
        {values.userType && values.userType !== UserType.Other && (
          <Grid
            item
            xs={11}
            style={{ marginTop: "20px", marginBottom: "20px" }}
          >
            <Box>
              <RadioGroupField
                name="userNewOrExisting"
                row
                options={[
                  {
                    value: UserNewOrExisting.Existing,
                    label: "I've referred to Bardavon before",
                  },
                  {
                    value: UserNewOrExisting.New,
                    label: "I'm new to Bardavon",
                  },
                ]}
              />
            </Box>
          </Grid>
        )}

        {values.userType &&
          values.userNewOrExisting === UserNewOrExisting.Existing &&
          values.userType !== UserType.Other && (
            <UserSelection
              formik={formik}
              userType={values?.userType}
              fetchedMatches={fetchedMatches}
              setFetchedMatches={setFetchedMatches}
            />
          )}
        {values.userType &&
          (values.userNewOrExisting === UserNewOrExisting.New ||
            values.userType === UserType.Other) && (
            <CustomUserInfoForm
              values={values}
              setFieldValue={setFieldValue}
              errors={errors}
            />
          )}
      </Form>
    </FormikProvider>
  );
};

const CustomUserInfoForm = ({
  values,
  setFieldValue,
  errors,
}: {
  values: UserFormSchema;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean
  ) => Promise<void | FormikErrors<UserFormSchema>>;
  errors: FormikErrors<UserFormSchema>;
}) => {
  return (
    <Grid container spacing={2} style={{ marginTop: "20px" }}>
      <Grid item xs={6}>
        <Field
          required
          component={MagicTextField}
          name="userFirstName"
          type="text"
          label="First name"
          helperText={<ErrorMessage name="userFirstName" />}
        />
      </Grid>
      <Grid item xs={6}>
        <Field
          required
          component={MagicTextField}
          name="userLastName"
          type="text"
          label="Last name"
          helperText={<ErrorMessage name="userLastName" />}
        />
      </Grid>
      {values?.userType !== UserType.ReferringDoctor && (
        <>
          <Grid item xs={6}>
            <Field
              required={values?.userType !== UserType.Other}
              component={MagicTextField}
              name="userCompany"
              type="text"
              label="Company"
              helperText={<ErrorMessage name="userCompany" />}
            />
          </Grid>
          {values?.userType !== UserType.Other && <Grid item xs={6}></Grid>}
        </>
      )}
      {values?.userType === UserType.Other && (
        <>
          <Grid item xs={6}>
            <Field
              required
              component={MagicTextField}
              name="userRoleInCase"
              type="text"
              label="Role in case"
              helperText={<ErrorMessage name="userRoleInCase" />}
            />
          </Grid>
          {values?.userType !== UserType.Other &&
            values?.userType !== UserType.ReferringDoctor && (
              <Grid item xs={6}></Grid>
            )}
        </>
      )}
      <Grid item xs={8}>
        <Typography
          variant="body2"
          style={{ color: styleVars.colorSecondaryText }}
        >
          Contact method (choose one*)
        </Typography>
      </Grid>
      <Grid item xs={8}>
        <Field
          required={!!values?.userEmailPreferred}
          component={MagicTextField}
          name="userEmail"
          type="text"
          label="Email"
          helperText={<ErrorMessage name="userEmail" />}
        />
      </Grid>
      <Grid item xs={4}>
        <FormControl
          error={!!errors.userPhonePreferred}
          required={
            !!values.userPhoneNumber &&
            !!values.userEmail &&
            !values.userPhonePreferred
          }
        >
          <FormControlLabel
            control={
              <MagicCheckbox
                name="userEmailPreferred"
                setFieldValue={setFieldValue}
                otherAction={() =>
                  values?.userPhonePreferred &&
                  setFieldValue("userPhonePreferred", false)
                }
              />
            }
            label={
              <Typography
                variant="body1"
                style={{
                  fontSize: styleVars.fontSizeLarge,
                  color: styleVars.colorSecondaryText,
                }}
              >
                Preferred method of contact
              </Typography>
            }
          />
          {!!errors.userPhonePreferred && (
            <FormHelperText>Preferred contact method required</FormHelperText>
          )}
        </FormControl>
      </Grid>
      <Grid item xs={3}>
        <Field
          component={MagicPhoneField}
          label="Phone number"
          name="userPhoneNumber"
          value={values.userPhoneNumber}
          type="tel"
          required={
            !!values?.userPhonePreferred ||
            values?.userType === UserType.ReferringDoctor
          }
          InputProps={{
            onChange: (e: { target: HTMLInputElement }) =>
              setFieldValue("userPhoneNumber", e.target.value, false),
          }}
        />
      </Grid>

      <Grid item xs={3}>
        <Field
          name="userPhoneType"
          label="Type"
          id="user-phone-type-select"
          component={MagicSelect}
          items={[
            {
              name: "\0",
              value: "",
            },
            { name: "Mobile", value: "M" },
            { name: "Work", value: "W" },
          ]}
          onChange={(e: { target: HTMLInputElement }) => {
            setFieldValue("userPhoneType", e.target.value);
          }}
        />
      </Grid>
      <Grid item xs={2}>
        <Field
          component={MagicTextField}
          name="userPhoneExtension"
          type="text"
          label="Extension"
          helperText={<ErrorMessage name="userPhoneExtension" />}
        />
      </Grid>
      <Grid item xs={4}>
        <FormControl
          error={!!errors.userPhonePreferred}
          required={
            !!values.userPhoneNumber &&
            !!values.userEmail &&
            !values.userEmailPreferred
          }
        >
          <FormControlLabel
            control={
              <MagicCheckbox
                name="userPhonePreferred"
                setFieldValue={setFieldValue}
                otherAction={() =>
                  values?.userEmailPreferred &&
                  setFieldValue("userEmailPreferred", false)
                }
              />
            }
            label={
              <Typography
                variant="body1"
                style={{
                  fontSize: styleVars.fontSizeLarge,
                  color: styleVars.colorSecondaryText,
                }}
              >
                Preferred method of contact
              </Typography>
            }
          />
          {!!errors.userPhonePreferred && (
            <FormHelperText>Preferred contact method required</FormHelperText>
          )}
        </FormControl>
      </Grid>
    </Grid>
  );
};

export default UserForm;
