import React, { useState, useEffect, useCallback } from 'react';
import MagicSearchInput from '../generic/MagicSearchInput';
import { Autocomplete } from 'formik-material-ui-lab';
import { Field, FormikErrors, FormikTouched } from 'formik';
import { ReferralFormSchema, SelectAdjuster } from '../../../types/FormSchema';
import { ic_search as icSearch } from 'react-icons-kit/md';
import Icon from 'react-icons-kit';
import { toSearchString, filterOptions } from '../../../helpers/formatters/autocomplete';
import { AdjusterDTO, fetchAdjusterMatches } from '../../../api/autocomplete';

const getStatusLabel = (status: string) => {
  if (status === 'noResults') {
    return 'No results found';
  } else if (status === 'searching') {
    return 'Searching...';
  } else {
    return 'Search';
  }
};

export const AdjusterSelect = (props: {
  name: string;
  disabled: boolean;
  touched: FormikTouched<{ [field: string]: any }>;
  errors: { [field: string]: any };
  setTouched: (
    fields: { [field: string]: any },
    shouldValidate?: boolean
  ) => Promise<void | FormikErrors<ReferralFormSchema>>;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean
  ) => Promise<void | FormikErrors<ReferralFormSchema>>;
}) => {
  const [adjusters, setAdjusters] = useState<SelectAdjuster[]>([]);
  const [filteredAdjusters, setFilteredAdjusters] = useState<SelectAdjuster[]>([]);
  const [inputValue, setInputValue] = useState<string>('');
  const [searchValue, setSearchValue] = useState<string>('');
  const [status, setStatus] = useState<string>('idle');

  const fetchAdjusters = useCallback((signal) => {
    if (searchValue?.length === 3) {
      setStatus("searching");
      fetchAdjusterMatches(searchValue, signal).then((response) => {
        setStatus(response.data.length > 0 ? "idle" : "noResults");
        if (response.data.length > 0) {
          const adjusterData = response.data as AdjusterDTO[];

          setStatus("idle");
          setAdjusters(
            adjusterData.map((d: AdjusterDTO) => ({
              label: `${d.firstName || ""} ${d.lastName || ""} ${
                d.company ? "-" : ""
              } ${d.company || ""}`,
              ...d,
            }))
          );
        }
      }).catch((error) => console.log('Error:', error));;
    }
  },[searchValue]);

  useEffect(() => {
    const abortController = new AbortController();
    if (searchValue.length < 3) setFilteredAdjusters([]);
    else fetchAdjusters(abortController.signal);
    return () => {
      abortController.abort();
    };
  }, [fetchAdjusters, searchValue.length]);

  // filter the case managers options based on the input
  useEffect(() => {
    const clean = toSearchString(inputValue);
    if (clean.length >= 3) {
      setStatus("searching");
      const filtered = filterOptions<SelectAdjuster>(adjusters, clean);
      if (filtered.length === 0) {
        setStatus("noResults");
      } else {
        setStatus("idle");
        setFilteredAdjusters(filtered);
      }
    }
  }, [inputValue, adjusters]);

  return (
    <Field
      name={props.name}
      component={Autocomplete}
      id="adjuster-select-search"
      disabled={props.disabled}
      options={filteredAdjusters}
      filterOptions={(options: [], state: any) => options}
      getOptionLabel={(option: { label: string }) => option.label}
      onChange={(
        e: { target: HTMLInputElement },
        value: {
          label: string;
          id: string;
          Email: string;
          Company: string;
          LastName: string;
          FirstName: string;
          SalesforceId: string;
        }[]
      ) => {
        props.setFieldValue(props.name, value);
      }}
      onBlur={() => {
        const touchedFields = {
          ...props.touched,
        };
        touchedFields[props.name] = true;
        props.setTouched(touchedFields);
      }}
      noOptionsText={getStatusLabel(status)}
      renderOption={(option: { label: string }) => (
        <div
          style={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          {option.label}
        </div>
      )}
      renderInput={(params: any) => {
        const errorProps: { error?: boolean; helperText?: string } =
          props.errors?.[props.name] && props.touched?.[props.name]
            ? {
                error: true,
                helperText: 'Required Field',
              }
            : {};
        return (
          <MagicSearchInput
            {...params}
            label="Adjuster"
            variant="outlined"
            helperText="Tip: Start typing first or last name to search"
            onChange={(e: { target: HTMLInputElement }) =>{
              const newInputValue = e.target.value ?? "";
              const newSearchValue = toSearchString(newInputValue).substring(
                0,
                3
              );
              if (searchValue !== newSearchValue) {
                setSearchValue(newSearchValue);
              }
              setInputValue(newInputValue);
            }}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <Icon
                  icon={icSearch}
                  size={24}
                  style={{
                    color: 'gray',
                    position: 'relative',
                    left: props.disabled ? '26px' : '52px',
                  }}
                />
              ),
            }}
            {...errorProps}
          />
        );
      }}
    />
  );
};
