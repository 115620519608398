import { NOVALUE } from "../../constants/textConstants";
import moment from "moment";
import {
  ReferralFormSchema,
  UserFormSchema,
  UserType,
} from "../../types/FormSchema";

export const formatDate = (
  date: string | Date | moment.Moment | null | undefined
): string => {
  let dateMoment;

  if (typeof date === "string") {
    dateMoment = moment(date, "YYYY-MM-DD");
  } else if (date instanceof Date) {
    dateMoment = moment(date);
  } else if (moment.isMoment(date)) {
    dateMoment = date;
  } else {
    return NOVALUE;
  }

  return dateMoment.format("M/D/YYYY");
};

export const getContactPreference = (
  phonePreferred: boolean | undefined,
  emailPreferred: boolean | undefined
) => {
  if (!!phonePreferred) {
    return "Phone";
  } else if (!!emailPreferred) {
    return "Email";
  } else {
    return null;
  }
};

export const getSurgicalFormattedNames = (surgicalKey: string | undefined) => {
  switch (surgicalKey) {
    case "surgical":
      return "Yes";
    case "nonsurgical":
      return "No";
    case "unknown":
      return "Unknown";
    default:
      return NOVALUE;
  }
};

export const formatSurgicalStatus = (surgicalStatus: string | undefined) => {
  return surgicalStatus?.toLowerCase()?.replace("-", "") ?? "unknown";
};

export const getSelectNameProperty = (
  name: string | undefined,
  source: { name: string; value: string }[] | undefined
) => {
  return source?.find(
    (item: { name: string; value: string }) => item.value === name
  )?.name;
};

export const getUserName = (userData: UserFormSchema) => {
  if (userData?.userNewOrExisting === "new") {
    return userData?.userFirstName && userData?.userLastName
      ? `${userData?.userFirstName} ${userData?.userLastName} - ${userData?.userCompany}`
      : NOVALUE;
  } else if (!!userData?.userAdjuster) {
    return userData?.userAdjuster?.firstName || userData?.userAdjuster?.lastName
      ? `${userData?.userAdjuster?.firstName} ${
          userData?.userAdjuster?.lastName
        } ${userData?.userAdjuster?.company ? "-" : ""} ${
          userData?.userAdjuster?.company
        }`
      : NOVALUE;
  } else if (!!userData?.userCaseManager) {
    return userData?.userCaseManager?.firstName ||
      userData?.userCaseManager?.lastName
      ? `${userData?.userCaseManager?.firstName} ${
          userData?.userCaseManager?.lastName
        } ${userData?.userCaseManager?.company ? "-" : ""}
        ${userData?.userCaseManager?.company}
        `
      : NOVALUE;
  } else if (!!userData?.userReferringPhysician) {
    return userData?.userReferringPhysician?.firstName ||
      userData?.userReferringPhysician?.lastName
      ? `${userData?.userReferringPhysician?.firstName} ${userData?.userReferringPhysician?.lastName}
      `
      : NOVALUE;
  } else {
    return NOVALUE;
  }
};

export const getUserPhone = (
  formValues: ReferralFormSchema & UserFormSchema
) => {
  if (formValues?.userNewOrExisting === "new") {
    return formValues?.userPhoneNumber ? formValues?.userPhoneNumber : NOVALUE;
  } else if (!!formValues?.userAdjuster) {
    return formValues?.userAdjuster?.phone
      ? formValues?.userAdjuster?.phone
      : NOVALUE;
  } else if (!!formValues?.userCaseManager) {
    return formValues?.userCaseManager?.phone
      ? formValues?.userCaseManager?.phone
      : NOVALUE;
  } else if (!!formValues?.userReferringPhysician) {
    return formValues?.userReferringPhysician?.phone
      ? formValues?.userReferringPhysician?.phone
      : NOVALUE;
  } else {
    return NOVALUE;
  }
};

export const getUserEmail = (userData: UserFormSchema) => {
  if (userData?.userNewOrExisting === "new") {
    return userData?.userEmail ? userData?.userEmail : NOVALUE;
  } else if (!!userData?.userAdjuster) {
    return userData?.userAdjuster?.email
      ? userData?.userAdjuster?.email
      : NOVALUE;
  } else if (!!userData?.userCaseManager) {
    return userData?.userCaseManager?.email
      ? userData?.userCaseManager?.email
      : NOVALUE;
  } else if (!!userData?.userReferringPhysician) {
    return userData?.userReferringPhysician?.email
      ? userData?.userReferringPhysician?.email
      : NOVALUE;
  } else {
    return NOVALUE;
  }
};

export const getCaseType = (caseType: string | undefined) => {
  if (caseType === "PT") {
    return "Physical Therapy";
  } else if (caseType === "OT") {
    return "Occupational Therapy";
  } else if (caseType === "WC") {
    return "Work Conditioning";
  } else if (caseType === "FC") {
    return "Functional Capacity Testing";
  } else if (caseType === "EM") {
    return "Employment Testing";
  } else if (caseType) {
    return caseType;
  } else return null;
};

export const getFormattedPatientGender = (gender: string | undefined) => {
  if (gender === "M") {
    return "Male";
  } else if (gender === "F") {
    return "Female";
  } else if (gender) {
    return gender;
  } else {
    return null;
  }
};

export const getTreatingPhysician = (formValues: ReferralFormSchema) => {
  if (!!formValues?.checkBoxForPhysicianInfo) {
    return `${formValues?.physicianFirstName || ""} ${
      formValues?.physicianLastName || ""
    }${(formValues?.physicianPhoneNumber || "") && " - "}${
      formValues?.physicianPhoneNumber || ""
    }`;
  } else if (!formValues?.checkBoxForPhysicianInfo) {
    return formValues?.treatingPhysician?.label;
  } else {
    return null;
  }
};

export const formatVisitText = (
  authVisits: string | undefined,
  times: string | undefined,
  weeks: string | undefined
) => {
  let visitText = authVisits ? authVisits : "";
  if (times && weeks && parseInt(weeks)) {
    const frequencyText = `${times}/week for ${weeks} week${
      parseInt(weeks) > 1 ? "s" : ""
    }`;
    visitText = visitText + (visitText ? ", " : "") + frequencyText;
  }
  return visitText || NOVALUE;
};

export const formatAddress = (
  street: string | undefined,
  city: string | undefined,
  state: string | undefined,
  zip: string | undefined
) => {
  if (!street) {
    return NOVALUE;
  } else {
    return `${street || ""}${street && ", "}${city || ""}${city && ", "}${
      state || ""
    }${state && " "}${zip || ""}`;
  }
};

export const getUserTypeLabel = (userType: UserType | undefined) => {
  if (userType === UserType.Adjuster) {
    return "Adjuster";
  } else if (userType === UserType.CaseManager) {
    return "Case Manager";
  } else if (userType === UserType.ReferringDoctor) {
    return "Referring Physician";
  } else if (userType === UserType.Other) {
    return null;
  } else return null;
};
