import {
  createContext,
  useState,
  useMemo,
  ReactNode,
  useEffect,
  Dispatch,
  SetStateAction,
  useCallback,
} from "react";
import { FormNavigation } from "../types/FormNavigation";
import {
  MetaInfo,
  ReauthFormSchema,
  ReferralFormSchema,
  UserFormSchema,
  FileUpload,
} from "../types/FormSchema";

interface FormContextType {
  userData: UserFormSchema;
  metadata: MetaInfo;
  formData: ReferralFormSchema | ReauthFormSchema;
  formStep: number;
  formValid: boolean;
  persistState: boolean;
  formNavigation: FormNavigation;
  files: FileUpload[];
  clearFormData: boolean;
  setUserData: Dispatch<SetStateAction<UserFormSchema>>;
  setFormData: Dispatch<SetStateAction<ReferralFormSchema | ReauthFormSchema>>;
  setMetadata: Dispatch<SetStateAction<MetaInfo>>;
  setFormValid: Dispatch<SetStateAction<boolean>>;
  setPersistState: Dispatch<SetStateAction<boolean>>;
  setFormNavigation: Dispatch<SetStateAction<FormNavigation>>;
  setFormStep: (newStep: number) => void;
  setFiles: Dispatch<SetStateAction<FileUpload[]>>;
  setClearFormData: Dispatch<SetStateAction<boolean>>;
}

interface Props {
  children?: ReactNode;
}

const LOCAL_STORAGE_KEY = "userData";

//We changed the schema for adjuster, case manager, and treating physician.
//This mapping is so that we can adjust user persisted data quietly.
const SCHEMA_MAPPINGS: {
  userAdjuster: Record<string, string>;
  userCaseManager: Record<string, string>;
  userReferringPhysician: Record<string, string>;
} = {
  userAdjuster: {
    Email: "email",
    AccountType: "accountType",
    Company: "company",
    LastName: "lastName",
    FirstName: "firstName",
    SearchText: "searchText",
    SalesforceId: "id",
  },
  userCaseManager: {
    SalesforceId: "id",
    AccountType: "accountType",
    FirstName: "firstName",
    LastName: "lastName",
    Company: "company",
    Email: "email",
    Phone: "phone",
    Fax: "fax",
    NPI: "npi",
    SearchText: "searchText",
  },
  userReferringPhysician: {
    SalesforceId: "id",
    AccountType: "accountType",
    FirstName: "firstName",
    LastName: "lastName",
    Company: "company",
    Email: "email",
    Phone: "phone",
    Fax: "fax",
    NPI: "npi",
    SearchText: "searchText",
  },
};

export const FormContext = createContext({} as FormContextType);

export const FormProvider = ({ children }: Props) => {
  const [userData, setUserData] = useState({} as UserFormSchema);
  const [metadata, setMetadata] = useState({} as MetaInfo);
  const [formData, setFormData] = useState<
    ReferralFormSchema | ReauthFormSchema
  >({});
  const [files, setFiles] = useState<FileUpload[]>([]);
  const [formValid, setFormValid] = useState<boolean>(false);
  const [persistState, setPersistState] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(true);
  const [formNavigation, setFormNavigation] = useState<FormNavigation>({});
  const [clearFormData, setClearFormData] = useState<boolean>(false);

  const currentCompletedStep = useMemo(
    () => Number(metadata?.formStep ?? 1),
    [metadata?.formStep]
  );

  const setFormStep = useCallback(
    (newStep: number) => {
      setMetadata({ ...metadata, formStep: newStep });
    },
    [metadata]
  );

  // Temp function.  Can be removed after we determine all users likely have been moved to the new schema.
  const mapNewFieldKeys = (storedJsonData: any) => {
    // Iterate over our schema mappings (userAdjuster, userCaseManager, userReferringPhysician)
    Object.keys(SCHEMA_MAPPINGS).forEach((key) => {
      const schemaKey = key as keyof typeof SCHEMA_MAPPINGS;
      // If the stored json has data for the mapped schema keys, then we need to transfer any data under the old keys to the new keys.
      if (
        schemaKey in storedJsonData &&
        storedJsonData[schemaKey] !== "" &&
        Object.keys(storedJsonData[schemaKey]).length
      ) {
        const newData: Record<string, string> = {};
        Object.keys(SCHEMA_MAPPINGS[schemaKey]).forEach((oldKey: string) => {
          if (oldKey in storedJsonData[schemaKey]) {
            const newKey = SCHEMA_MAPPINGS[schemaKey][oldKey];
            newData[newKey] = storedJsonData[schemaKey][oldKey];
          }
        });
        // We should never have partial object matching.
        // So either newData is populated with the full result set or it'll be empty.
        // localstorage will correct itself from the userdata
        if (Object.keys(newData).length) {
          console.warn(`Outdata schema found in local storage, correcting data: ${schemaKey} with ${newData}`);
          storedJsonData[schemaKey] = newData;
        }
      }
    });
    return storedJsonData
  };

  //On component first load, set form data
  useEffect(() => {
    try {
      const storedData = localStorage.getItem(LOCAL_STORAGE_KEY);
      console.log("storedData data.", storedData)
      if (storedData) {
        const parsedStoredData = JSON.parse(storedData);
        console.log("Correcting data.")
        const correctedData = mapNewFieldKeys(parsedStoredData);
        setUserData({...correctedData});
      }
    } catch (error) {
      console.error("Error parsing stored user data", error);
      setLoading(false);
    }
    setLoading(false);
  }, []);

  //If userRememberMe is false and the local storage item exists, make sure we remove it.
  useEffect(() => {
    if (!persistState && LOCAL_STORAGE_KEY in localStorage) {
      localStorage.removeItem(LOCAL_STORAGE_KEY);
    } else if (persistState) {
      localStorage.setItem(
        LOCAL_STORAGE_KEY,
        JSON.stringify(userData)
      );
    }
  }, [
    persistState,
    userData,
  ]);

  const context = useMemo(
    () => ({
      userData,
      metadata,
      formData,
      formValid,
      persistState,
      formStep: currentCompletedStep,
      formNavigation,
      files,
      clearFormData,
      setUserData,
      setFormData,
      setMetadata,
      setFormValid,
      setPersistState,
      setFormNavigation,
      setFormStep,
      setFiles,
      setClearFormData,
    }),
    [
      userData,
      metadata,
      formData,
      formValid,
      currentCompletedStep,
      persistState,
      formNavigation,
      files,
      clearFormData,
      setUserData,
      setFormData,
      setMetadata,
      setFormValid,
      setPersistState,
      setFormNavigation,
      setFormStep,
      setFiles,
      setClearFormData,
    ]
  );

  return (
    <FormContext.Provider value={context}>
      {loading ? <p>Loading...</p> : children}
    </FormContext.Provider>
  );
};
