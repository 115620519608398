import * as Yup from "yup";

export const phoneNumberValidationTest: (
  value: string | null | undefined,
  context: Yup.TestContext
) => boolean | Yup.ValidationError = (value, context) => {
  if (value === undefined) return true;

  if (typeof value !== "number" && typeof value !== "string") return false;

  const number = value.toString().replace(/\D/g, "");

  return number.length === 10;
};

export const phoneExtensionValidation = Yup.number()
  .transform((value, originalValue) => (originalValue === "" ? null : value))
  .nullable()
  .max(999999, "extension cannot exceed 6 characters")
  .typeError("Extension must be a number");
