import * as Yup from "yup";
import {
  phoneExtensionValidation,
  phoneNumberValidationTest,
} from "../helpers/validators/validationHelpers";
import { ERROR_REQUIRED_TXT } from "../constants/textConstants";

const patientInfoValidation = {
  patientPreferredName: Yup.string().max(100, "Must be 100 characters or less"),
  patientMiddleName: Yup.string().max(40, "Must be 40 characters or less"),
  patientSuffix: Yup.string(),
  patientAddress: Yup.string().max(255, "Must be 255 characters or less"),
  patientCity: Yup.string().max(100, "Must be 100 characters or less"),
  patientState: Yup.string(),
  patientZip: Yup.string()
    .notRequired()
    .nullable()
    .transform((value) => (!!value ? value : null))
    .matches(/^\d{5}(-\d{4})?$/, "Must be a valid postal code (5 or 9 digits)"),
  patientMaritalStatus: Yup.string(),
  patientRace: Yup.string(),
  patientEthnicity: Yup.string(),
  patientPreferredLanguage: Yup.string(),
  patientEmergencyContactName: Yup.string().max(
    255,
    "Must be 255 characters or less"
  ),
  patientEmergencyContactRelationship: Yup.string(),
  patientPhoneNumber: Yup.string().test(
    "len",
    "Invalid phone",
    phoneNumberValidationTest
  ),
};

const otherPatientInfoValidation = {
  patientPreferredClinic: Yup.string().max(
    255,
    "Must be 255 characters or less"
  ),
  patientPreferredClinician: Yup.string().max(
    255,
    "Must be 255 characters or less"
  ),
  patientOtherInfo: Yup.string().max(1000, "Must be 1000 characters or less"),
};

const stakeholderValidation = {
  adjusterFirstName: Yup.string()
    .max(40, "Must be 40 characters or less")
    .when("customAdjusterInfo", {
      is: true,
      then: Yup.string().required(ERROR_REQUIRED_TXT),
    }),
  adjusterLastName: Yup.string()
    .max(40, "Must be 40 characters or less")
    .when("customAdjusterInfo", {
      is: true,
      then: Yup.string().required(ERROR_REQUIRED_TXT),
    }),
  adjusterCompany: Yup.string()
    .max(100, "Must be 100 characters or less")
    .when("customAdjusterInfo", {
      is: true,
      then: Yup.string().required(ERROR_REQUIRED_TXT),
    }),
  adjusterPhoneType: Yup.string(),
  adjusterPhoneExtension: phoneExtensionValidation,
  adjusterPhoneNumber: Yup.string().when("customAdjusterInfo", {
    is: true,
    then: Yup.string().when("adjusterPhonePreferred", {
      is: true,
      then: Yup.string()
        .test("len", "Invalid phone", phoneNumberValidationTest)
        .required("Required when preferred"),
      otherwise: Yup.string().when(
        ["adjusterEmailPreferred", "adjusterEmail", "adjusterPhoneNumber"],
        {
          is: (adjusterEmailPreferred, adjusterEmail, adjusterPhoneNumber) =>
            !adjusterEmailPreferred && !adjusterEmail && !adjusterPhoneNumber,
          then: Yup.string().required("Phone or email required"),
          otherwise: Yup.string().test(
            "len",
            "Invalid phone",
            phoneNumberValidationTest
          ),
        }
      ),
    }),
  }),
  adjusterEmail: Yup.string().when("customAdjusterInfo", {
    is: true,
    then: Yup.string().when("adjusterEmailPreferred", {
      is: true,
      then: Yup.string()
        .email("Invalid email")
        .required("Required when preferred"),
      otherwise: Yup.string().when(
        ["adjusterPhonePreferred", "adjusterEmail", "adjusterPhoneNumber"],
        {
          is: (adjusterPhonePreferred, adjusterEmail, adjusterPhoneNumber) =>
            !adjusterPhonePreferred && !adjusterEmail && !adjusterPhoneNumber,
          then: Yup.string().required("Phone or email required"),
          otherwise: Yup.string().email("Invalid email"),
        }
      ),
    }),
  }),

  caseManagerFirstName: Yup.string()
    .max(40, "Must be 40 characters or less")
    .when("customCaseManagerInfo", {
      is: true,
      then: Yup.string().required(ERROR_REQUIRED_TXT),
    }),
  caseManagerLastName: Yup.string()
    .max(40, "Must be 40 characters or less")
    .when("customCaseManagerInfo", {
      is: true,
      then: Yup.string().required(ERROR_REQUIRED_TXT),
    }),

  caseManagerCompany: Yup.string()
    .max(100, "Must be 100 characters or less")
    .when("customCaseManagerInfo", {
      is: true,
      then: Yup.string().required(ERROR_REQUIRED_TXT),
    }),
  caseManagerPhoneType: Yup.string(),
  caseMangerPhoneExtension: phoneExtensionValidation,
  caseManagerPhoneType2: Yup.string(),
  caseManagerPhoneNumber2: Yup.string().test(
    "len",
    "Invalid phone",
    phoneNumberValidationTest
  ),
  caseManagerPhoneExtension2: phoneExtensionValidation,
  caseManagerEmail: Yup.string().when("customCaseManagerInfo", {
    is: true,
    then: Yup.string().when("caseManagerEmailPreferred", {
      is: true,
      then: Yup.string()
        .email("Invalid email")
        .required("Required when preferred"),
      otherwise: Yup.string().when(
        [
          "caseManagerPhonePreferred",
          "caseManagerEmail",
          "caseManagerPhoneNumber",
        ],
        {
          is: (
            caseManagerPhonePreferred,
            caseManagerEmail,
            caseManagerPhoneNumber
          ) =>
            !caseManagerPhonePreferred &&
            !caseManagerEmail &&
            !caseManagerPhoneNumber,
          then: Yup.string().required("Phone or email required"),
          otherwise: Yup.string().email("Invalid email"),
        }
      ),
    }),
  }),
  caseManagerPhoneNumber: Yup.string().when("customCaseManagerInfo", {
    is: true,
    then: Yup.string().when("caseManagerPhonePreferred", {
      is: true,
      then: Yup.string()
        .test("len", "Invalid phone", phoneNumberValidationTest)
        .required("Required when preferred"),
      otherwise: Yup.string().when(
        [
          "caseManagerEmailPreferred",
          "caseManagerEmail",
          "caseManagerPhoneNumber",
        ],
        {
          is: (
            caseManagerEmailPreferred,
            caseManagerEmail,
            caseManagerPhoneNumber
          ) =>
            !caseManagerEmailPreferred &&
            !caseManagerEmail &&
            !caseManagerPhoneNumber,
          then: Yup.string().required("Phone or email required"),
          otherwise: Yup.string().test(
            "len",
            "Invalid phone",
            phoneNumberValidationTest
          ),
        }
      ),
    }),
  }),

  adjusterEmailPreferred: Yup.boolean(),
  adjusterPhonePreferred: Yup.boolean(),
  caseManagerEmailPreferred: Yup.boolean(),
  caseManagerPhonePreferred: Yup.boolean(),
};

const validationSchema = Yup.object().shape({
  caseManagerInfo: Yup.string(),
  adjusterInfo: Yup.string(),
  // Custom adjuster
  customAdjusterInfo: Yup.boolean(),
  // Custom case manager form
  customCaseManagerInfo: Yup.boolean(),

  ...patientInfoValidation,
  ...otherPatientInfoValidation,
  ...stakeholderValidation,
});

export const optionalPatientSection = Yup.object().shape(patientInfoValidation)
export const otherSection = Yup.object().shape(otherPatientInfoValidation)
export const stakeholderSection = Yup.object().shape(stakeholderValidation)

export default validationSchema;
