import React, { useState, useEffect, useCallback } from "react";
import MagicSearchInput from "../generic/MagicSearchInput";
import { Autocomplete } from "formik-material-ui-lab";
import { Field, FormikTouched, FormikErrors } from "formik";
import { ReferralFormSchema, SelectEmployer } from "../../../types/FormSchema";
import { ic_search as icSearch } from "react-icons-kit/md";
import Icon from "react-icons-kit";
import {
  toSearchString,
  filterOptions,
} from "../../../helpers/formatters/autocomplete";
import { EmployerDTO, fetchEmployerMatches } from "../../../api/autocomplete";

const getStatusLabel = (status: string) => {
  if (status === "noResults") {
    return "No results found";
  } else if (status === "searching") {
    return "Searching...";
  } else {
    return "Search";
  }
};

export const EmployerSelect = (props: {
  className?: string;
  name: string;
  disabled: boolean;
  touched: FormikTouched<{ [field: string]: any }>;
  errors: { [field: string]: any };
  setTouched: (
    fields: { [field: string]: any },
    shouldValidate?: boolean
  ) => Promise<void | FormikErrors<ReferralFormSchema>>;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean
  ) => Promise<void | FormikErrors<ReferralFormSchema>>;
}) => {
  const [employers, setEmployers] = useState([]);
  const [filteredEmployers, setFilteredEmployers] = useState<SelectEmployer[]>(
    []
  );
  const [inputValue, setInputValue] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [status, setStatus] = useState("idle");

  const fetchEmployers = useCallback(
    (signal: AbortSignal) => {
      if (searchValue?.length === 3) {
        fetchEmployerMatches(searchValue, signal)
          .then((response) => {
            setStatus(response.data.length > 0 ? "idle" : "noResults");
            setEmployers(
              response.data.map((d: EmployerDTO) => {
                return {
                  label: d.name,
                  ...d,
                };
              })
            );
          })
          .catch((error) => console.log("Error:", error));
      }
    },
    [searchValue]
  );

  useEffect(() => {
    const abortController = new AbortController();
    if (searchValue.length < 3) setFilteredEmployers([]);
    else fetchEmployers(abortController.signal);
    return () => {
      abortController.abort();
    };
  }, [fetchEmployers, searchValue.length]);

  // filter the employers options based on the input
  useEffect(() => {
    const clean = toSearchString(inputValue);
    if (clean.length >= 3) {
      setStatus("searching");
      const filtered = filterOptions<SelectEmployer>(employers, clean);
      if (filtered.length === 0) {
        setStatus("noResults");
      } else {
        setStatus("idle");
        setFilteredEmployers(filtered);
      }
    }
  }, [employers, inputValue]);

  return (
    <Field
      name={props.name}
      component={Autocomplete}
      id="employers-select-search"
      disabled={props.disabled}
      options={filteredEmployers}
      filterOptions={(options: [], state: any) => options}
      getOptionLabel={(option: { label: string }) => option.label}
      onChange={(e: MouseEvent, value: SelectEmployer[]) => {
        props.setFieldValue(props.name, value);
      }}
      onBlur={() => {
        const touchedFields = { ...props.touched };
        touchedFields[props.name] = true;
        props.setTouched(touchedFields);
      }}
      noOptionsText={getStatusLabel(status)}
      renderOption={(option: { label: string }) => (
        <div
          style={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {option.label}
        </div>
      )}
      renderInput={(params: any) => {
        const errorProps =
          props.errors?.[props.name] && props.touched?.[props.name]
            ? {
                error: true,
                helperText: "Required Field",
              }
            : {};
        return (
          <MagicSearchInput
            style={{ marginTop: "4px" }}
            {...params}
            label="Employer"
            variant="outlined"
            helperText="Tip: Type any portion of the employer name to search"
            onChange={(e: { target: HTMLInputElement }) => {
              const newInputValue = e.target.value ?? "";
              const newSearchValue = toSearchString(newInputValue).substring(
                0,
                3
              );
              if (searchValue !== newSearchValue) {
                setSearchValue(newSearchValue);
              }
              setInputValue(newInputValue);
            }}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <Icon
                  icon={icSearch}
                  size={24}
                  style={{
                    color: "gray",
                    position: "relative",
                    left: !!props.disabled ? "26px" : "52px",
                  }}
                />
              ),
            }}
            {...errorProps}
          />
        );
      }}
    />
  );
};
