import {
  ReauthFormSchema,
  UserFormSchema,
  UserNewOrExisting,
  UserType,
  FileUpload,
  Files,
} from "../../types/FormSchema";
import { NewReauth, SubmissionFile } from "../../types/SubmissionSchema";
import { getContactPreference } from "../formatters/dataFormatters";
import {
  getPhoneType,
  momentStripTimestamp,
} from "../formatters/submissionFormatters";

export const serializeReauthData = ({
  userType,
  userAdjuster,
  userCaseManager,
  userReferringPhysician,
  userFirstName,
  userLastName,
  userNewOrExisting,
  userCompany,
  userEmail,
  userPhoneNumber,
  userPhoneType,
  userPhoneExtension,
  userPhonePreferred,
  userEmailPreferred,
  patientFirstName,
  patientLastName,
  patientBirthday,
  claimNumber,
  authAuthorizedVisits,
  authVisitTimes,
  authVisitWeeks,
  authAuthorizedBy,
  authScriptDate,
  reauthBodyParts,
  fileUploads,
}: ReauthFormSchema & UserFormSchema & Files): NewReauth => {
  return {
    submitterType: userType!,
    adjusterId: userAdjuster?.id ?? null,
    caseManagerId: userCaseManager?.id ?? null,
    physicianId: userReferringPhysician?.id ?? null,
    submitterFirstName:
      userNewOrExisting === UserNewOrExisting.New
        ? userFirstName ?? null
        : null,
    submitterLastName:
      userNewOrExisting === UserNewOrExisting.New ? userLastName ?? null : null,
    submitterCompany:
      userNewOrExisting === UserNewOrExisting.New &&
      userType !== UserType.ReferringDoctor
        ? userCompany ?? null
        : null,
    submitterEmail:
      userNewOrExisting === UserNewOrExisting.New ? userEmail ?? null : null,
    submitterPhone:
      userNewOrExisting === UserNewOrExisting.New
        ? userPhoneNumber ?? null
        : null,
    submitterPhoneType:
      userNewOrExisting === UserNewOrExisting.New
        ? getPhoneType(userPhoneType)
        : null,
    submitterPhoneExt:
      userNewOrExisting === UserNewOrExisting.New
        ? userPhoneExtension ?? null
        : null,
    submitterPreferredContact:
      userNewOrExisting === UserNewOrExisting.New
        ? getContactPreference(userPhonePreferred, userEmailPreferred)
        : null,
    patientFirstName: patientFirstName ?? null,
    patientLastName: patientLastName ?? null,
    patientBirthdate: momentStripTimestamp(patientBirthday),
    claimNumber: claimNumber ?? null,
    authorizedVisits: authAuthorizedVisits
      ? Number(authAuthorizedVisits)
      : null,
    authorizedPerWeek: authVisitTimes ? Number(authVisitTimes) : null,
    authorizedWeeks: authVisitWeeks ? Number(authVisitWeeks) : null,
    authorizedBy: authAuthorizedBy ?? null,
    scriptDate: momentStripTimestamp(authScriptDate),
    newBodyParts:
      reauthBodyParts?.map((bodyPart) => bodyPart.label)?.join(";") ?? null,
    fileUploads:
      fileUploads.map(
        (f: FileUpload) =>
          ({
            upload_id: f.fileId,
            filename: f.name,
            document_category: f.category,
          } as SubmissionFile)
      ) ?? [],
  };
};
