import React, { useEffect, useState } from "react";
import { UserType } from "../../../types/FormSchema";
import { MagicTextField } from "../generic/MagicTextField";
import { MagicPhoneField } from "../generic/MagicPhoneField";
import { Field, FormikProps } from "formik";
import { Button, Typography, Grid, Box } from "@material-ui/core";
import {
  fetchAdjusterMatches,
  fetchCaseManagerMatches,
  fetchReferringDoctorMatches,
} from "../../../api/autocomplete";
import "./UserSelection.scss";

type CardData = {
  company: string;
  email: string;
  firstName: string;
  lastName: string;
  id: string;
  npi?: string;
  phone?: string;
  fax?: string;
};

const getTargetField = (userType: UserType | undefined) => {
  switch (userType) {
    case UserType.Adjuster:
      return "userAdjuster";
    case UserType.CaseManager:
      return "userCaseManager";
    case UserType.ReferringDoctor:
      return "userReferringPhysician";
    default:
      return "other";
  }
};

const getTargetEmailField = (userType: UserType | undefined) => {
  switch (userType) {
    case UserType.Adjuster:
      return "adjusterEmailSearch";
    case UserType.CaseManager:
      return "caseManagerEmailSearch";
    case UserType.ReferringDoctor:
      return "";
    default:
      return "other";
  }
};

const isValidPhoneNumber = (phoneNumber: string): boolean => {
  const phoneRegex = /^\(\d{3}\) \d{3}-\d{4}$/;
  return phoneRegex.test(phoneNumber);
};

export const UserSelection = ({
  formik,
  userType,
  fetchedMatches,
  setFetchedMatches,
}: {
  formik: FormikProps<any>;
  userType: UserType | undefined;
  fetchedMatches: CardData[];
  setFetchedMatches: Function;
}) => {
  const [hasBeenFetched, setHasBeenFetched] = useState(false);
  const [controller, setController] = useState<AbortController>(
    new AbortController()
  );

  const targetField = getTargetField(userType);
  const targetEmailField = getTargetEmailField(userType);
  const isFindButtonDisabled =
    formik.values?.emailSearch === "" ||
    !formik.values?.emailSearch.includes("@") ||
    formik.values?.emailSearch?.length < 3;
  const isPhysicianFindDisabled = () => {
    if (
      isValidPhoneNumber(formik.values?.physicianPhoneSearch) ||
      isValidPhoneNumber(formik.values?.physicianFaxSearch)
    ) {
      if (
        !!formik.values?.physicianLastNameSearch &&
        formik.values?.physicianLastNameSearch?.length > 2
      ) {
        return false;
      }
    }
    return true;
  };

  const setEmailValue = (value: string) =>
    formik.setFieldValue("emailSearch", value);

  useEffect(() => {
    if (formik.values?.userAdjuster) {
      setFetchedMatches([formik.values?.userAdjuster]);
    } else if (formik.values?.userCaseManager) {
      setFetchedMatches([formik.values?.userCaseManager]);
    } else if (formik.values?.userReferringPhysician) {
      setFetchedMatches([formik.values?.userReferringPhysician]);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setHasBeenFetched(false);
  }, [userType]);

  const checkForMatches = async (e: { preventDefault: () => void }) => {
    e.preventDefault();
    try {
      if (userType === UserType.Adjuster) {
        const adjusters = await fetchAdjusterMatches(
          formik.values?.emailSearch,
          controller.signal
        );
        setFetchedMatches(adjusters.data);
      } else if (userType === UserType.CaseManager) {
        const caseManagers = await fetchCaseManagerMatches(
          formik.values?.emailSearch,
          controller.signal
        );
        setFetchedMatches(caseManagers.data);
      } else if (userType === UserType.ReferringDoctor) {
        const physicians = await fetchReferringDoctorMatches(
          formik.values?.physicianLastNameSearch,
          formik.values?.physicianPhoneSearch ||
            formik.values?.physicianFaxSearch,
          formik.values?.physicianPhoneSearch ? "phone" : "fax",
          controller.signal
        );
        setFetchedMatches(physicians.data);
      }
      setHasBeenFetched(true);
    } catch (e) {
      console.warn(e);
    }
  };

  useEffect(() => {
    // If there are no matches, set an error state
    if (fetchedMatches.length === 0) {
      formik.setFieldTouched(targetEmailField);
      formik.setFieldError(
        targetEmailField,
        "Sorry, we can't find this in our database."
      );
    }
    // If there's only one match, auto-select first result
    else if (fetchedMatches.length === 1) {
      formik.setFieldValue(targetField, fetchedMatches[0]);
    }
  }, [fetchedMatches]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!controller) {
      const newController = new AbortController();
      setController(newController);
    }

    return () => {
      if (controller) {
        controller.abort();
      }
    };
  }, [controller]);

  return (
    <div className="user-selection">
      <Typography variant="body1">
        Great! Let's see if we can automatically fill in your info - and make
        other selections faster, too.
      </Typography>
      <div className="selection-input-container">
        {userType === UserType.Adjuster && (
          <SearchMatchesField
            fieldName={targetEmailField}
            fieldLabel="Adjuster email"
            emailValue={formik.values?.emailSearch}
            setEmailValue={setEmailValue}
            hasBeenFetched={hasBeenFetched}
            fetchedMatches={fetchedMatches}
          />
        )}
        {userType === UserType.CaseManager && (
          <SearchMatchesField
            fieldName={targetEmailField}
            fieldLabel="Case Manager email"
            emailValue={formik.values?.emailSearch}
            setEmailValue={setEmailValue}
            hasBeenFetched={hasBeenFetched}
            fetchedMatches={fetchedMatches}
          />
        )}
        {userType === UserType.ReferringDoctor && (
          <Grid container spacing={2}>
            <Grid item xs={7}>
              <Field
                required
                component={MagicTextField}
                name="physicianLastNameSearch"
                type="text"
                label="Physician last name"
                error={
                  !!hasBeenFetched &&
                  !fetchedMatches?.length && {
                    physicianLastNameSearch: true,
                  }
                }
                helperText={
                  !!hasBeenFetched &&
                  !fetchedMatches?.length &&
                  "Sorry, we cant find this in our database"
                }
              />
            </Grid>
            <Grid item xs={5}></Grid>
            <Grid item xs={3}>
              <Field
                id="physician-phone-search"
                component={MagicPhoneField}
                label="Physician phone"
                name="physicianPhoneSearch"
                value={formik.values.physicianPhoneSearch}
                type="tel"
                required
                error={
                  !!hasBeenFetched &&
                  !fetchedMatches?.length &&
                  !!formik.values.physicianPhoneSearch && {
                    physicianPhoneSearch: true,
                  }
                }
                helperText={
                  !!hasBeenFetched &&
                  !fetchedMatches?.length &&
                  !!formik.values.physicianPhoneSearch &&
                  "Sorry, we cant find this in our database"
                }
                InputProps={{
                  onChange: (e: { target: HTMLInputElement }) => {
                    formik.setFieldValue(
                      "physicianPhoneSearch",
                      e.target.value,
                      false
                    );
                    formik.setFieldValue("physicianFaxSearch", "");
                  },
                }}
              />
            </Grid>
            <Grid item xs={1}>
              <Box
                display="flex"
                height="100%"
                alignItems="center"
                justifyContent="center"
              >
                <Typography align="center">- OR -</Typography>
              </Box>
            </Grid>
            <Grid item xs={3}>
              <Field
                id="physician-fax-search"
                component={MagicPhoneField}
                label="Physician fax"
                name="physicianFaxSearch"
                value={formik.values.physicianFaxSearch}
                type="tel"
                required
                error={
                  !!hasBeenFetched &&
                  !fetchedMatches?.length &&
                  !!formik.values.physicianFaxSearch && {
                    physicianFaxSearch: true,
                  }
                }
                helperText={
                  !!hasBeenFetched &&
                  !fetchedMatches?.length &&
                  !!formik.values.physicianFaxSearch &&
                  "Sorry, we cant find this in our database"
                }
                InputProps={{
                  onChange: (e: { target: HTMLInputElement }) => {
                    formik.setFieldValue(
                      "physicianFaxSearch",
                      e.target.value,
                      false
                    );
                    formik.setFieldValue("physicianPhoneSearch", "");
                  },
                }}
              />
            </Grid>
            <Grid item xs={1}>
              <Button
                variant="contained"
                color="primary"
                size="medium"
                // disabled={isFindButtonDisabled}
                disabled={isPhysicianFindDisabled()}
                onClick={checkForMatches}
              >
                <Typography variant="button">Find</Typography>
              </Button>
            </Grid>
          </Grid>
        )}
        {formik.values?.userType !== UserType.ReferringDoctor && (
          <Button
            variant="contained"
            color="primary"
            size="medium"
            disabled={isFindButtonDisabled}
            onClick={checkForMatches}
          >
            <Typography variant="button">Find</Typography>
          </Button>
        )}
      </div>
      {fetchedMatches.length !== 0 && (
        <>
          {fetchedMatches.map((matchedObject) => (
            <SelectFoundItem
              matchedObject={matchedObject}
              formik={formik}
              targetField={targetField}
              key={matchedObject?.id}
              hasOnlyOneResult={fetchedMatches.length === 1}
              userType={userType}
            />
          ))}
          {fetchedMatches.length === 1 && (
            <Typography style={{ marginBottom: "40px" }}>
              If this is you, click "Next".
            </Typography>
          )}
          {fetchedMatches.length > 1 && (
            <Typography style={{ marginBottom: "40px" }}>
              Please choose one from above, then click "Next".
            </Typography>
          )}

          <Typography>Not you? You can:</Typography>
          <ul>
            <li>
              <Typography>Enter a different email and check again</Typography>
            </li>
            <li>
              <Typography>
                Choose "I'm new to Bardavon" and enter your info
              </Typography>
            </li>
          </ul>
        </>
      )}
      {hasBeenFetched && fetchedMatches.length === 0 && (
        <>
          <Typography style={{ marginTop: "40px" }}>You can:</Typography>
          <ul>
            <li>
              <Typography>Enter a different email and check again</Typography>
            </li>
            <li>
              <Typography>
                Choose "I'm new to Bardavon" and enter your info
              </Typography>
            </li>
          </ul>
        </>
      )}
    </div>
  );
};

const SearchMatchesField = ({
  fieldName,
  fieldLabel,
  emailValue,
  setEmailValue,
  hasBeenFetched,
  fetchedMatches,
}: {
  fieldName: string;
  fieldLabel: string;
  emailValue: string;
  setEmailValue: (email: string) => void;
  hasBeenFetched: boolean;
  fetchedMatches: CardData[];
}) => {
  return (
    <Field
      required
      component={MagicTextField}
      name={fieldName}
      type="text"
      label={fieldLabel}
      value={emailValue}
      onChange={(e: { target: HTMLInputElement }) =>
        setEmailValue(e.target.value)
      }
      // Manual handling for the error state for this field completely circumventing formik error states
      error={
        !!hasBeenFetched &&
        !fetchedMatches?.length && {
          adjusterEmailSearch: true,
        }
      }
      helperText={
        !!hasBeenFetched &&
        !fetchedMatches?.length &&
        "Sorry, we cant find this in our database"
      }
    />
  );
};

const SelectFoundItem = ({
  matchedObject,
  formik,
  hasOnlyOneResult,
  targetField,
  userType,
}: {
  // matchedObject: AdjusterDTO;
  matchedObject: CardData;
  formik: FormikProps<any>;
  hasOnlyOneResult: boolean;
  targetField: string;
  userType: string | undefined;
}) => {
  const isCardSelected =
    matchedObject?.id === formik.values[targetField]?.id;

  return (
    <button
      className={`selection-found-card`}
      onClick={(e: { preventDefault: () => void }) => {
        e.preventDefault();
        formik.setFieldValue(targetField, matchedObject);
      }}
    >
      {!hasOnlyOneResult && (
        <div
          className={`card-radio-symbol ${isCardSelected ? "selected" : ""}`}
        ></div>
      )}
      <div className="card-contents">
        <Typography style={{ fontWeight: 600 }}>
          {matchedObject.firstName} {matchedObject.lastName}
        </Typography>
        {userType === UserType.ReferringDoctor ? (
          <>
            <Typography style={{ fontWeight: 600 }}>
              NPI: {matchedObject?.npi ? "#" : ""}
              {matchedObject?.npi || "--"}
            </Typography>
            <Typography style={{ fontWeight: 600 }}>
              ph: {matchedObject?.phone || "--"}
            </Typography>
            <Typography style={{ fontWeight: 600 }}>
              fax: {matchedObject?.fax || "--"}
            </Typography>
          </>
        ) : (
          <Typography style={{ fontWeight: 600 }}>
            {matchedObject.company}
          </Typography>
        )}
      </div>
    </button>
  );
};
