import React, { useMemo } from "react";
import { Typography } from "@material-ui/core";
import { NOVALUE } from "../../constants/textConstants";
import {
  parseAndGroupFormData,
  FormValue,
} from "../../helpers/formatters/parseAndGroupFormData";

import classes from "./CondensedReview.module.scss";
import { ReviewFiles, FormType, ReauthFormSchema, ReferralFormSchema, UserFormSchema } from "../../types/FormSchema";

interface CondensedReviewProps {
  allFormData: (ReferralFormSchema | ReauthFormSchema) & UserFormSchema & ReviewFiles
  completedPage?: boolean
  formType?: FormType
}

export const CondensedReview = ({ allFormData, completedPage, formType }: CondensedReviewProps) => {
  const {
    yourInfo,
    referralInfo,
    authorizations,
    reauthorization,
    stakeholders,
    patient,
    other,
  } = useMemo(
    () => parseAndGroupFormData(allFormData, formType),
    [allFormData, formType]
  );

  const showSection = (section: any[]) =>
    section?.some(({ value }: FormValue) => !!value && value !== NOVALUE);

  // We only want to calculate these values once
  const [
    yourInfoShown,
    referralInfoShown,
    authorizationsShown,
    reauthorizationShown,
    stakeholdersShown,
    patientShown,
    otherShown,
  ] = useMemo(() => {
    const showSections = [];
    showSections.push(showSection(yourInfo));
    showSections.push(
      showSection(referralInfo) && formType === FormType.Referral
    );
    showSections.push(
      showSection(authorizations) && formType === FormType.Referral
    );
    showSections.push(
      showSection(reauthorization) && formType === FormType.Reauth
    );
    showSections.push(
      showSection(stakeholders) && formType=== FormType.Referral
    );
    showSections.push(
      showSection(patient) && formType === FormType.Referral
    );
    showSections.push(
      showSection(other) && formType === FormType.Referral
    );
    return showSections;
  }, [
    yourInfo,
    referralInfo,
    formType,
    authorizations,
    reauthorization,
    stakeholders,
    patient,
    other,
  ]);

  const formatSectionData = (
    data: FormValue[],
    headerTitle: string,
    headerType: "sectionHeader" | "subsectionHeader",
    showHeader: boolean
  ) => {
    const variant = headerType === "sectionHeader" ? "h6" : "body1";

    return (
      <>
        {showHeader && (
          <Typography
            variant={variant}
            className={
              headerType === "sectionHeader"
                ? classes.sectionHeader
                : classes.subsection
            }
          >
            {headerTitle}
          </Typography>
        )}
        {data?.map(({ name, value }: FormValue) => {
          return (
            !!value &&
            value !== NOVALUE && (
              <>
                <span className={classes.flexLabel}>{`${name}:`}</span>
                <span className={classes.flexValue}>{value}</span>
              </>
            )
          );
        })}
      </>
    );
  };

  return (
    <div className={classes.condensedReview}>
      <div
        className={
          completedPage
            ? `${classes.completedPage} ${classes.flexContainer}`
            : classes.flexContainer
        }
      >
        <div className={classes.flexHalf}>
          {formatSectionData(
            yourInfo,
            "Your info",
            "sectionHeader",
            yourInfoShown
          )}
          {formatSectionData(
            referralInfo,
            "Referral information",
            "sectionHeader",
            referralInfoShown
          )}
          {formatSectionData(
            reauthorization,
            "Re-authorization",
            "sectionHeader",
            reauthorizationShown
          )}
        </div>
        <div className={classes.flexHalf}>
          {formatSectionData(
            [],
            "Additional info",
            "sectionHeader",
            stakeholdersShown ||
              patientShown ||
              otherShown ||
              authorizationsShown
          )}
          {formatSectionData(
            authorizations,
            "AUTHORIZATIONS",
            "subsectionHeader",
            authorizationsShown
          )}
          {formatSectionData(
            stakeholders,
            "STAKEHOLDERS",
            "subsectionHeader",
            stakeholdersShown
          )}
          {formatSectionData(
            patient,
            "PATIENT",
            "subsectionHeader",
            patientShown
          )}
          {formatSectionData(other, "OTHER", "subsectionHeader", otherShown)}
        </div>
      </div>
    </div>
  );
};
