type GenericResultDTO = {
  id: string;
  firstName: string;
  lastName: string;
  company: string;
  email: string;
  searchText: string;
  phone?: string;
};

//Leaving {} just in case this object needs expanded in the future.
export type AdjusterDTO = {} & GenericResultDTO;

export type CaseManagerDTO = {
  bNotesCaseManagerId: number;
  caseManagerPreferredContact?: string;
  caseManagerFax?: string;
} & GenericResultDTO;

export type PhysicianDTO = {
  bNotesReferringId: number;
  phone?: string;
  npi?: string;
  fax?: string;
} & GenericResultDTO;

type FetchGenericMatchesResponse = {
  data: AdjusterDTO[] | CaseManagerDTO[] | PhysicianDTO[];
};

export type EmployerDTO = {
  id: string;
  name: string;
  searchText: string;
};

export const fetchAdjusterMatches = async (
  inputValue: string,
  signal: AbortSignal
) => {
  return await fetchGenericMatches(
    `${process.env.REACT_APP_REFERRAL_BACKEND}/public/referral/adjusters/${inputValue}`,
    signal
  );
};
export const fetchCaseManagerMatches = async (
  inputValue: string,
  signal: AbortSignal
) => {
  return await fetchGenericMatches(
    `${process.env.REACT_APP_REFERRAL_BACKEND}/public/referral/casemanagers/${inputValue}`,
    signal
  );
};

export const fetchPhysicianMatches = async (
  inputValue: string,
  signal: AbortSignal
) => {
  return await fetchGenericMatches(
    `${process.env.REACT_APP_REFERRAL_BACKEND}/public/referral/physicians/${inputValue}`,
    signal
  );
};

export const fetchReferringDoctorMatches = async (
  lastName: string,
  contactNumber: string,
  contactType: string,
  signal: AbortSignal
) => {
  const urlSuffix =
    contactType === "phone"
      ? `&phone=${contactNumber}`
      : `&fax=${contactNumber}`;
  return await fetchGenericMatches(
    `${process.env.REACT_APP_REFERRAL_BACKEND}/public/referral/physicians?last_name=${lastName}${urlSuffix}`,
    signal
  );
};

const fetchGenericMatches = async (
  endpoint: string,
  signal: AbortSignal
): Promise<FetchGenericMatchesResponse> => {
  return await fetch(endpoint, {
    headers: { accept: "application/json" },
    signal,
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error(JSON.stringify(response.status));
      }
      return response.json();
    })
    .then((data) => {
      return {
        data: data,
      };
    })
    .catch((error) => {
      console.error(`fetchGenericMatches (${endpoint}) error:`, error);
      return Promise.reject();
    });
};

export const fetchEmployerMatches = async (
  value: string,
  signal: AbortSignal
) => {
  return await fetch(
    `${process.env.REACT_APP_REFERRAL_BACKEND}/public/referral/employers/${value}`,
    {
      headers: { accept: "application/json" },
      signal,
    }
  )
    .then((response) => {
      if (!response.ok) {
        throw new Error(JSON.stringify(response.status));
      }
      return response.json();
    })
    .then((data) => {
      return {
        data: data,
      };
    })
    .catch((error) => {
      console.error(`fetchEmployers error:`, error);
      return Promise.reject();
    });
};

export const fetchICD10Matches = async (value: string, signal: AbortSignal) => {
  return await fetch(
    `${process.env.REACT_APP_REFERRAL_BACKEND}/public/referral/ICD_search/${value}`,
    {
      headers: { accept: "application/json" },
      signal,
    }
  )
    .then((response) => {
      if (!response.ok) {
        throw new Error(JSON.stringify(response.status));
      }
      return response.json();
    })
    .then((data) => {
      return {
        data: data,
      };
    })
    .catch((error) => {
      console.log("fetchICD10Matches error:", error);
      return Promise.reject();
    });
};

export const fetchBodyPartMatches = async (
  signal: AbortSignal
) => {
  return await fetch(
    `${process.env.REACT_APP_REFERRAL_BACKEND}/public/referral/bodyparts`,
    {
      headers: { accept: "application/json" },
      signal,
    }
  )
    .then((response) => {
      if (!response.ok) {
        throw new Error(JSON.stringify(response.status));
      }

      return response.json();
    })
    .then((data) => {
      return {
        data: data,
      };
    })
    .catch((error) => {
      console.log("fetchBodyPartMatches error:", error);
      return Promise.reject();
    });
};
