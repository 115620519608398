import { Box, AppBar } from "@material-ui/core";
import { Outlet, useNavigate } from "react-router-dom";
import classes from "./CustomLayout.module.scss";
import bardLogo from "../images/bardavon_logo.png";

export const CustomLayout = () => {
  const navigate = useNavigate();

  return (
    <Box>
      <AppBar position="static" className={classes.appBar} />
      <Box className={classes.container}>
        <Box
          className={classes.logoContainer}
          onClick={() => {
            navigate("/");
          }}
        >
          <Box className={classes.logo}>
            <img
              src={bardLogo}
              alt={"Bardavon Logo"}
              style={{ height: "60px" }}
            ></img>
            {/* <BardLogo /> */}
          </Box>
        </Box>
        <Outlet />
      </Box>
    </Box>
  );
};
