import { useContext, useEffect, useState, MouseEvent } from "react";
import { FormContext } from "../../contexts/FormContext";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Grid,
  Typography,
  Link,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { NOVALUE } from "../../constants/textConstants";
import languageChoices from "../../constants/languageChoices";
import states from "../../constants/states";

import ethnicityChoices from "../../constants/ethnicityChoices";
import patientNameSuffixChoices from "../../constants/suffixChoices";
import raceChoices from "../../constants/raceChoices";
import maritalStatusChoices from "../../constants/maritalChoices";

import styleVars from "../../_export.module.scss";
import DialogContainer from "../dialogs/DialogContainer";
import {
  BodyPart,
  BodyPartsNewOrExisting,
  FileUpload,
  FormType,
  ReauthFormSchema,
  ReferralFormSchema,
} from "../../types/FormSchema";
import {
  formatDate,
  getContactPreference,
  getSurgicalFormattedNames,
  getSelectNameProperty,
  getUserName,
  getUserPhone,
  getUserEmail,
  getCaseType,
  getFormattedPatientGender,
  getTreatingPhysician,
  formatVisitText,
  formatAddress,
  getUserTypeLabel,
} from "../../helpers/formatters/dataFormatters";

import { validateEntireFormInSections } from "../../helpers/validators/validateEntireForm";
import { SectionType } from "../../helpers/validators/validateEntireForm";
import UploadDialog from "../dialogs/UploadDialog";

import { MedicalDiagnosisType } from "../../types/RequiredInfoTypes";

import classes from "./ReviewForm.module.scss";
import common from "../../Common.module.scss";

const ReviewForm = () => {
  const navigate = useNavigate();
  const {
    metadata,
    formStep,
    formData,
    userData,
    files,
    clearFormData,
    setFormStep,
    setFormValid,
    setFormData,
    setFiles,
    setClearFormData,
  } = useContext(FormContext);

  const [formErrors, setFormErrors] = useState<{
    errors: Record<string, string>;
    sections: string[];
  }>({ errors: {}, sections: [] });

  useEffect(() => {
    if (clearFormData) {
      setFormData({});
      setFiles([]);
      setClearFormData(false);
    }
  }, [clearFormData, setFormData, setClearFormData, setFiles]);

  useEffect(() => {
    validateEntireFormInSections(
      { ...userData, ...formData, fileUploads: files },
      metadata.formType!
    ).then((errors) => {
      if (!!Object.keys(errors?.errors).length) setFormValid(false);
      else setFormValid(true);
      setFormErrors(errors);
    });
  }, [formData, setFormValid, userData, metadata.formType, files]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const currentStep = metadata.formType === FormType.Referral ? 4 : 2;
    if (formStep < currentStep) {
      setFormStep(currentStep);
    }
  }, [formStep, metadata.formType, setFormStep]);

  return (
    <div className={classes.reviewForm}>
      <Grid item xs={12}>
        <Box width="925px"></Box>
      </Grid>
      <Grid item xs={12}>
        <Typography
          style={{
            color: styleVars.colorThemeBlue,
            fontSize: styleVars.fontSize4XL,
          }}
        >
          {metadata.formType === FormType.Referral
            ? "Please review your referral"
            : "Please review your request"}
        </Typography>
      </Grid>
      <Grid container spacing={2}>
        <Box height="28px"></Box>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body1" className={common.fontNormalSecondary}>
          {metadata.formType === FormType.Referral
            ? 'Please review your referral below. If everything looks good, click "Submit." If you need to change something, click the "Edit" link'
            : 'If everything looks good, click "Submit."  If you need to change something, click the "Edit" link.  You can print or save this next.'}
        </Typography>
      </Grid>
      <Grid container spacing={2}>
        <Box height="30px"></Box>
      </Grid>
      <Grid item xs={10}>
        <Box className={classes.divider} height="1px"></Box>
      </Grid>
      <Grid container spacing={2}>
        <Box height="28px"></Box>
      </Grid>
      {
        //#region User Info
      }
      <Grid item xs={3}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell size="small" className={classes.leftCell}>
                <div className={classes.forceMaxWidth}>
                  <Typography
                    style={{
                      color: formErrors?.sections.includes(SectionType.User)
                        ? styleVars.colorError
                        : styleVars.colorGreen,
                      fontSize: styleVars.fontSize2XL,
                      fontWeight: 500,
                    }}
                  >
                    Your info
                  </Typography>
                </div>
              </TableCell>
              <TableCell size="small" className={classes.cell}>
                <Link
                  component="button"
                  variant="body2"
                  underline="none"
                  onClick={(e: MouseEvent) => {
                    e.preventDefault();
                    navigate("/referral/user");
                  }}
                >
                  Edit
                </Link>
                {formErrors?.sections.includes(SectionType.User) && (
                  <Typography
                    component="span"
                    variant="body2"
                    className={classes.missingInfoText}
                  >
                    {"  "}(Info Missing)
                  </Typography>
                )}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell size="small" className={classes.leftCell}>
                Role:
              </TableCell>
              {/* We are going to have to clean up some of the select values such as this */}
              <TableCell size="small" className={classes.cell}>
                {getUserTypeLabel(userData?.userType) || NOVALUE}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell size="small" className={classes.leftCell}>
                Name:
              </TableCell>
              <TableCell size="small" className={classes.cell}>
                {getUserName(userData)}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell size="small" className={classes.leftCell}>
                Email:
              </TableCell>
              <TableCell size="small" className={classes.cell}>
                {getUserEmail(userData)}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell size="small" className={classes.leftCell}>
                Preferred contact:
              </TableCell>
              <TableCell size="small" className={classes.cell}>
                {getContactPreference(
                  userData?.userPhonePreferred,
                  userData?.userEmailPreferred
                ) ?? NOVALUE}
              </TableCell>
            </TableRow>
            {/* Additional phone is allowed so conditional showing necessary */}
            <TableRow>
              <TableCell size="small" className={classes.leftCell}>
                Phone:
              </TableCell>
              <TableCell size="small" className={classes.cell}>
                {getUserPhone(userData)}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell size="small" className={classes.leftCell}>
                Attachments:
              </TableCell>
              <TableCell size="small" className={classes.cell}>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  {
                    <DialogContainer
                      hideCloseButton
                      // classes=""
                      dialogTrigger={({ toggleDialog }) =>
                        files.length ? (
                          <>
                            {files.map((f: FileUpload, i: number) => {
                              return (
                                <Box display="inline-flex">
                                  <Link
                                    component="button"
                                    underline="none"
                                    style={{
                                      fontSize: styleVars.fontSizeNormal,
                                    }}
                                    onClick={(e: MouseEvent) => {
                                      e.preventDefault();
                                      toggleDialog();
                                    }}
                                  >
                                    {f?.name}
                                  </Link>
                                  <Typography
                                    key={`${f?.name}${i}`}
                                    style={{
                                      fontSize: styleVars.fontSizeNormal,
                                      marginLeft: styleVars.spacing,
                                    }}
                                  >
                                    ({f?.category})
                                    {Number(files.length) > 0 &&
                                      i !== Number(files.length) - 1 && (
                                        <span className={classes.fileSeparator}>|</span>
                                      )}
                                  </Typography>
                                </Box>
                              );
                            })}
                          </>
                        ) : (
                          <Link
                            component="button"
                            variant="body1"
                            underline="none"
                            style={{ fontSize: styleVars.fontSizeNormal }}
                            onClick={(e: MouseEvent) => {
                              e.preventDefault();
                              toggleDialog();
                            }}
                          >
                            Upload documents
                          </Link>
                        )
                      }
                      dialogContent={({ toggleDialog }) => (
                        <UploadDialog toggleDialog={toggleDialog} />
                      )}
                    />
                  }
                  {formErrors.sections.includes(SectionType.Files) ? (
                    <Typography
                      style={{
                        color: styleVars.colorError,
                        fontSize: styleVars.fontSizeNormal,
                        marginLeft: styleVars.spacing,
                      }}
                    >
                      *Required
                    </Typography>
                  ) : null}
                </Box>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Grid>
      <Grid item xs={9}></Grid>
      <Grid container spacing={2}>
        <Box height="40px"></Box>
      </Grid>
      {
        //#endregion
      }
      {
        //#region Referral Info
      }
      {metadata.formType === FormType.Referral ? (
        <>
          <Grid item xs={3}>
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell size="small" className={classes.cell}>
                    <div className={classes.forceMaxWidth}>
                      <Typography
                        style={{
                          color: formErrors?.sections.includes(
                            SectionType.Required
                          )
                            ? styleVars.colorError
                            : styleVars.colorGreen,
                          fontSize: styleVars.fontSize2XL,
                          fontWeight: 500,
                        }}
                      >
                        Referral information
                      </Typography>
                    </div>
                  </TableCell>
                  <TableCell size="small" className={classes.cell}>
                    <Link
                      component="button"
                      variant="body2"
                      underline="none"
                      onClick={(e: MouseEvent) => {
                        e.preventDefault();
                        navigate("/referral/requiredInfo");
                      }}
                    >
                      Edit
                    </Link>
                    {formErrors?.sections.includes(SectionType.Required) && (
                      <Typography
                        component="span"
                        variant="body2"
                        className={classes.missingInfoText}
                      >
                        {"  "}(Info Missing)
                      </Typography>
                    )}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell size="small" className={classes.leftCell}>
                    Patient name:
                  </TableCell>
                  <TableCell size="small" className={classes.cell}>
                    {formData?.patientFirstName && formData?.patientLastName
                      ? `${formData?.patientFirstName} ${formData?.patientLastName}`
                      : NOVALUE}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell size="small" className={classes.leftCell}>
                    Birthday:
                  </TableCell>
                  <TableCell size="small" className={classes.cell}>
                    {formatDate(formData?.patientBirthday)}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell size="small" className={classes.leftCell}>
                    Gender:
                  </TableCell>
                  <TableCell size="small" className={classes.cell}>
                    {getFormattedPatientGender(
                      (formData as ReferralFormSchema)?.patientSex
                    ) || NOVALUE}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell size="small" className={classes.leftCell}>
                    Phone number:
                  </TableCell>
                  <TableCell size="small" className={classes.cell}>
                    {(formData as ReferralFormSchema)?.patientPhoneNumber ||
                      NOVALUE}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell size="small" className={classes.leftCell}>
                    Primary language:
                  </TableCell>
                  <TableCell size="small" className={classes.cell}>
                    {getSelectNameProperty(
                      (formData as ReferralFormSchema)?.patientPrimaryLanguage,
                      languageChoices
                    ) || NOVALUE}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell size="small" className={classes.leftCell}>
                    Interpreter authorized:
                  </TableCell>
                  <TableCell size="small" className={classes.cell}>
                    {(formData as ReferralFormSchema)?.interpreterAuthorized
                      ? "Yes"
                      : "No"}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell size="small" className={classes.leftCell}>
                    Transportation authorized:
                  </TableCell>
                  <TableCell size="small" className={classes.cell}>
                    {(formData as ReferralFormSchema)?.transportationAuthorized
                      ? "Yes"
                      : "No"}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell size="small" className={classes.leftCell}>
                    Claim number:
                  </TableCell>
                  <TableCell size="small" className={classes.cell}>
                    {formData?.claimNumber || NOVALUE}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell size="small" className={classes.leftCell}>
                    State of jurisdiction:
                  </TableCell>
                  <TableCell size="small" className={classes.cell}>
                    {getSelectNameProperty(
                      (formData as ReferralFormSchema)?.stateOfJury,
                      states
                    ) || NOVALUE}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell size="small" className={classes.leftCell}>
                    Injury date:
                  </TableCell>
                  <TableCell size="small" className={classes.cell}>
                    {formatDate((formData as ReferralFormSchema)?.dateOfInjury)}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell size="small" className={classes.leftCell}>
                    Treating physician:
                  </TableCell>
                  <TableCell size="small" className={classes.cell}>
                    {getTreatingPhysician(formData) || NOVALUE}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell size="small" className={classes.leftCell}>
                    Case type:
                  </TableCell>
                  <TableCell size="small" className={classes.cell}>
                    {getCaseType((formData as ReferralFormSchema)?.caseType) ||
                      NOVALUE}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell size="small" className={classes.leftCell}>
                    Specialty:
                  </TableCell>
                  <TableCell size="small" className={classes.cell}>
                    {(formData as ReferralFormSchema)?.specialty || NOVALUE}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell size="small" className={classes.leftCell}>
                    Diagnosis/ICD-10:
                  </TableCell>
                  <TableCell size="small" className={classes.cell}>
                    {(formData as ReferralFormSchema)?.medicalDiagnosis?.length
                      ? (formData as ReferralFormSchema)?.medicalDiagnosis?.map(
                          (m: MedicalDiagnosisType, i: number) => {
                            return <div key={m.combined}>{m?.combined}</div>;
                          }
                        )
                      : NOVALUE}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell size="small" className={classes.leftCell}>
                    Body part:
                  </TableCell>
                  <TableCell size="small" className={classes.cell}>
                    {(formData as ReferralFormSchema)?.bodyPart?.length
                      ? (formData as ReferralFormSchema)?.bodyPart?.map(
                          (b: BodyPart, i: number) => {
                            return (
                              <div key={b.BodyPartName}>{b?.BodyPartName}</div>
                            );
                          }
                        )
                      : NOVALUE}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell size="small" className={classes.leftCell}>
                    Surgical:
                  </TableCell>
                  <TableCell size="small" className={classes.cell}>
                    {getSurgicalFormattedNames(
                      (formData as ReferralFormSchema)?.surgical
                    )}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell size="small" className={classes.leftCell}>
                    Surgery date:
                  </TableCell>
                  <TableCell size="small" className={classes.cell}>
                    {formatDate(
                      (formData as ReferralFormSchema)?.dateOfSurgery
                    )}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell size="small" className={classes.leftCell}>
                    Employer:
                  </TableCell>
                  <TableCell size="small" className={classes.cell}>
                    {(formData as ReferralFormSchema)?.employerSelected
                      ?.label ||
                      (formData as ReferralFormSchema)?.employerCompany ||
                      NOVALUE}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell size="small" className={classes.leftCell}>
                    Job title:
                  </TableCell>
                  <TableCell size="small" className={classes.cell}>
                    {(formData as ReferralFormSchema)?.jobTitle || NOVALUE}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Grid>
          <Grid container spacing={2}>
            <Box height="40px"></Box>
          </Grid>
        </>
      ) : null}
      {
        //#endregion
      }
      {
        //#region Additional Info
      }
      <Grid item xs={12} style={{ paddingLeft: "15px" }}>
        <Box display="flex" alignItems="center">
          <Typography
            style={{
              color:
                formErrors?.sections.includes(SectionType.Additional) ||
                formErrors?.sections.includes(SectionType.Authorization) ||
                formErrors?.sections.includes(SectionType.Reauthorization)
                  ? styleVars.colorError
                  : styleVars.colorGreen,
              fontSize: styleVars.fontSize2XL,
              fontWeight: 500,
              width: "210px",
            }}
          >
            {metadata.formType === FormType.Referral
              ? "Additional info"
              : "Re-authorization"}
          </Typography>
          {metadata.formType === FormType.Reauth ? (
            <Link
              component="button"
              variant="body2"
              underline="none"
              onClick={(e: MouseEvent) => {
                e.preventDefault();
                navigate(`/${metadata.formType}/authorization`);
              }}
            >
              Edit{" "}
            </Link>
          ) : null}
          <Typography
            component="span"
            variant="body2"
            className={`${classes.missingInfoText} ${
              !formErrors?.sections.includes(SectionType.Reauthorization)
                ? common.hidden
                : ""
            }`}
          >
            {"  "}(Info Missing)
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={3}>
        <Grid container spacing={2}>
          <Box height="24px"></Box>
        </Grid>
        <Table className={classes.table}>
          <TableHead>
            {metadata.formType === FormType.Referral ? (
              <TableRow>
                <TableCell size="small" className={classes.leftCell}>
                  <div className={classes.forceMaxWidth}>
                    <Typography
                      variant="body1"
                      style={{
                        color: formErrors?.sections.includes(
                          SectionType.Authorization
                        )
                          ? styleVars.colorError
                          : styleVars.colorGreen,
                        fontSize: styleVars.fontSizeNormal,
                        fontWeight: 500,
                      }}
                    >
                      AUTHORIZATIONS
                    </Typography>
                  </div>
                </TableCell>
                <TableCell size="small" className={classes.cell}>
                  <Link
                    component="button"
                    variant="body2"
                    underline="none"
                    onClick={(e: MouseEvent) => {
                      e.preventDefault();
                      navigate(`/${metadata.formType}/authorization`);
                    }}
                  >
                    Edit{" "}
                  </Link>
                  {formErrors?.sections.includes(
                    metadata.formType === FormType.Referral
                      ? SectionType.Authorization
                      : SectionType.Reauthorization
                  ) && (
                    <Typography
                      component="span"
                      variant="body2"
                      className={classes.missingInfoText}
                    >
                      {"  "}(Info Missing)
                    </Typography>
                  )}
                </TableCell>
              </TableRow>
            ) : (
              <TableRow>
                <TableCell className={classes.emptyHeader}>
                  <div className={classes.forceMaxWidth} />
                </TableCell>
              </TableRow>
            )}
          </TableHead>
          <TableBody>
            {metadata.formType === FormType.Reauth ? (
              <TableRow>
                <TableCell size="small" className={classes.leftCell}>
                  Patient name:
                </TableCell>
                <TableCell size="small" className={classes.cell}>
                  {formData?.patientFirstName && formData?.patientLastName
                    ? `${formData?.patientFirstName} ${formData?.patientLastName}`
                    : NOVALUE}
                </TableCell>
              </TableRow>
            ) : null}
            {metadata.formType === FormType.Reauth ? (
              <TableRow>
                <TableCell size="small" className={classes.leftCell}>
                  Birthday
                </TableCell>
                <TableCell size="small" className={classes.cell}>
                  {formatDate(formData?.patientBirthday)}
                </TableCell>
              </TableRow>
            ) : null}

            {metadata.formType === FormType.Reauth ? (
              <TableRow>
                <TableCell size="small" className={classes.leftCell}>
                  Claim number:
                </TableCell>
                <TableCell size="small" className={classes.cell}>
                  {formData?.claimNumber || NOVALUE}
                </TableCell>
              </TableRow>
            ) : null}
            <TableRow>
              {/* Parsing to simplified dates necessary */}
              <TableCell size="small" className={classes.leftCell}>
                Script date:
              </TableCell>
              <TableCell size="small" className={classes.cell}>
                {formatDate(formData?.authScriptDate)}
              </TableCell>
            </TableRow>
            {metadata.formType === FormType.Referral ? (
              <TableRow>
                {/* Parsing to simplified dates necessary */}
                <TableCell size="small" className={classes.leftCell}>
                  Script expiration date:
                </TableCell>
                <TableCell size="small" className={classes.cell}>
                  {formatDate(
                    (formData as ReferralFormSchema)?.authScriptEndDate
                  )}
                </TableCell>
              </TableRow>
            ) : null}
            <TableRow>
              <TableCell size="small" className={classes.leftCell}>
                Authorized visits:
              </TableCell>
              <TableCell size="small" className={classes.cell}>
                {formatVisitText(
                  formData?.authAuthorizedVisits,
                  formData?.authVisitTimes,
                  formData?.authVisitWeeks
                )}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell size="small" className={classes.leftCell}>
                Authorized by:
              </TableCell>
              <TableCell size="small" className={classes.cell}>
                {formData?.authAuthorizedBy || NOVALUE}
              </TableCell>
            </TableRow>
            {metadata.formType === FormType.Referral ? (
              <TableRow>
                <TableCell size="small" className={classes.leftCell}>
                  Authorization number:
                </TableCell>
                <TableCell size="small" className={classes.cell}>
                  {(formData as ReferralFormSchema)?.authAuthorizationNumber ||
                    NOVALUE}
                </TableCell>
              </TableRow>
            ) : null}
            {metadata.formType === FormType.Referral ? (
              <TableRow>
                <TableCell size="small" className={classes.leftCell}>
                  Add'l injury date:
                </TableCell>
                <TableCell size="small" className={classes.cell}>
                  {formatDate(
                    (formData as ReferralFormSchema)?.authAdditionalInjuryDate
                  ) || NOVALUE}
                </TableCell>
              </TableRow>
            ) : null}
            {metadata.formType === FormType.Referral ? (
              <TableRow>
                <TableCell size="small" className={classes.leftCell}>
                  Return to Dr. date:
                </TableCell>
                <TableCell size="small" className={classes.cell}>
                  {formatDate(
                    (formData as ReferralFormSchema)?.authReturnToDoctorDate
                  )}
                </TableCell>
              </TableRow>
            ) : null}
            {metadata.formType === FormType.Reauth ? (
              <TableRow>
                <TableCell size="small" className={classes.leftCell}>
                  New body part(s):
                </TableCell>
                <TableCell size="small" className={classes.cell}>
                  {(formData as ReauthFormSchema)?.useNewOrExistingBodyParts ===
                  BodyPartsNewOrExisting.New
                    ? "Yes"
                    : "No"}
                </TableCell>
              </TableRow>
            ) : null}
            {metadata.formType === FormType.Reauth ? (
              <TableRow>
                <TableCell size="small" className={classes.leftCell}>
                  Adding body parts:
                </TableCell>
                <TableCell size="small" className={classes.cell}>
                  {(formData as ReauthFormSchema)?.reauthBodyParts
                    ?.map((bodyPart) => bodyPart.label)
                    .join(",")}
                </TableCell>
              </TableRow>
            ) : null}
          </TableBody>
        </Table>
      </Grid>
      {
        //#endregion
      }
      {metadata.formType === FormType.Referral ? (
        <>
          <Grid item xs={9}></Grid>
          <Grid item xs={3}>
            <Grid container spacing={2}>
              <Box height="24px"></Box>
            </Grid>
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell size="small" className={classes.cell}>
                    <div className={classes.forceMaxWidth}>
                      <Typography
                        variant="body1"
                        style={{
                          color: formErrors?.sections.includes(
                            SectionType.Stakeholder
                          )
                            ? styleVars.colorError
                            : styleVars.colorGreen,
                          fontSize: styleVars.fontSizeNormal,
                          fontWeight: 500,
                        }}
                      >
                        STAKEHOLDERS
                      </Typography>
                    </div>
                  </TableCell>
                  <TableCell size="small" className={classes.cell}>
                    <Link
                      component="button"
                      variant="body2"
                      underline="none"
                      onClick={(e: MouseEvent) => {
                        e.preventDefault();
                        navigate("/referral/additionalInfo");
                      }}
                    >
                      Edit
                    </Link>
                    {formErrors?.sections.includes(SectionType.Stakeholder) && (
                      <Typography
                        component="span"
                        variant="body2"
                        className={classes.missingInfoText}
                      >
                        {"  "}(Info Missing)
                      </Typography>
                    )}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell size="small" className={classes.leftCell}>
                    Adjuster:
                  </TableCell>
                  <TableCell size="small" className={classes.cell}>
                    {!!(formData as ReferralFormSchema)?.selectAdjuster
                      ? (formData as ReferralFormSchema)?.selectAdjuster
                          ?.label || NOVALUE
                      : (formData as ReferralFormSchema)?.adjusterFirstName &&
                        (formData as ReferralFormSchema)?.adjusterLastName &&
                        (formData as ReferralFormSchema).adjusterCompany
                      ? `${
                          (formData as ReferralFormSchema)?.adjusterFirstName ||
                          ""
                        } ${
                          (formData as ReferralFormSchema)?.adjusterLastName ||
                          ""
                        } - ${
                          (formData as ReferralFormSchema)?.adjusterCompany ||
                          ""
                        }`
                      : NOVALUE}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell size="small" className={classes.leftCell}>
                    Nurse/Case manager:
                  </TableCell>
                  <TableCell size="small" className={classes.cell}>
                    {!!(formData as ReferralFormSchema)?.selectCaseManager
                      ? (formData as ReferralFormSchema)?.selectCaseManager
                          ?.label || NOVALUE
                      : (formData as ReferralFormSchema)
                          ?.caseManagerFirstName &&
                        (formData as ReferralFormSchema)?.caseManagerLastName &&
                        (formData as ReferralFormSchema).caseManagerCompany
                      ? `${
                          (formData as ReferralFormSchema)
                            ?.caseManagerFirstName || ""
                        } ${
                          (formData as ReferralFormSchema)
                            ?.caseManagerLastName || ""
                        } - ${
                          (formData as ReferralFormSchema)
                            ?.caseManagerCompany || ""
                        }`
                      : NOVALUE}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Grid>
        </>
      ) : null}
      {metadata.formType === FormType.Referral ? (
        <>
          <Grid item xs={9}></Grid>
          <Grid item xs={3}>
            <Grid container spacing={2}>
              <Box height="24px"></Box>
            </Grid>
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell size="small" className={classes.cell}>
                    <div className={classes.forceMaxWidth}>
                      <Typography
                        variant="body1"
                        style={{
                          color: formErrors?.sections.includes(
                            SectionType.PatientOptional
                          )
                            ? styleVars.colorError
                            : styleVars.colorGreen,
                          fontSize: styleVars.fontSizeNormal,
                          fontWeight: 500,
                        }}
                      >
                        PATIENT
                      </Typography>
                    </div>
                  </TableCell>
                  <TableCell size="small" className={classes.cell}>
                    <Link
                      component="button"
                      variant="body2"
                      underline="none"
                      onClick={(e: MouseEvent) => {
                        e.preventDefault();
                        navigate("/referral/additionalInfo");
                      }}
                    >
                      Edit
                    </Link>
                    {formErrors?.sections.includes(
                      SectionType.PatientOptional
                    ) && (
                      <Typography
                        component="span"
                        variant="body2"
                        className={classes.missingInfoText}
                      >
                        {"  "}(Info Missing)
                      </Typography>
                    )}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell size="small" className={classes.leftCell}>
                    Preferred name:
                  </TableCell>
                  <TableCell size="small" className={classes.cell}>
                    {(formData as ReferralFormSchema)?.patientPreferredName ||
                      NOVALUE}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell size="small" className={classes.leftCell}>
                    Middle name:
                  </TableCell>
                  <TableCell size="small" className={classes.cell}>
                    {(formData as ReferralFormSchema)?.patientMiddleName ||
                      NOVALUE}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell size="small" className={classes.leftCell}>
                    Suffix:
                  </TableCell>
                  <TableCell size="small" className={classes.cell}>
                    {getSelectNameProperty(
                      (formData as ReferralFormSchema)?.patientSuffix,
                      patientNameSuffixChoices
                    ) || NOVALUE}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell size="small" className={classes.leftCell}>
                    Address:
                  </TableCell>
                  <TableCell size="small" className={classes.cell}>
                    {formatAddress(
                      (formData as ReferralFormSchema)?.patientAddress,
                      (formData as ReferralFormSchema)?.patientCity,
                      (formData as ReferralFormSchema)?.patientState,
                      (formData as ReferralFormSchema)?.patientZip
                    )}
                    {/* {formData?.patientAddress || NOVALUE} */}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell size="small" className={classes.leftCell}>
                    Marital status:
                  </TableCell>
                  <TableCell size="small" className={classes.cell}>
                    {getSelectNameProperty(
                      (formData as ReferralFormSchema)?.patientMaritalStatus,
                      maritalStatusChoices
                    ) || NOVALUE}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell size="small" className={classes.leftCell}>
                    Race:
                  </TableCell>
                  <TableCell size="small" className={classes.cell}>
                    {getSelectNameProperty(
                      (formData as ReferralFormSchema)?.patientRace,
                      raceChoices
                    ) || NOVALUE}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell size="small" className={classes.leftCell}>
                    Ethnicity:
                  </TableCell>
                  <TableCell size="small" className={classes.cell}>
                    {getSelectNameProperty(
                      (formData as ReferralFormSchema)?.patientEthnicity,
                      ethnicityChoices
                    ) || NOVALUE}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell size="small" className={classes.leftCell}>
                    Emergency contact:
                  </TableCell>
                  <TableCell size="small" className={classes.cell}>
                    {(formData as ReferralFormSchema)
                      ?.patientEmergencyContactName || NOVALUE}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell size="small" className={classes.leftCell}>
                    Relationship:
                  </TableCell>
                  <TableCell size="small" className={classes.cell}>
                    {(formData as ReferralFormSchema)
                      ?.patientEmergencyContactRelationship || NOVALUE}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell size="small" className={classes.leftCell}>
                    Phone:
                  </TableCell>
                  <TableCell size="small" className={classes.cell}>
                    {(formData as ReferralFormSchema)
                      ?.patientEmergencyContactPhoneNumber || NOVALUE}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Grid>
        </>
      ) : null}
      {metadata.formType === FormType.Referral ? (
        <>
          <Grid item xs={9}></Grid>
          <Grid item xs={3}>
            <Grid container spacing={2}>
              <Box height="40px"></Box>
            </Grid>
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell size="small" className={classes.cell}>
                    <div className={classes.forceMaxWidth}>
                      <Typography
                        style={{
                          color: formErrors?.sections.includes("additionalInfo")
                            ? styleVars.colorError
                            : styleVars.colorGreen,
                          fontSize: styleVars.fontSizeNormal,
                          fontWeight: 500,
                        }}
                      >
                        OTHER
                      </Typography>
                    </div>
                  </TableCell>
                  <TableCell size="small" className={classes.cell}>
                    <Link
                      component="button"
                      variant="body2"
                      underline="none"
                      onClick={(e: MouseEvent) => {
                        e.preventDefault();
                        navigate("/referral/additionalInfo");
                      }}
                    >
                      Edit
                    </Link>
                    {formErrors?.sections.includes("additionalInfo") && (
                      <Typography
                        component="span"
                        variant="body2"
                        className={classes.missingInfoText}
                      >
                        {"  "}(Info Missing)
                      </Typography>
                    )}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell size="small" className={classes.leftCell}>
                    Preferred clinic:
                  </TableCell>
                  <TableCell size="small" className={classes.cell}>
                    {(formData as ReferralFormSchema)?.patientPreferredClinic ||
                      NOVALUE}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell size="small" className={classes.leftCell}>
                    Preferred therapist:
                  </TableCell>
                  <TableCell size="small" className={classes.cell}>
                    {(formData as ReferralFormSchema)
                      ?.patientPreferredClinician || NOVALUE}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell size="small" className={classes.leftCell}>
                    Other info/notes:
                  </TableCell>
                  <TableCell size="small" className={classes.cell}>
                    {(formData as ReferralFormSchema)?.patientOtherInfo ||
                      NOVALUE}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Grid>
        </>
      ) : null}
    </div>
  );
};

export default ReviewForm;
