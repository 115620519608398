import moment from "moment";
import * as Yup from "yup";
import { ERROR_REQUIRED_TXT } from "../constants/textConstants";
import differenceInMonths from "date-fns/differenceInMonths";
import { BodyPartsNewOrExisting } from "../types/FormSchema";

const validationSchema = Yup.object().shape({
  patientFirstName: Yup.string()
    .max(50, "First name cannot exceed 50 characters")
    .required(ERROR_REQUIRED_TXT),
  patientLastName: Yup.string()
    .max(50, "Last name cannot exceed 50 characters")
    .required(ERROR_REQUIRED_TXT),
  patientBirthday: Yup.string()
    .nullable()
    .required(ERROR_REQUIRED_TXT)
    .test(
      "patientBirthday",
      "Should be greater than 13 years old",
      function (value) {
        return (
          differenceInMonths(
            new Date(),
            new Date(moment(value).format("MMM DD, YYYY HH:MM"))
          ) >= 156
        );
      }
    ),

  authAuthorizedVisits: Yup.number()
    .transform((curr, orig) => (!!orig ? curr : null))
    .nullable()
    .typeError("You must enter a numeric value")
    .min(0, "The value must be non-negative")
    .max(99, "The value must be 99 or less"),
  authVisitTimes: Yup.number()
    .transform((curr, orig) => (!!orig ? curr : null))
    .nullable()
    .test(
      "authVisit",
      ERROR_REQUIRED_TXT,
      function (value: number | null | undefined) {
        const { authVisitWeeks } = this.parent;
        return (
          (value === undefined && authVisitWeeks === undefined) ||
          (value !== undefined && authVisitWeeks !== undefined)
        );
      }
    )
    .typeError("You must enter a numeric value")
    .min(0, "The value must be non-negative")
    .max(7, "The value must be 7 or less"),

  authVisitWeeks: Yup.number()
    .transform((curr, orig) => (!!orig ? curr : null))
    .nullable()
    .test("authVisit", ERROR_REQUIRED_TXT, function (value) {
      const { authVisitTimes } = this.parent;
      return (
        (value === undefined && authVisitTimes === undefined) ||
        (value !== undefined && authVisitTimes !== undefined)
      );
    })
    .typeError("You must enter a numeric value")
    .min(0, "The value must be non-negative")
    .max(99, "The value must be 99 or less"),

  authAuthorizedBy: Yup.string()
    .required(ERROR_REQUIRED_TXT)
    .nullable()
    .test(
      "auth",
      "Authorization number or Authorized by must be provided",
      function (value: string | null | undefined) {
        const { authAuthorizationNumber, authAuthorizedVisits } = this.parent;
        return authAuthorizedVisits ? value || authAuthorizationNumber : true;
      }
    ),

  authScriptDate: Yup.string().nullable(),
  claimNumber: Yup.string()
    .required(ERROR_REQUIRED_TXT)
    .matches(
      /^[a-zA-Z0-9-]+$/,
      "Claim number must only include letters, numbers, and hyphens"
    ),

  useNewOrExistingBodyParts: Yup.string()
    .nullable()
    .required(ERROR_REQUIRED_TXT),
  reauthBodyParts: Yup.array()
    .max(10, "Body parts cannot exceed 10 selections")
    .nullable()
    .test("min", "At least one body part is required.", function (value: any) {
      const { useNewOrExistingBodyParts } = this.parent;
      if (
        !useNewOrExistingBodyParts ||
        useNewOrExistingBodyParts === BodyPartsNewOrExisting.Existing
      )
        return true;
      return value && value.length > 0;
    }),
});

export default validationSchema;
