import React from "react";
import {
  useContext,
  useState,
  useCallback,
  useEffect,
  useMemo,
  MouseEvent,
} from "react";
import { FormContext } from "../../contexts/FormContext";
import {
  Button,
  Box,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  MenuItem,
  TextField,
} from "@material-ui/core";
import Icon from "react-icons-kit";
import { ic_cloud_upload as icCloudUpload } from "react-icons-kit/md";
import { ic_file_upload as icFileUpload } from "react-icons-kit/md";
import { ic_delete as icDelete } from "react-icons-kit/md";
import { ic_assignment_turned_in as icAssignmentTurnedIn } from "react-icons-kit/md";
import { ic_error as icError } from "react-icons-kit/md";
import { FileUpload, FormType, UploadFileType } from "../../types/FormSchema";
import { useDropzone } from "react-dropzone";
import styleVars from "../../_export.module.scss";
import "./UploadDialog.scss";
import { sendErrorNotification } from "../../api/notification";
import { uploadFile } from "../../api/form";

type UploadDialogProps = {
  toggleDialog: () => void;
};

enum FileUploadStatus {
  None,
  Uploading,
  Success,
  Failure,
}

function notifyFileSizeError() {
  sendErrorNotification("Referrer attempted to upload file larger than 5MB");
}

const INVALID_FILE_TYPE = "InvalidFileType";
const FILE_TOO_LARGE = "FileTooLarge";
const REFERRAL_FILE_TYPES = [
  "Certified letter",
  "Doctor's note(s)",
  "First report of injury",
  "Job description",
  "Prescription",
  "Signed Plan of Care",
  "Other",
];
const REAUTH_FILE_TYPES = [
  "Certified letter",
  "Prescription",
  "Request for Authorization",
];

const UploadDialog: React.FC<UploadDialogProps> = ({ toggleDialog }) => {
  const { files, metadata, setFiles } = useContext(FormContext);

  const [currentFile, setCurrentFile] = useState<
    (FileUpload & UploadFileType & Blob) | null | undefined
  >(undefined);
  const [documentCategory, setDocumentCategory] = useState<string>("");
  const [fileUploadStatus, setFileUploadStatus] = useState(
    FileUploadStatus.None
  );
  const [fileUploadError, setFileUploadError] = useState<string | null>(null);

  const [isAttemptingDeleteFile, setIsAttemptingDeleteFile] = useState(false);
  const [attemptDeleteFileIndex, setAttemptDeleteFileIndex] = useState<
    number | null
  >(null);

  useEffect(() => {
    setDocumentCategory("");
    setCurrentFile(null);
    setFileUploadStatus(FileUploadStatus.None);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const resetSelectField = () => {
    setDocumentCategory(""); // or whatever the initial state should be
  };
  const resetAttemptDeleteFile = () => {
    setAttemptDeleteFileIndex(null);
    setIsAttemptingDeleteFile(false);
  };

  const onDrop = useCallback(
    (acceptedFiles, rejectedFiles) => {
      setFileUploadError(null);

      if (acceptedFiles && acceptedFiles.length > 0) {
        const file: UploadFileType = acceptedFiles[0];

        setCurrentFile({
          name: file.name,
          category: documentCategory,
          size: file.size,
          content: true,
          file: file,
          fileId: null,
        } as FileUpload & UploadFileType & Blob);
      } else if (rejectedFiles && rejectedFiles.length > 0) {
        const rejectedFileDetails = rejectedFiles[0];
        console.log(rejectedFileDetails);

        if (rejectedFileDetails.errors[0].code === "file-invalid-type") {
          setFileUploadError(INVALID_FILE_TYPE);
        } else if (rejectedFileDetails.errors[0].code === "file-too-large") {
          setFileUploadError(FILE_TOO_LARGE);
          notifyFileSizeError();
        }
      }
    },
    [documentCategory]
  );

  const updateFormOnClose = (e: MouseEvent) => {
    e.preventDefault();
    toggleDialog();
  };
  const onCancelClick = (e: MouseEvent) => {
    e.preventDefault();
    toggleDialog();
  };
  const onDeleteStagedFileClick = () => {
    setDocumentCategory("");
    setCurrentFile(null);
  };
  const onAttemptDeleteFileClick = (i: number) => {
    if (isAttemptingDeleteFile) return;
    setAttemptDeleteFileIndex(i);
    setIsAttemptingDeleteFile(true);
  };
  const onConfirmDeleteFileClick = (e: MouseEvent) => {
    e.preventDefault();
    if (files.length === 1) {
      setFiles([]);
      resetAttemptDeleteFile();
      return;
    }
    if (attemptDeleteFileIndex === null) {
      resetAttemptDeleteFile();
      return;
    }
    const uploadFilesCopy = files.slice();
    uploadFilesCopy.splice(attemptDeleteFileIndex, 1);
    setFiles(uploadFilesCopy);
    resetAttemptDeleteFile();
  };
  const onCancelDeleteFileClick = (e: MouseEvent) => {
    e.preventDefault();
    resetAttemptDeleteFile();
  };

  const { getRootProps, getInputProps, open } = useDropzone({
    onDrop: onDrop,
    maxFiles: 1,
    maxSize: 5000000,
    noClick: true,
    noKeyboard: true,
    // (PDF, TIF, TIFF, JPG, JPEG, PNG, jpeg/png
    accept: {
      "image/*": [".png", ".jpeg", ".jpg", ".tif", ".tiff"],
      "application/pdf": [".pdf"],
    },
  });

  useEffect(() => {
    if (
      fileUploadStatus === FileUploadStatus.Success ||
      fileUploadStatus === FileUploadStatus.Failure
    ) {
      setTimeout(() => {
        setFileUploadStatus(FileUploadStatus.None);
      }, 2000);
    }
  }, [fileUploadStatus]);

  return (
    <>
      <div className="toast-container">
        {fileUploadStatus === FileUploadStatus.Uploading && (
          <div className="toast-item in-progress">
            <p>Your document is uploading...</p>
          </div>
        )}
        {fileUploadStatus === FileUploadStatus.Success && (
          <div className="toast-item success">
            <p>Success! Add more files or click "Done"</p>
          </div>
        )}
        {fileUploadStatus === FileUploadStatus.Failure && (
          <div className="toast-item failed">
            <p>Failed to upload. Try again</p>
          </div>
        )}
        {isAttemptingDeleteFile && (
          <div className="toast-item delete interactive">
            <p>Are you sure you want to delete this file?</p>
            <button onClick={onConfirmDeleteFileClick}>Yes</button>
            <button onClick={onCancelDeleteFileClick}>No</button>
          </div>
        )}
      </div>
      <Grid container>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography
              variant="h5"
              style={{
                color: styleVars.colorReferralBlue,
                fontWeight: "bold",
              }}
            >
              Upload documents
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Box height="40px"></Box>
        </Grid>
        <Grid container spacing={2}>
          <Box
            height="220px"
            width="100%"
            style={{
              border: "gray 1px solid",
              display: "flex",
              justifyContent: "center",
              paddingTop: "20px",
              marginBottom: "20px",
            }}
            {...getRootProps({ className: "dropzone" })}
          >
            <input {...getInputProps()} />
            <Box
              height="200px"
              width="94%"
              style={{
                border: "gray 1px dashed",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              {fileUploadError ? (
                <>
                  <Grid item>
                    <Icon
                      icon={icError}
                      size={60}
                      style={{ color: "#B61A1D", marginTop: "20px" }}
                    />
                  </Grid>
                  {fileUploadError === FILE_TOO_LARGE ? (
                    <>
                      <Typography
                        variant="body1"
                        style={{
                          color: "#B71B1C",
                          fontWeight: 500,
                          fontSize: styleVars.fontSizeLarge,
                        }}
                      >
                        Oops, this file is too large
                      </Typography>
                      <Typography style={{ fontSize: styleVars.fontSizeSmall }}>
                        We can't upload files larger than 5MB.
                      </Typography>
                      <Typography style={{ fontSize: styleVars.fontSizeSmall }}>
                        Please break this document up into multiple smaller
                        files and try uploading again.
                      </Typography>
                    </>
                  ) : (
                    fileUploadError === INVALID_FILE_TYPE && (
                      <>
                        <Typography
                          variant="body1"
                          style={{
                            color: "#B71B1C",
                            fontWeight: 500,
                            fontSize: styleVars.fontSizeLarge,
                          }}
                        >
                          Oops, this file type is not supported
                        </Typography>
                        <Typography
                          style={{
                            marginTop: "6px",
                            fontSize: styleVars.fontSizeSmall,
                          }}
                        >
                          File must be one of the following types: .PDF, .PNG,
                          .JPG, .JPEG, .TIF, .TIFF
                        </Typography>
                      </>
                    )
                  )}

                  <Button
                    variant="contained"
                    color="secondary"
                    size="medium"
                    style={{ marginTop: "6px" }}
                    onClick={() => {
                      setFileUploadError(null);
                    }}
                  >
                    <Typography variant="button">OK</Typography>
                  </Button>
                </>
              ) : (
                <>
                  <Box height="8px"></Box>
                  <Icon
                    icon={icCloudUpload}
                    style={{ color: "#D5EAEA" }}
                    size={60}
                  />
                  <Box height="5px"></Box>

                  <Typography
                    variant="body1"
                    style={{
                      fontWeight: 600,
                      fontSize: styleVars.fontSizeLarge,
                    }}
                  >
                    Drag and drop a file here, or
                  </Typography>
                  <Box height="18px"></Box>
                  <Button variant="contained" color="secondary" onClick={open}>
                    BROWSE FOR FILE
                  </Button>
                  {!currentFile?.content && (
                    <Typography
                      variant="body1"
                      style={{
                        marginTop: "10px",
                        color: "#9E9E9E",
                        fontSize: styleVars.fontSizeSmall,
                      }}
                    >
                      Size limit: 5MB per document
                    </Typography>
                  )}
                  {!!currentFile?.content && (
                    <>
                      <Grid container spacing={2}>
                        <Box height="34px"></Box>
                      </Grid>

                      <Grid
                        container
                        spacing={2}
                        direction="row"
                        justifyContent="center"
                      >
                        <Typography variant="body2">
                          <Typography
                            component="span"
                            variant="body1"
                            style={{
                              fontWeight: 600,
                              fontSize: styleVars.fontSizeLarge,
                            }}
                          >
                            File:{" "}
                          </Typography>
                          {currentFile?.name}
                        </Typography>
                        <Icon
                          icon={icDelete}
                          style={{ color: "gray", cursor: "pointer" }}
                          onClick={onDeleteStagedFileClick}
                          size={24}
                        />
                      </Grid>
                    </>
                  )}
                </>
              )}
            </Box>
          </Box>
        </Grid>
        <Grid container spacing={2}>
          <Box height="28px"></Box>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextField
              select
              fullWidth
              label="Document Category"
              variant="outlined"
              name="documentCategory"
              id="document-category-select"
              value={documentCategory}
              size="small"
              onChange={(e) => {
                if (currentFile) {
                  setCurrentFile({
                    name: currentFile?.name,
                    size: currentFile?.size,
                    content: currentFile?.content,
                    file: currentFile?.file,
                    category: e.target?.value,
                    fileId: currentFile?.fileId,
                  } as FileUpload & UploadFileType & Blob);
                }
                setDocumentCategory(e.target?.value);
              }}
            >
              {metadata.formType === FormType.Referral
                ? REFERRAL_FILE_TYPES.map((fileType) => (
                    <MenuItem key={fileType} value={fileType}>
                      {fileType}
                    </MenuItem>
                  ))
                : REAUTH_FILE_TYPES.map((fileType) => (
                    <MenuItem key={fileType} value={fileType}>
                      {fileType}
                    </MenuItem>
                  ))}
            </TextField>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Box height="40px"></Box>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Button
              variant="contained"
              color="secondary"
              size="medium"
              style={{
                width: "110px",
              }}
              disabled={!documentCategory || !currentFile}
              onClick={() => {
                if (currentFile) {
                  setFileUploadStatus(FileUploadStatus.Uploading);
                  uploadFile(
                    currentFile?.file as File & UploadFileType & Blob
                  ).then((uploadRequestId: string) => {
                    if (!!uploadRequestId) {
                      setFiles([
                        ...files,
                        { ...currentFile, fileId: uploadRequestId },
                      ]);
                      setFileUploadStatus(FileUploadStatus.Success);
                      resetSelectField();
                      setCurrentFile(undefined);
                    } else {
                      console.warn("UploadDialog: File upload failed.");
                      setFileUploadStatus(FileUploadStatus.Failure);
                    }
                  });
                }
              }}
            >
              <Icon icon={icFileUpload} size={24} />
              <Typography
                variant="button"
                style={{
                  fontSize: styleVars.fontSizeNormal,
                  marginLeft: "6px",
                  minHeight: "25px",
                  fontWeight: "bold",
                }}
              >
                UPLOAD
              </Typography>
            </Button>
          </Grid>
        </Grid>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell>
                <Typography variant="subtitle2" style={{ fontWeight: "bold" }}>
                  File list
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="subtitle2" style={{ fontWeight: "bold" }}>
                  Category
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="subtitle2" style={{ fontWeight: "bold" }}>
                  Status
                </Typography>
              </TableCell>
            </TableRow>
            {files.map((f: FileUpload, i: number) => {
              const isMarkedForDelete = attemptDeleteFileIndex === i;
              return (
                <TableRow
                  key={i}
                  style={{ background: isMarkedForDelete ? "#ecc1c1" : "" }}
                >
                  <TableCell>
                    <Typography variant="body1">{f.name}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body1">{f.category}</Typography>
                  </TableCell>
                  <TableCell width="20%">
                    <Icon
                      icon={icAssignmentTurnedIn}
                      style={{ color: "green" }}
                      size={24}
                    />
                  </TableCell>
                  <TableCell style={{ borderBottom: "none" }}>
                    <Icon
                      icon={icDelete}
                      style={{
                        color: isMarkedForDelete ? "#B71B1C" : "gray",
                        cursor: "pointer",
                      }}
                      size={24}
                      onClick={() => onAttemptDeleteFileClick(i)}
                    />
                  </TableCell>
                </TableRow>
              );
            })}
            {fileUploadStatus === FileUploadStatus.Uploading && (
              <TableRow>
                <TableCell>
                  <Typography variant="body1">{currentFile?.name}</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body1">
                    {currentFile?.category}
                  </Typography>
                </TableCell>
                <TableCell width="20%">Uploading...</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
        <Grid container spacing={2}>
          <Box height="50px"></Box>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  size="medium"
                  onClick={updateFormOnClose}
                  disabled={!files.length}
                >
                  <Typography variant="button">Done</Typography>
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="outlined"
                  color="primary"
                  size="medium"
                  onClick={onCancelClick}
                >
                  <Typography variant="button">Cancel</Typography>
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default UploadDialog;
